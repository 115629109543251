import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';
import ImageSelect from 'components/ImageSelect';
import TitleInput from 'components/TitleInput';
import CustomMDXEditor from 'components/CustomMDXEditor';

const HomeForm = ({ defaultValues, isOpen, onDelete, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const values = watch();

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues,
        description: defaultValues.description
      });
    } else {
      reset({});
    }
  }, [reset, defaultValues, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <ImageSelect
          object={values}
          setValue={setValue}
          uploadVideo
          preloadImage={!defaultValues}
        />
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <TitleInput control={control} />
        </FormControl>
        <FormControl id="description">
          <FormLabel>{t('common.description')}</FormLabel>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <CustomMDXEditor field={field} setValue={setValue} />
            )}
          />
        </FormControl>
        <Input {...register('image')} hidden />
        <Input {...register('unsplash')} hidden />
        <Flex alignItems="center" justifyContent="space-between">
          <Box>
            {values?.id && (
              <Button
                colorScheme="red"
                onClick={async () => {
                  await onDelete();
                }}
              >
                {t('common.delete')}
              </Button>
            )}
          </Box>
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default HomeForm;
