import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import {
  faArrowRightArrowLeft,
  faBell,
  faLifeRing,
  faShield,
  faSignOut,
  faUser
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MemberAvatar from 'components/MemberAvatar';
import NotificationBadge from 'features/notification/NotificationBadge';
import { useAuth } from 'providers/AuthProvider';
import ModalWrapper from 'components/ModalWrapper';

const NavigationMenu = ({ me, onClickNotifications }) => {
  const { logout } = useAuth();
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isOpen: showSupportModal,
    onOpen: openSupportModal,
    onClose: closeSupportModal
  } = useDisclosure();

  const handleOnClickSignOut = () => {
    logout();
    navigate(`/${slug}/login`);
  };

  const handleOnClickChangeHive = () => {
    localStorage.removeItem('slug');
    navigate('/join');
  };

  return me ? (
    <Menu placement="bottom-end">
      <MenuButton position="relative" top={0} left={0}>
        <MemberAvatar member={me} size="sm" width="40px" height="40px" />
        <NotificationBadge
          unreadNotifications={profile.unread_notifications}
          offset={0.5}
        />
      </MenuButton>
      <MenuList>
        <MenuItem
          as={Link}
          to={`/${slug}/member/${me.id}`}
          icon={<FontAwesomeIcon icon={faUser} fixedWidth />}
        >
          <Text>{t('common.profile')}</Text>
        </MenuItem>
        <MenuItem
          onClick={onClickNotifications}
          icon={<FontAwesomeIcon icon={faBell} fixedWidth />}
        >
          <HStack justifyContent="space-between" spacing={4}>
            <Text>{t('common.notifications')}</Text>
            <NotificationBadge
              unreadNotifications={profile.unread_notifications}
              position="static"
            />
          </HStack>
        </MenuItem>
        <Divider />
        {profile?.is_admin_or_manager && (
          <>
            {profile.is_admin && (
              <MenuItem
                as={Link}
                to={`/${slug}/admin`}
                icon={<FontAwesomeIcon icon={faShield} fixedWidth />}
              >
                <Text>{t('common.admin')}</Text>
              </MenuItem>
            )}
            <MenuItem
              onClick={openSupportModal}
              icon={<FontAwesomeIcon icon={faLifeRing} fixedWidth />}
            >
              <Text>{t('common.support')}</Text>
            </MenuItem>
          </>
        )}

        <Divider />
        <MenuItem
          onClick={handleOnClickChangeHive}
          icon={<FontAwesomeIcon icon={faArrowRightArrowLeft} fixedWidth />}
        >
          <Text>{t('common.change_hive')}</Text>
        </MenuItem>
        <MenuItem
          onClick={handleOnClickSignOut}
          icon={<FontAwesomeIcon icon={faSignOut} fixedWidth />}
        >
          <Text>{t('common.sign_out')}</Text>
        </MenuItem>
      </MenuList>
      <ModalWrapper
        size="sm"
        isOpen={showSupportModal}
        onClose={closeSupportModal}
        title={t('common.support')}
      >
        <Stack spacing={4}>
          <Button
            variant="outline"
            as="a"
            target="_blank"
            href="https://help.hives.co/en/"
          >
            {t('common.help_center')}
          </Button>
          <Button
            variant="outline"
            as="a"
            target="_blank"
            href="https://app.hives.co/shared/collection/1f00f5da-2e19-4b3d-ac6c-d46538b17c7d"
          >
            {t('common.feedback')}
          </Button>
        </Stack>
      </ModalWrapper>
    </Menu>
  ) : null;
};

export default NavigationMenu;
