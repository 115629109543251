import { useEffect, useState } from 'react';
import { Center, Flex, Spinner } from '@chakra-ui/react';

const LoadingIndicator = ({
  children,
  centered = false,
  delay = 300,
  ...rest
}) => {
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIndicator(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  if (!showIndicator) {
    return null;
  }

  return (
    <Flex flexDir="column">
      {children ? (
        children
      ) : (
        <Center
          my={centered ? 0 : 8}
          minHeight={centered ? '100vh' : null}
          width="full"
          {...rest}
        >
          <Spinner size="lg" />
        </Center>
      )}
    </Flex>
  );
};

export default LoadingIndicator;
