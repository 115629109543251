export const areDeeplyEqual = (obj1, obj2) => {
  if (obj1 && obj2) {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }
    for (const key of obj1Keys) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        if (!areDeeplyEqual(obj1[key], obj2[key])) {
          return false;
        }
      } else {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }
    }
    return true;
  } else {
    return obj1 === obj2;
  }
};

export const splitIntoPages = (originalPages, allResults) => {
  let remainingResults = [...allResults];
  return originalPages.map(page => {
    const { count, next, previous, results } = page;
    const currentPageResults = remainingResults.slice(0, results.length);
    remainingResults = remainingResults.slice(results.length);
    return {
      count: count - (results.length - currentPageResults.length),
      next,
      previous,
      results: currentPageResults
    };
  });
};
