import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';
import DescriptionFormControl from './DescriptionFormControl';

const ReportForm = ({ defaultValues, onSubmit }) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <Input {...register('title')} />
        </FormControl>
        <DescriptionFormControl
          control={control}
          setValue={text => setValue('description', text)}
        />
        <Flex justifyContent="flex-end">
          <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default ReportForm;
