import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AspectRatio, Input, Stack, useToast } from '@chakra-ui/react';
import CoverMedia from './CoverMedia';
import UnsplashModal from './modals/UnsplashModal';
import {
  faEllipsisVertical,
  faImage,
  faSearch,
  faPlus,
  faPlayCircle
} from '@fortawesome/pro-regular-svg-icons';
import { findMedia } from 'utils/media';
import { ActionMenu, CustomMenuItem, DeleteMenuItem } from './ActionMenu';
import IconTooltip from './tooltips/IconTooltip';

const ImageSelect = ({
  object,
  preloadImage = false,
  setValue,
  uploadVideo = false
}) => {
  const imageRef = useRef();
  const videoRef = useRef();
  const [showUnsplashModal, setShowUnsplashModal] = useState(false);

  const { t } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    async function fetchImage() {
      const UNSPLASH_ARCHIVE_NATURE_COLLECTION = 3330448;
      try {
        const { data } = await axios.get(
          `https://api.unsplash.com/photos/random?client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}&collections=${UNSPLASH_ARCHIVE_NATURE_COLLECTION}`
        );
        setValue('unsplash', data.urls.regular);
      } catch (e) {
        console.log(e);
      }
    }
    if (preloadImage) {
      fetchImage();
    }
  }, [setValue, preloadImage]);

  const toggleShowUnsplashModal = () => {
    setShowUnsplashModal(!showUnsplashModal);
  };

  const handleOnClickUploadImage = () => {
    imageRef.current.click();
  };

  const handleOnClickUploadVideo = () => {
    videoRef.current.click();
  };

  const handleOnClickRemove = () => {
    setValue('image', null);
    setValue('unsplash', null);
    if (uploadVideo) {
      setValue('video', null);
    }
  };

  const handleOnUploadImage = e => {
    const image = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => {};
    reader.readAsDataURL(image);
    setValue('image', image);
    setValue('unsplash', null);
    if (uploadVideo) {
      setValue('video', null);
    }
  };

  const handleOnUploadUnplash = url => {
    setValue('image', null);
    setValue('unsplash', url);
    if (uploadVideo) {
      setValue('video', null);
    }
  };

  const handleOnUploadVideo = e => {
    const video = e.target.files[0];
    if (video.size > 1000000000) {
      toast({
        title: 'File is too large. File cannot be larger than 1 GB.',
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
    } else if (video.name.match(/[.](mp4|webm)$/i) === null) {
      toast({
        title: t('common.video_format_error'),
        status: 'error',
        position: 'bottom-right',
        isClosable: true
      });
    } else {
      const reader = new FileReader();
      reader.onload = e => {};
      reader.readAsDataURL(video);
      setValue('image', null);
      setValue('unsplash', null);
      if (uploadVideo) {
        setValue('video', video);
      }
    }
  };

  return (
    <Stack position="relative">
      <AspectRatio ratio={16 / 9}>
        <CoverMedia object={object}>
          <Input
            accept="video/mp4, video/webm"
            type="file"
            ref={videoRef}
            onChange={handleOnUploadVideo}
            hidden
          />
          <Input
            accept="image/*"
            type="file"
            ref={imageRef}
            onChange={handleOnUploadImage}
            hidden
          />
          <UnsplashModal
            isOpen={showUnsplashModal}
            onClose={toggleShowUnsplashModal}
            onImage={handleOnUploadUnplash}
          />
        </CoverMedia>
      </AspectRatio>
      <ActionMenu
        icon={findMedia(object) ? faEllipsisVertical : faPlus}
        variant="overlay"
        size="sm"
        position="absolute"
        top={2}
        right={2}
        overflowY="visible"
      >
        {findMedia(object) && (
          <DeleteMenuItem
            label={t('button.remove')}
            onClick={handleOnClickRemove}
          />
        )}
        {uploadVideo && (
          <CustomMenuItem
            icon={faPlayCircle}
            label={t('button.upload_video')}
            onClick={handleOnClickUploadVideo}
            command={
              <IconTooltip label={t('common.video_format_disclaimer')} />
            }
          />
        )}
        <CustomMenuItem
          icon={faImage}
          label={t('button.upload_image')}
          onClick={handleOnClickUploadImage}
        />
        <CustomMenuItem
          icon={faSearch}
          label={t('button.search_image')}
          onClick={toggleShowUnsplashModal}
        />
      </ActionMenu>
    </Stack>
  );
};

export default ImageSelect;
