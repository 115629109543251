import { useEffect } from 'react';
import i18next from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';

const GroupForm = ({ defaultValues, isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="name">
          <FormLabel>{t('common.name')}</FormLabel>
          <Input
            placeholder={t('placeholder.enter_name')}
            {...register('name', {
              required: true
            })}
            defaultValue={defaultValues?.name}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button mr={3} onClick={onClose}>
            {i18next.t('button.cancel')}
          </Button>
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {i18next.t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default GroupForm;
