import { AsyncSelect } from 'chakra-react-select';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@chakra-ui/react';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import { useEffect } from 'react';

let timeoutId;

const fetchCollections = (query, slug) => {
  return new Promise(resolve => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(async () => {
      const { data } = await api.get(
        `/collections?search=${query}&hive__slug=${slug}&transferable=true`
      );
      resolve(data);
    }, 1000);
  });
};

const SearchCollectionModal = ({ isOpen, onClose, onSubmit }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <ModalWrapper
      headerContent={t('common.search_collection')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            control={control}
            name="collection"
            rules={{ required: true }}
            render={({ field }) => (
              <AsyncSelect
                {...field}
                loadOptions={query => fetchCollections(query, slug)}
                placeholder={t('placeholder.search')}
                getOptionLabel={collection => collection.title}
                getOptionValue={collection => collection.id}
                useBasicStyles
              />
            )}
          />
          <Button
            type="submit"
            alignSelf="flex-end"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {t('button.change')}
          </Button>
        </Stack>
      </form>
    </ModalWrapper>
  );
};

export default SearchCollectionModal;
