import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SimpleGrid } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import CollectionListItem from 'features/collection/CollectionListItem';
import api from 'utils/api';

const FolderCollections = ({ folderId }) => {
  const { t } = useTranslation();

  const { slug } = useParams();

  const {
    data: collections,
    status,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    ['folder', folderId, 'collections'],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        folder: folderId,
        offset: pageParam,
        limit: 20
      });
      params.append('hive__slug', slug);
      const { data } = await api.get(`/collections?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.collections').toLowerCase()
        })
      ]}
    >
      {isSuccess && (
        <InfiniteScrollHelper
          hasMore={!isFetching && hasNextPage}
          loadMore={fetchNextPage}
        >
          <SimpleGrid columns={[1, null, 2]} spacing={6}>
            {collections.pages.map(page =>
              page.results.map(collection => (
                <CollectionListItem
                  key={collection.id}
                  collection={collection}
                />
              ))
            )}
          </SimpleGrid>
          {isFetching && <LoadingIndicator />}
        </InfiniteScrollHelper>
      )}
    </LoadingWrapper>
  );
};

export default FolderCollections;
