import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  Divider,
  Flex,
  Heading,
  Image,
  Stack,
  useColorModeValue
} from '@chakra-ui/react';
import { faFilePdf, faMemo } from '@fortawesome/pro-regular-svg-icons';
import { ActionMenu, CustomMenuItem } from 'components/ActionMenu';
import CoverMedia from 'components/CoverMedia';
import Description from 'components/Description';
import GridAttachmentList from 'features/attachment/GridAttachmentList';
import CardStep from 'features/card/CardStep';
import { getCollectingSingular } from 'features/collection/collectionUtils';

const CardReport = ({
  card,
  includeBackground,
  onClose,
  interactable = true
}) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const logo = useColorModeValue(
    card.collection.hive.dark_logo
      ? card.collection.hive.dark_logo
      : '/hives_dark.png',
    card.collection.hive.light_logo
      ? card.collection.hive.light_logo
      : '/hives_light.png'
  );

  return (
    <Stack spacing={8} pb={8}>
      <Flex justifyContent="space-between" alignItems="center">
        <Image src={logo} height={8} />
        {interactable && (
          <ActionMenu>
            <CustomMenuItem
              icon={faMemo}
              label={t('card.view_main_content')}
              onClick={onClose}
            />
            <CustomMenuItem
              as={Link}
              to={`/${slug}/reports/${card.id}`}
              target="_blank"
              rel="noopener noreferrer"
              icon={faFilePdf}
              label={t('common.export_report')}
            />
          </ActionMenu>
        )}
      </Flex>
      {includeBackground && (
        <>
          <Stack spacing={8}>
            <Heading fontSize="2xl">{t('common.background')}</Heading>
            <Stack spacing={6}>
              <Heading fontSize="xl">{card.collection.title}</Heading>
              <Description>{card.collection.description}</Description>
              <GridAttachmentList
                code={card.collection.code}
                modelConfig={{
                  singular: 'collection',
                  plural: 'collections',
                  modelId: card.collection.id
                }}
              />
            </Stack>
          </Stack>
          <Divider />
        </>
      )}
      <Stack spacing={8}>
        <Stack spacing={6}>
          <Heading fontSize="2xl">
            {getCollectingSingular(card.collection.type)}
          </Heading>
          <CoverMedia object={card} height="200px" hideIfEmpty />
          <Heading fontSize="xl">{card.title}</Heading>
          <Description>{card.description}</Description>
        </Stack>
        <GridAttachmentList
          code={card.collection.code}
          modelConfig={{
            singular: 'card',
            plural: 'cards',
            modelId: card.id
          }}
        />
        {/* <CardStep
          card={card}
          filter={{ step__isnull: true }}
          canEdit={false}
          noEmptyState
          forReport
        /> */}

        <CardStep
          card={card}
          filter={{ step__isnull: true }}
          noEmptyState
          forReport
        />

        {card.collection.workflow?.steps
          ?.filter(step => card.steps_with_feedback.includes(step.id))
          .map(step => (
            <Stack key={step.id} spacing={8}>
              <Heading fontSize="lg" whiteSpace="nowrap">
                {step.name}
              </Heading>
              <Stack spacing={4}>
                <CardStep
                  code={card.collection.code}
                  card={card}
                  filter={{ step: step.id }}
                  canEdit={false}
                  forReport
                />
              </Stack>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default CardReport;
