import { useParams } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import i18next from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button, Flex, FormControl, Stack } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';

const SelectGroupForm = ({ defaultValues, onSubmit }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  const {
    status,
    data: groups,
    isSuccess
  } = useQuery('groups', async () => {
    const { data } = await api.get(`/groups?hive__slug=${slug}`);
    return data;
  });

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.groups').toLowerCase()
        })
      ]}
    >
      {isSuccess && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <FormControl id="group">
              <Controller
                name="group"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={defaultValues?.group}
                    options={groups}
                    placeholder={t('placeholder.search')}
                    getOptionLabel={group => group.name}
                    getOptionValue={group => group.id}
                    useBasicStyles
                    isClearable
                  />
                )}
              />
            </FormControl>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                colorScheme="teal"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                {i18next.t('button.save')}
              </Button>
            </Flex>
          </Stack>
        </form>
      )}
    </LoadingWrapper>
  );
};

export default SelectGroupForm;
