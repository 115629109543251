import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { Divider, Stack, useColorModeValue } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import CommentNotification from './CommentNotification';
import StepNotification from './StepNotification';
import AssigneeNotification from './AssigneeNotification';

const NotificationList = ({ onClickCard }) => {
  const { t } = useTranslation();
  const notificationColor = useColorModeValue('red.500', 'red.400');

  const {
    status,
    data: notifications,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isSuccess
  } = useInfiniteQuery(
    'notifications',
    async ({ pageParam = 0 }) => {
      const { data } = await api.get(
        `/notifications?offset=${pageParam}&limit=10`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.notifications').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <>
          <InfiniteScrollHelper
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
          >
            <Stack spacing={4}>
              {notifications.pages.map(page =>
                page.results.map(notification => (
                  <React.Fragment key={notification.id}>
                    {notification.event === 'ASSIGNED_TO' && (
                      <AssigneeNotification
                        key={notification.id}
                        notification={notification}
                        onClickCard={onClickCard}
                        color={notificationColor}
                      />
                    )}
                    {notification.event === 'COMMENTED_ON' && (
                      <CommentNotification
                        key={notification.id}
                        notification={notification}
                        onClickCard={onClickCard}
                        color={notificationColor}
                      />
                    )}
                    {notification.event === 'CHANGED_STEP' && (
                      <StepNotification
                        key={notification.id}
                        notification={notification}
                        onClickCard={onClickCard}
                        color={notificationColor}
                      />
                    )}
                    <Divider />
                  </React.Fragment>
                ))
              )}
              {isFetching && <LoadingIndicator />}
            </Stack>
          </InfiniteScrollHelper>
        </>
      ) : null}
    </LoadingWrapper>
  );
};

export default NotificationList;
