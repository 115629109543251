import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical,
  faEdit,
  faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { faThumbTack } from '@fortawesome/pro-solid-svg-icons';

const AttachmentActionMenu = ({
  onClickDelete,
  onClickUpdate,
  onClickSetImage,
  isImage = false
}) => {
  const { t } = useTranslation();
  return (
    <Menu placement="bottom">
      <MenuButton as={Button} size="xs" variant={isImage ? 'overlay' : 'ghost'}>
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<FontAwesomeIcon icon={faEdit} fixedWidth />}
          onClick={onClickUpdate}
        >
          {t('common.edit')}
        </MenuItem>
        <MenuItem
          icon={<FontAwesomeIcon icon={faTrashAlt} fixedWidth />}
          onClick={onClickDelete}
        >
          {t('common.delete')}
        </MenuItem>
        {onClickSetImage && (
          <MenuItem
            icon={<FontAwesomeIcon className="fa-fw" icon={faThumbTack} />}
            onClick={onClickSetImage}
          >
            {t('common.set_as_cover_image')}
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default AttachmentActionMenu;
