import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Button, Divider, HStack, Image, Stack, Text } from '@chakra-ui/react';
import ModalWrapper from 'components/ModalWrapper';
import LoginForm from 'features/auth/LoginForm';
import api from 'utils/api';
import CheckYourEmail from 'components/CheckYourEmail';

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState(null);

  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    if (isOpen) {
      setEmail(null);
    }
  }, [isOpen]);

  const createConfirmationMutation = useMutation(
    payload => api.post('/auth/confirm', payload),
    {
      onSuccess: (_, payload) => {
        setEmail(payload.email);
      }
    }
  );

  const handleOnContinueWithMail = async ({ email }) => {
    await createConfirmationMutation.mutateAsync({
      email,
      state: window.btoa(
        JSON.stringify({
          from: location.pathname
        })
      )
    });
  };

  // const handleOnContinueWithFacebook = () => {
  //   const url = `https://www.facebook.com/v15.0/dialog/oauth`;
  //   const params = new URLSearchParams({
  //     client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  //     response_type: 'code',
  //     redirect_uri: `${process.env.REACT_APP_URL}/auth/facebook`,
  //     scope: 'email',
  //     state: window.btoa(
  //       JSON.stringify({
  //         from: location.pathname
  //       })
  //     )
  //   });
  //   window.open(`${url}?${params.toString()}`, '_self');
  // };

  const handleOnContinueWithGoogle = () => {
    const url = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/google`,
      scope: 'openid email profile',
      state: window.btoa(
        JSON.stringify({
          from: location.pathname
        })
      ),
      access_type: 'offline',
      prompt: 'consent'
    });
    window.open(`${url}?${params.toString()}`, '_self');
  };

  const handleOnContinueWithLinkedIn = () => {
    const url = 'https://www.linkedin.com/oauth/v2/authorization';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/linkedin`,
      scope: 'r_liteprofile r_emailaddress',
      state: window.btoa(
        JSON.stringify({
          from: location.pathname
        })
      )
    });
    window.open(`${url}?${params.toString()}`, '_self');
  };

  const handleOnContinueWithMicrosoft = () => {
    const url =
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/microsoft`,
      scope: 'openid email profile',
      state: window.btoa(
        JSON.stringify({
          from: location.pathname
        })
      ),
      prompt: 'none'
    });
    window.open(`${url}?${params.toString()}`, '_self');
  };

  const handleOnContinueWithSlack = () => {
    const url = 'https://slack.com/oauth/v2/authorize';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_SLACK_CLIENT_ID,
      user_scope: 'identity.avatar,identity.basic,identity.email',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/slack`,
      state: window.btoa(
        JSON.stringify({
          from: location.pathname
        })
      )
    });
    window.open(`${url}?${params.toString()}`, '_self');
  };

  return (
    <ModalWrapper
      title={
        email ? t('login.check_your_email') : t('login.start_contributing')
      }
      isOpen={isOpen}
      onClose={onClose}
      pb={6}
    >
      <Stack spacing={4}>
        {email ? (
          <CheckYourEmail email={email} />
        ) : (
          <>
            <Stack>
              <Text>{t('login.enter_your_email')}</Text>
              <LoginForm onSubmit={handleOnContinueWithMail} />
            </Stack>
            <HStack>
              <Divider />
              <Text variant="muted">{t('common.or')}</Text>
              <Divider />
            </HStack>
            <Button onClick={handleOnContinueWithGoogle} variant="outline">
              <HStack>
                <Image src="/google.svg" height={4} />
                <Text>{t('button.continue_with_google')}</Text>
              </HStack>
            </Button>
            {/* <Button
              onClick={handleOnContinueWithFacebook}
              variant="outline"
            >
              <HStack>
                <Image src="/facebook.svg" height={4} />
                <Text>{t('button.continue_with_facebook')}</Text>
              </HStack>
            </Button> */}
            <Button onClick={handleOnContinueWithLinkedIn} variant="outline">
              <HStack>
                <Image src="/linkedin.svg" height={4} />
                <Text>{t('button.continue_with_linkedin')}</Text>
              </HStack>
            </Button>
            <Button onClick={handleOnContinueWithSlack} variant="outline">
              <HStack>
                <Image src="/slack.svg" height={4} />
                <Text>{t('button.continue_with_slack')}</Text>
              </HStack>
            </Button>
            <Button onClick={handleOnContinueWithMicrosoft} variant="outline">
              <HStack>
                <Image src="/microsoft.svg" height={4} />
                <Text>{t('button.continue_with_microsoft')}</Text>
              </HStack>
            </Button>
          </>
        )}
      </Stack>
    </ModalWrapper>
  );
};

export default LoginModal;
