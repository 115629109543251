import { useTranslation } from 'react-i18next';
import { HStack, Stack, Text } from '@chakra-ui/react';
import BadgeIcon from 'components/BadgeIcon';
import { getWorkflowExplainer } from './workflowUtils';
import { faFolderOpen, faPenField } from '@fortawesome/pro-regular-svg-icons';

const WorkflowStep = ({ title, icon, colorScheme = 'gray' }) => (
  <HStack spacing={4}>
    <BadgeIcon icon={icon} colorScheme={colorScheme} />
    <Text>{title}</Text>
  </HStack>
);

const WorkflowStepList = ({ workflow }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4}>
      <Text variant="muted">{`${getWorkflowExplainer(workflow.type)}:`}</Text>
      <WorkflowStep title={t('common.inbox')} icon={faFolderOpen} />
      {workflow?.steps.map(step => (
        <WorkflowStep
          key={step.id}
          title={step.name}
          icon={faPenField}
          colorScheme={step.color}
        />
      ))}
    </Stack>
  );
};

export default WorkflowStepList;
