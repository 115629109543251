import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Flex,
  HStack,
  Heading,
  Image,
  Spacer,
  Stack,
  useColorModeValue
} from '@chakra-ui/react';
import ActionMenu, {
  CustomMenuItem,
  DeleteMenuItem,
  EditMenuItem
} from 'components/ActionMenu';
import Description from 'components/Description';
import ModalWrapper from 'components/ModalWrapper';
import { faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const CollectionReportModal = ({
  report,
  isOpen,
  onClose,
  onClickDelete,
  onClickUpdate
}) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { t } = useTranslation();
  const logo = useColorModeValue(
    hive.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive.light_logo ? hive.light_logo : '/hives_light.png'
  );

  return report ? (
    <ModalWrapper
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      pb={6}
      size="2xl"
    >
      <Stack spacing={8}>
        <Flex>
          <Image src={logo} height={8} />
        </Flex>
        <HStack spacing={4}>
          <Heading fontSize="2xl">{report.title}</Heading>
          <Spacer />
          <ActionMenu>
            <EditMenuItem onClick={onClickUpdate} />
            <DeleteMenuItem onClick={onClickDelete} />
            <CustomMenuItem
              as={Link}
              to={`/${slug}/collection-reports/${report.id}`}
              target="_blank"
              rel="noopener noreferrer"
              icon={faFilePdf}
              label={t('common.export_ai_analysis')}
            />
          </ActionMenu>
        </HStack>
        <Stack spacing={4}>
          <Description>{report.report}</Description>
        </Stack>
      </Stack>
    </ModalWrapper>
  ) : null;
};

export default CollectionReportModal;
