import { Reorder } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Container, Heading, Stack } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import BackButton from 'components/BackButton';
import api from 'utils/api';
import BoardReorderItem from './BoardReorderItem';

let timer;

const BoardOrderPage = () => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const { t } = useTranslation();
  const {
    status,
    data: boards,
    isSuccess
  } = useQuery(['boards', slug], async () => {
    const { data } = await api.get(`/boards?hive=${hive.id}`);
    return data;
  });

  const queryClient = useQueryClient();

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.boards').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <Stack spacing={8}>
            <Heading fontSize="3xl">{t('board.board_order')}</Heading>
            <BackButton />
            <Reorder.Group
              as="span"
              axis="y"
              values={boards}
              onReorder={boards => {
                if (timer != null) {
                  clearTimeout(timer);
                }
                timer = setTimeout(() => {
                  try {
                    const boards = queryClient.getQueryData(['boards', slug]);
                    api.patch(`/hives/${slug}/reorder-boards`, {
                      boards: boards.map(board => board.id)
                    });
                  } catch (_) {}
                }, 1000);
                queryClient.setQueryData(['boards', slug], boards);
              }}
            >
              {boards.map(board => (
                <BoardReorderItem key={board.id} board={board} />
              ))}
            </Reorder.Group>
          </Stack>
        )}
      </LoadingWrapper>
    </Container>
  );
};

export default BoardOrderPage;
