import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Container, Heading, HStack, Stack } from '@chakra-ui/react';
import SearchForm from './SearchForm';
import FilterButton from 'components/FilterButton';
import CollectionList from 'features/collection/CollectionList';
import CardList from 'features/card/CardList';
import MemberList from 'features/member/MemberList';
import api from 'utils/api';

const SearchPage = () => {
  const { slug } = useParams();
  const [option, setOption] = useState(0);
  const [search, setSearch] = useState(null);
  const { t } = useTranslation();

  const {
    data: results,
    status,
    refetch
  } = useQuery(['search-results', search], async () => {
    const params = new URLSearchParams();
    params.append('slug', slug);
    if (search) {
      params.append('search', search);
    }
    const { data } = await api.get(
      `/metrics/search-results?${params.toString()}`
    );
    return data;
  });

  const handleOnSubmitSearch = async ({ search }) => {
    setSearch(search);
  };

  useEffect(() => {
    if (search) {
      refetch();
    }
  }, [refetch, search]);

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <Heading fontSize="3xl">{t('common.search')}</Heading>
        <HStack overflowX="auto" py={2}>
          <FilterButton
            title={t('common.contributions')}
            selected={option === 0}
            onClick={() => {
              setOption(0);
            }}
            count={status === 'success' ? results.metrics.cards : 0}
          />
          <FilterButton
            title={t('common.collections')}
            selected={option === 1}
            onClick={() => {
              setOption(1);
            }}
            count={status === 'success' ? results.metrics.collections : 0}
          />
          <FilterButton
            title={t('common.members')}
            selected={option === 2}
            onClick={() => {
              setOption(2);
            }}
            count={status === 'success' ? results.metrics.members : 0}
          />
        </HStack>
        <SearchForm onSubmit={handleOnSubmitSearch} />
        {status === 'success' && (
          <>
            {option === 0 ? (
              <CardList filter={{ search }} />
            ) : option === 1 ? (
              <CollectionList search={search} />
            ) : option === 2 ? (
              <MemberList search={search} />
            ) : null}
          </>
        )}
      </Stack>
    </Container>
  );
};

export default SearchPage;
