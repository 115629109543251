import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePDF } from 'react-to-pdf';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  LightMode,
  Stack,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import LoadingWrapper from 'components/LoadingWrapper';
import CardReport from 'features/card/CardReport';
import api from 'utils/api';
import SpinnerSwitch from 'components/SpinnerSwitch';

const getBase64FromUrl = async url => {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = `${url}?__v=${Date.now()}`;
  return new Promise(resolve => {
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const base64String = canvas.toDataURL('image/png');
      resolve(base64String);
    };
  });
};

const ReportDetailed = () => {
  const [exporting, setExporting] = useState(false);
  const [includeBackground, setIncludeBackground] = useState(true);
  const { toPDF, targetRef } = usePDF();

  const { cardId } = useParams();
  const { t } = useTranslation();
  const { slug } = useParams();

  const {
    data: card,
    status,
    isSuccess
  } = useQuery(['card', cardId], async () => {
    const { data } = await api.get(`/cards/${cardId}?hive=${slug}`);
    return data;
  });

  const handleOnClickExport = async () => {
    setExporting(true);
    await toPDF({
      overrides: {
        canvas: {
          onclone: async (_, html) => {
            const images = html.querySelectorAll('img');
            for await (const img of images) {
              if (img.src.includes('data:image')) continue;
              img.src = await getBase64FromUrl(img.src);
            }
          }
        }
      }
    });
    setExporting(false);
  };

  return (
    <LightMode>
      <Container maxW="container.lg" marginY={8}>
        <LoadingWrapper statuses={[status]}>
          {isSuccess && (
            <Stack spacing={8}>
              <Flex alignItems="center" justifyContent="space-between">
                <Heading fontSize="3xl">{t('common.report')}</Heading>
                <Button
                  colorScheme="teal"
                  onClick={handleOnClickExport}
                  isLoading={exporting}
                >
                  <HStack>
                    <FontAwesomeIcon icon={faDownload} />
                    <Text>{t('common.download_pdf')}</Text>
                  </HStack>
                </Button>
              </Flex>
              <HStack spacing={2} alignSelf="flex-end">
                <Text>{t('card.include_background')}</Text>
                <SpinnerSwitch
                  isChecked={includeBackground}
                  optionKey="include_background"
                  onChange={() => setIncludeBackground(!includeBackground)}
                />
              </HStack>
              <div ref={targetRef}>
                <Box
                  padding={16}
                  borderWidth={1}
                  rounded="lg"
                  data-theme="light"
                  bg="chakra-body-bg"
                  color="chakra-body-text"
                >
                  <CardReport
                    card={card}
                    onClose={() => {}}
                    interactable={false}
                    includeBackground={includeBackground}
                  />
                </Box>
              </div>
            </Stack>
          )}
        </LoadingWrapper>
      </Container>
    </LightMode>
  );
};

export default ReportDetailed;
