import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import LabeledBox from 'components/LabeledBox';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import StatisticSkeleton from './StatisticSkeleton';

const ProjectStateDistribution = () => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const { status, data, isSuccess } = useQuery(
    'project-state-distribution',
    async () => {
      const { data } = await api.get(
        `/metrics/project-state-distribution?slug=${slug}`
      );
      return data;
    }
  );

  return (
    <LabeledBox label={t('dashboard.project_distribution')}>
      <LoadingWrapper
        indicator={
          <LoadingIndicator>
            <Stack py={2} spacing={4}>
              {[...Array(3)].map((_, index) => (
                <StatisticSkeleton key={index} />
              ))}
            </Stack>
          </LoadingIndicator>
        }
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.metrics').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack>
            <HStack>
              <Text>{t('common.inactive')}</Text>
              <Spacer />
              <Text fontWeight="bold">{data.inactive}</Text>
            </HStack>
            <HStack>
              <Text>{t('common.active')}</Text>
              <Spacer />
              <Text fontWeight="bold">{data.active}</Text>
            </HStack>
          </Stack>
        ) : null}
      </LoadingWrapper>
    </LabeledBox>
  );
};

export default ProjectStateDistribution;
