import { useState } from 'react';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

const IconTooltip = ({
  color = null,
  icon = faInfoCircle,
  label,
  placement = 'bottom',
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {label && (
        <Tooltip isOpen={isOpen} label={label} hasArrow placement={placement}>
          <Box
            alignSelf="flex-start"
            onClick={() => {
              setIsOpen(true);
            }}
            onMouseEnter={() => {
              setIsOpen(true);
            }}
            onMouseLeave={() => {
              setIsOpen(false);
            }}
            cursor="pointer"
            {...rest}
          >
            <Text color={color}>
              <FontAwesomeIcon icon={icon} />
            </Text>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

export default IconTooltip;
