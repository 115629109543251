import { Box, Center, Text, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const AdminListItem = ({ icon, text, to, tooltip }) => (
  <Tooltip hasArrow label={tooltip}>
    <Box
      as={to ? Link : 'div'}
      to={to}
      padding={[4, 5, 5]}
      rounded="lg"
      borderWidth={1}
      cursor="pointer"
    >
      <Center height="64px">
        <FontAwesomeIcon icon={icon} size="2x" />
      </Center>
      <Text textAlign="center">{text}</Text>
    </Box>
  </Tooltip>
);

export default AdminListItem;
