import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faEdit,
  faEllipsisVertical,
  faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { t } from 'i18next';

export const ActionMenu = ({
  placement = 'bottom-end',
  icon = faEllipsisVertical,
  children,
  ...rest
}) => (
  <Menu placement={placement}>
    <MenuButton as={Button} size="md" variant="outline" {...rest}>
      <FontAwesomeIcon icon={icon} />
    </MenuButton>
    <MenuList position="right" zIndex={2}>
      {children}
    </MenuList>
  </Menu>
);

export const EditMenuItem = ({ onClick, label }) => (
  <MenuItem
    onClick={onClick}
    icon={<FontAwesomeIcon icon={faEdit} fixedWidth />}
  >
    {label ? label : t('common.edit')}
  </MenuItem>
);

export const DeleteMenuItem = ({ onClick, label }) => (
  <MenuItem
    onClick={onClick}
    icon={<FontAwesomeIcon icon={faTrashAlt} fixedWidth />}
  >
    {label ? label : t('common.delete')}
  </MenuItem>
);

export const CustomMenuItem = ({ onClick = null, label, icon, ...rest }) => (
  <MenuItem
    onClick={onClick}
    icon={<FontAwesomeIcon icon={icon ? icon : faChevronRight} fixedWidth />}
    {...rest}
  >
    {label ? label : ''}
  </MenuItem>
);

export default ActionMenu;
