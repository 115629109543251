import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const LoginRedirect = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      navigate(`/${slug}`, { replace: true });
    }
  }, [navigate, slug]);

  return null;
};

export default LoginRedirect;
