import { Box, SimpleGrid } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingWrapper from 'components/LoadingWrapper';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import api from 'utils/api';
import AnswerListItem from './AnswerListItem';

const SurveyAnswerList = ({ filter, fieldId, onClickAnswer, columns }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    status: loadingStatus,
    data: answers,
    fetchNextPage,
    isFetching,
    isSuccess,
    hasNextPage,
    refetch: fetchAnswers
  } = useInfiniteQuery(
    ['survey', 'answers', filter],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      if (filter?.search) {
        params.append('search', filter.search);
      }
      if (filter?.field) {
        params.append('field', filter.field);
      }
      const { data } = await api.get(`/surveys/answers?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      },
      enabled: false
    }
  );

  useEffect(() => {
    queryClient.removeQueries([
      'survey',
      'answers',
      { search: filter.search, field: filter.field }
    ]);
    fetchAnswers();
  }, [queryClient, filter.search, filter.field, fetchAnswers, fieldId]);

  return (
    <Box width="full">
      <LoadingWrapper
        statuses={[loadingStatus]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.cards').toLowerCase()
          })
        ]}
      >
        {isSuccess && answers.pages[0].count > 0 && (
          <InfiniteScrollHelper
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
          >
            <SimpleGrid width="full" columns={columns} spacing={4}>
              {answers.pages.map(page =>
                page.results.map(answer => (
                  <AnswerListItem
                    key={answer.id}
                    answer={answer}
                    onClick={() => {
                      onClickAnswer(answer);
                    }}
                  />
                ))
              )}
            </SimpleGrid>
          </InfiniteScrollHelper>
        )}
      </LoadingWrapper>
    </Box>
  );
};

export default SurveyAnswerList;
