import { useTranslation } from 'react-i18next';
import {
  Container,
  Heading,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react';
import FolderUncategorizedCollections from './FolderUncategorizedCollections';
import IconTooltip from 'components/tooltips/IconTooltip';
import FolderUncategorizedSurveys from './FolderUncategorizedSurveys';

const FolderUncategorizedPage = () => {
  const { t } = useTranslation();
  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <HStack alignItems="flex-end">
          <Heading fontSize="3xl">{t('common.uncategorized')}</Heading>
          <IconTooltip
            label={t('folder.uncategorized_explainer')}
            color="gray"
            alignSelf="center"
          />
        </HStack>
        <Tabs size="sm" variant="fresh">
          <TabList>
            <Tab>{t('common.collections')}</Tab>
            <Tab>{t('common.surveys')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <FolderUncategorizedCollections />
            </TabPanel>
            <TabPanel>
              <FolderUncategorizedSurveys />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </Container>
  );
};

export default FolderUncategorizedPage;
