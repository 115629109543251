import React from 'react';
import { useController } from 'react-hook-form';
import {
  Box,
  Flex,
  HStack,
  Input,
  Spacer,
  Text,
  Textarea
} from '@chakra-ui/react';

const CharacterCounterInput = ({
  useControllerProps,
  placeholder = null,
  isTextArea = false,
  ...rest
}) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController(useControllerProps);

  const maxLength = useControllerProps?.rules?.maxLength.value;
  const currentLength = value?.length ? value.length : 0;
  const exceedsLimit = currentLength > maxLength;

  return (
    <Box>
      {isTextArea ? (
        <Textarea
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          isInvalid={!!error}
          {...rest}
        />
      ) : (
        <Input
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          isInvalid={!!error}
          {...rest}
        />
      )}
      <Flex width="full" justifyContent="flex-end">
        {useControllerProps?.rules?.maxLength && (
          <HStack
            spacing={4}
            width="full"
            justifyContent="space-between"
            mt={2}
          >
            {exceedsLimit && (
              <Text fontSize="sm" variant="error">
                {useControllerProps.rules.maxLength.message}
              </Text>
            )}
            <Spacer />
            <Text fontSize="sm" variant={error ? 'error' : 'light'}>
              {`${currentLength} / ${useControllerProps.rules.maxLength.value}`}
            </Text>
          </HStack>
        )}
      </Flex>
    </Box>
  );
};

export default CharacterCounterInput;
