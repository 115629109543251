import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Center, Stack, Text } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import { useCard } from 'providers/CardProvider';
import api from 'utils/api';
import CardListItem from './CardListItem';
import CardTable from './CardTable';
import { appendCardFilterToURLParams, cardFilterToArray } from './cardUtils';
import { useEffect } from 'react';

const CardList = ({
  emptyState = null,
  tableView,
  filter,
  code,
  onSuccess,
  onClick,
  breakpointCols = {
    default: 3,
    992: 2,
    768: 1
  },
  useCarousel = false
}) => {
  const { openCard, setCarouselCardIds } = useCard();
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    status: loadingStatus,
    data: cards,
    fetchNextPage,
    isFetching,
    isSuccess,
    hasNextPage
  } = useInfiniteQuery(
    ['cards', { hive: slug }, ...cardFilterToArray(filter)],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 10
      });
      if (code) {
        params.append('code', code);
      }
      params.append('hive', slug);
      appendCardFilterToURLParams(filter, params);
      const { data } = await api.get(`/cards?${params.toString()}`);
      if (onSuccess) {
        onSuccess(data.results);
      }
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  useQuery(
    ['carouselCardIds', { hive: slug }, ...cardFilterToArray(filter)],
    async () => {
      const params = new URLSearchParams();
      if (code) {
        params.append('code', code);
      }
      params.append('hive', slug);
      appendCardFilterToURLParams(filter, params);
      const { data } = await api.get(`/cards/carousel?${params.toString()}`);
      return data;
    },
    {
      enabled: useCarousel,
      onSuccess: data => {
        setCarouselCardIds(data);
      }
    }
  );

  useEffect(() => {
    return () => {
      if (useCarousel) {
        setCarouselCardIds([]);
      }
    };
  }, [useCarousel, setCarouselCardIds]);

  return (
    <LoadingWrapper
      statuses={[loadingStatus]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.cards').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        cards.pages[0].count > 0 ? (
          tableView ? (
            <CardTable cards={cards} filter={filter} onClick={onClick} />
          ) : (
            <Stack>
              <InfiniteScrollHelper
                hasMore={!isFetching && hasNextPage}
                loadMore={fetchNextPage}
              >
                <Masonry
                  breakpointCols={breakpointCols}
                  className="masonry-grid"
                  columnClassName="masonry-column"
                >
                  {cards.pages.map(page =>
                    page.results.map(card => (
                      <CardListItem
                        key={card.id}
                        card={card}
                        onClick={() => {
                          if (onClick) {
                            onClick(card);
                          } else {
                            openCard(card.id, code);
                          }
                        }}
                      />
                    ))
                  )}
                </Masonry>
              </InfiniteScrollHelper>
              {isFetching && <LoadingIndicator />}
            </Stack>
          )
        ) : filter?.search ||
          filter?.step ||
          filter?.assignee ||
          filter?.tags ? (
          <Center>
            <Text variant="muted">{t('common.no_results_were_found')}</Text>
          </Center>
        ) : emptyState ? (
          emptyState
        ) : null
      ) : null}
    </LoadingWrapper>
  );
};

export default CardList;
