import { format } from 'date-fns';
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button, Center, Spinner } from '@chakra-ui/react';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import { useParams } from 'react-router-dom';

const ExportModal = ({ collection, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const { data, isLoading, isSuccess, refetch } = useQuery(
    ['export', collection.id],
    async () => {
      const { data } = await api.get(
        `/collections/${collection.id}/export?hive__slug=${slug}`
      );
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen, refetch]);

  const generateCsv = () => {
    if (isSuccess) {
      let temp = [];
      temp.push([...data.columns]);
      data.rows.forEach(row => {
        temp.push([...row]);
      });
      return temp;
    }
    return null;
  };

  const csvData = generateCsv();

  return (
    <ModalWrapper title={t('common.export')} isOpen={isOpen} onClose={onClose}>
      {isLoading && (
        <Center>
          <Spinner />
        </Center>
      )}
      {isSuccess && csvData && (
        <CSVLink
          data={csvData}
          filename={`${collection.title
            .replace(' ', '_')
            .toUpperCase()}_${format(new Date(), "yyyy-MM-dd'T'HH-mm-ss")}.csv`}
          separator=";"
        >
          <Button colorScheme="teal">{t('common.download')}</Button>
        </CSVLink>
      )}
    </ModalWrapper>
  );
};

export default ExportModal;
