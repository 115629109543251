import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Button, FormControl, FormLabel, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles } from '@fortawesome/pro-solid-svg-icons';
import CustomMDXEditor from './CustomMDXEditor';

const DescriptionFormControl = ({
  control,
  setValue,
  label,
  isLoading,
  onClickMagic,
  showMagicButton
}) => {
  const [key, setKey] = useState(Math.random());

  const { t } = useTranslation();
  return (
    <FormControl id="description">
      <FormLabel>{label ? label : t('common.description')}</FormLabel>
      <Controller
        key={key}
        control={control}
        name="description"
        render={({ field }) => (
          <CustomMDXEditor field={field} setValue={setValue} />
        )}
      />
      {showMagicButton && onClickMagic && (
        <Tooltip
          hasArrow
          label={t('button.description_magic_button_explainer')}
          placement="bottom-start"
        >
          <Button
            position="absolute"
            right={2}
            bottom={2}
            alignSelf="flex-end"
            variant="outline"
            rounded="full"
            width={10}
            height={10}
            onClick={async () => {
              try {
                await onClickMagic();
                setKey(Math.random());
              } catch (e) {
                console.log(e);
              }
            }}
            isLoading={isLoading}
            zIndex={9999}
          >
            <FontAwesomeIcon icon={faMagicWandSparkles} />
          </Button>
        </Tooltip>
      )}
    </FormControl>
  );
};

export default DescriptionFormControl;
