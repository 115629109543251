import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  Flex,
  Heading,
  HStack,
  Progress,
  Stack,
  Text
} from '@chakra-ui/react';

const SurveySelection = ({ field }) => {
  const { t } = useTranslation();
  return (
    <Box key={field.id} borderWidth={1} rounded="lg" padding={6}>
      <Stack spacing={8}>
        <Flex justifyContent="space-between">
          <Stack>
            <Heading fontSize="lg">{field.field}</Heading>
            <Text variant="muted">
              {t('survey.answer_count', { count: field.metrics.answers })}
            </Text>
          </Stack>
          <Badge variant="outline" alignSelf="flex-start">
            {t('survey.choice')}
          </Badge>
        </Flex>
        <Stack spacing={6}>
          {field.options.map(option => (
            <Stack key={option.id} spacing={4}>
              <Flex alignItems="center" justifyContent="space-between">
                <Text variant="muted">{option.option}</Text>
                <HStack>
                  <Text variant="muted">{`${option.metrics.percentage}%`}</Text>
                  <Text variant="muted">{`(${option.metrics.selections})`}</Text>
                </HStack>
              </Flex>
              <Progress
                min={0}
                max={field.metrics.answers || 1}
                value={option.metrics.selections}
                rounded="lg"
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SurveySelection;
