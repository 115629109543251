import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  HStack,
  Input,
  Stack,
  Tag,
  Text,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLinkSimple } from '@fortawesome/pro-regular-svg-icons';
import InviteForm from 'components/InviteForm';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const InviteModal = ({ me, isOpen, onClose, onSubmit }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { t } = useTranslation();

  const toast = useToast();

  const {
    status,
    data: domains,
    isSuccess
  } = useQuery('domains', async () => {
    const { data } = await api.get(`/domains?hive=${hive.id}`);
    return data;
  });

  const handleOnClickCopy = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}/${slug}`);
    toast({
      title: 'Link copied.',
      status: 'success',
      position: 'bottom-right',
      isClosable: true
    });
  };

  return (
    <ModalWrapper
      title={t('common.invite')}
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      pb={4}
    >
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.domain').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={4}>
            <HStack>
              <FontAwesomeIcon icon={faLinkSimple} />
              <Text>{t('invite.invite_with_link')}</Text>
            </HStack>
            <FormControl>
              <HStack>
                <Input
                  value={`${process.env.REACT_APP_URL}/${hive.slug}`}
                  readOnly
                />
                <Button colorScheme="blue" onClick={handleOnClickCopy}>
                  {t('button.copy')}
                </Button>
              </HStack>
              <FormHelperText>
                {hive.open
                  ? t('invite.anyone_can_join_this_hive')
                  : t(
                      'invite.anyone_with_an_allowed_domain_can_join_this_hive'
                    )}
              </FormHelperText>
            </FormControl>
            {!hive.open && (
              <Flex flexFlow="wrap">
                {domains.map(domain => (
                  <Tag key={domain.id} mr={1} my={1}>
                    {domain.domain}
                  </Tag>
                ))}
              </Flex>
            )}
            <Divider />
            <Stack>
              <HStack>
                <FontAwesomeIcon icon={faEnvelope} />
                <Text>{t('invite.invite_with_email')}</Text>
              </HStack>
              <InviteForm
                defaultValues={{
                  subject: `Join ${hive.name} on hives`,
                  message: `You have been invited to join ${hive.name} on hives by ${me.full_name}`
                }}
                onSubmit={onSubmit}
              />
            </Stack>
          </Stack>
        ) : null}
      </LoadingWrapper>
    </ModalWrapper>
  );
};

export default InviteModal;
