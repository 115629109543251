import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Flex,
  Heading,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
  useDisclosure
} from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import {
  ActionMenu,
  EditMenuItem,
  DeleteMenuItem
} from 'components/ActionMenu';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import ProjectTemplateForm from './ProjectTemplateForm';
import ProjectStepTable from '../step/ProjectStepTable';
import api from 'utils/api';
import BackButton from 'components/BackButton';

const ProjectTemplateDetailed = () => {
  const {
    isOpen: showDeleteProjectTemplateModal,
    onOpen: openDeleteProjectTemplateModal,
    onClose: closeDeleteProjectTemplateModal
  } = useDisclosure();

  const {
    isOpen: showUpdateProjectTemplateModal,
    onOpen: openUpdateProjectTemplateModal,
    onClose: closeUpdateProjectTemplateModal
  } = useDisclosure();

  const { projectTemplateId } = useParams();

  const navigate = useNavigate();
  const { slug } = useParams();
  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation();

  const {
    status,
    data: projectTemplate,
    isSuccess
  } = useQuery(['project-template', projectTemplateId], async () => {
    const { data } = await api.get(`/project-templates/${projectTemplateId}`);
    return data;
  });

  const deleteProjectTemplateMutation = useMutation(
    async () => {
      await api.delete(`/project-templates/${projectTemplateId}`);
    },
    {
      onSuccess: () => {
        closeDeleteProjectTemplateModal();
        toast({
          title: 'Project template was successfully deleted.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        navigate(`/${slug}/project/templates`);
      }
    }
  );

  const updateProjectTemplateMutation = useMutation(
    async projectTemplate =>
      await api.patch(
        `/project-templates/${projectTemplateId}`,
        projectTemplate
      ),
    {
      onSuccess: ({ data: projectTemplate }) => {
        closeUpdateProjectTemplateModal();
        const projectTemplates = queryClient.getQueryData('project-templates');
        if (projectTemplates) {
          queryClient.setQueryData(
            'project-templates',
            projectTemplates.map(p =>
              p.id === projectTemplateId ? projectTemplate : p
            )
          );
        }
        queryClient.setQueryData(
          ['project-template', projectTemplateId],
          projectTemplate
        );
        toast({
          title: 'Project template was successfully updated.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnDeleteTemplate = async () => {
    await deleteProjectTemplateMutation.mutateAsync();
  };

  const handleOnUpdateProjectTemplate = async projectTemplate => {
    await updateProjectTemplateMutation.mutateAsync(projectTemplate);
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.template').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={20}>
            <Stack spacing={4}>
              <Flex alignItems="center" justifyContent="space-between">
                <Heading fontSize="2xl">{projectTemplate.name}</Heading>
                <ActionMenu>
                  <EditMenuItem onClick={openUpdateProjectTemplateModal} />
                  <DeleteMenuItem onClick={openDeleteProjectTemplateModal} />
                </ActionMenu>
              </Flex>
              <BackButton />
              <Tabs size="sm" variant="fresh" isLazy>
                <TabList>
                  <Tab>{t('common.steps')}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <ProjectStepTable projectTemplateId={projectTemplateId} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <ConfirmationModal
                deleteText={t(
                  'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
                )}
                isOpen={showDeleteProjectTemplateModal}
                onClose={closeDeleteProjectTemplateModal}
                onDelete={handleOnDeleteTemplate}
              />
              <ModalWrapper
                title={t('common.project_template')}
                isOpen={showUpdateProjectTemplateModal}
                onClose={closeUpdateProjectTemplateModal}
              >
                <ProjectTemplateForm
                  defaultValues={{
                    name: projectTemplate.name
                  }}
                  isOpen={showUpdateProjectTemplateModal}
                  onSubmit={handleOnUpdateProjectTemplate}
                />
              </ModalWrapper>
            </Stack>
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default ProjectTemplateDetailed;
