import { useTranslation } from 'react-i18next';
import {
  Center,
  HStack,
  Progress,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react';
import IconTooltip from 'components/tooltips/IconTooltip';

const MyScore = ({ chart, scoring }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4}>
      <Text variant="muted">{t('card.my_scoring')}</Text>
      {chart.parameters.map((parameter, index) => (
        <Stack key={index}>
          <HStack>
            <Text variant="muted" wordBreak="break-word">
              {parameter.label}
            </Text>
            <Center>
              <IconTooltip label={parameter.description} />
            </Center>
            <Spacer />
            {chart.average ? (
              <HStack
                alignItems="flex-end"
                opacity={
                  scoring
                    ? scoring.points[index].value >= parameter.min
                      ? 1
                      : 0.4
                    : 0.4
                }
              >
                <Text fontWeight="bold" fontSize="sm">
                  {scoring ? scoring.points[index].value.toFixed(1) : '--'}
                </Text>
                <Text
                  variant="muted"
                  whiteSpace="nowrap"
                >{`/ ${parameter.max}`}</Text>
              </HStack>
            ) : (
              <Text fontWeight="bold" fontSize="sm" whiteSpace="nowrap">
                {scoring ? scoring.points[index].value.toFixed(1) : '---'}
              </Text>
            )}
          </HStack>
          {chart.average && (
            <Progress
              opacity={
                scoring
                  ? scoring.points[index].value >= parameter.min
                    ? 1
                    : 0.25
                  : 0.25
              }
              rounded="lg"
              size="lg"
              value={scoring ? scoring.points[index].value : 0}
              min={parameter.min}
              max={parameter.max}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default MyScore;
