import {
  Box,
  Center,
  Divider,
  HStack,
  Skeleton,
  SkeletonCircle,
  Stack
} from '@chakra-ui/react';

const StepDistributionChartSkeleton = () => {
  return (
    <Box rounded="lg" borderWidth={1}>
      <Box padding={4}>
        <Skeleton rounded="md" height="25px" width="120px" />
      </Box>
      <Divider />
      <Box padding={4}>
        <Stack>
          <Center>
            <SkeletonCircle size={50} my={8} />
          </Center>
          {[...Array(3)].map((_, index) => (
            <HStack key={index} justifyContent="space-between">
              <Skeleton rounded="sm" height="25px" width="100px" />
              <Skeleton rounded="md" height="25px" width="50px" />
            </HStack>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default StepDistributionChartSkeleton;
