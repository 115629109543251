import HiveListItem from './HiveListItem';
import { Divider, Heading, HStack, SimpleGrid, Stack } from '@chakra-ui/react';

const HiveList = ({ hives, title }) => {
  return hives.length > 0 ? (
    <Stack spacing={4}>
      <HStack spacing={4}>
        <Heading fontSize="3xl">{title}</Heading>
        <Divider />
      </HStack>
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {hives.map(hive => (
          <HiveListItem key={hive.id} hive={hive} />
        ))}
      </SimpleGrid>
    </Stack>
  ) : null;
};

export default HiveList;
