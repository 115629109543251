import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import {
  Button,
  FormControl,
  FormLabel,
  Flex,
  Stack,
  Switch,
  Textarea
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import TitleInput from 'components/TitleInput';
import api from 'utils/api';
import LoadingWrapper from 'components/LoadingWrapper';

const ProjectForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const {
    status: templatesStatus,
    data: templates,
    isSuccess: templatesIsSuccess,
    refetch: fetchProjectTemplates
  } = useQuery(
    'project-templates',
    async () => {
      const { data } = await api.get('/project-templates');
      return data;
    },
    {
      enabled: false
    }
  );

  const {
    status: channelsStatus,
    data: channels,
    isSuccess: channelsIsSuccess,
    refetch: fetchChannels
  } = useQuery(
    'channels',
    async () => {
      const { data } = await api.get('/channels');
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  useEffect(() => {
    if (isOpen) {
      fetchProjectTemplates();
      fetchChannels();
    }
  }, [isOpen, fetchChannels, fetchProjectTemplates]);

  return (
    <LoadingWrapper
      statuses={[templatesStatus, channelsStatus]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.templates').toLowerCase()
        }),
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.channels').toLowerCase()
        })
      ]}
    >
      {templatesIsSuccess && channelsIsSuccess ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <FormControl id="channel">
              <FormLabel>{t('common.channel')}</FormLabel>
              <Controller
                name="channel"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.channel}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={channels}
                    placeholder={t('placeholder.search')}
                    getOptionLabel={channel => channel.title}
                    getOptionValue={channel => channel.id}
                    useBasicStyles
                  />
                )}
              />
            </FormControl>
            {!!!defaultValues?.template && (
              <FormControl id="template">
                <FormLabel>{t('common.template')}</FormLabel>
                <Controller
                  name="template"
                  control={control}
                  defaultValue={defaultValues?.template}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={templates}
                      placeholder={t('placeholder.search')}
                      getOptionLabel={template => template.name}
                      getOptionValue={template => template.id}
                      isClearable
                      useBasicStyles
                    />
                  )}
                />
              </FormControl>
            )}
            <FormControl id="title">
              <FormLabel>{t('common.title')}</FormLabel>
              <TitleInput control={control} />
            </FormControl>
            <FormControl id="description">
              <FormLabel>{t('common.description')}</FormLabel>
              <Textarea
                rows={5}
                {...register('description')}
                defaultValue={defaultValues?.description}
              />
            </FormControl>
            <FormControl
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormLabel htmlFor="active" mb="0">
                {t('common.active')}
              </FormLabel>
              <Switch
                id="active"
                {...register('active')}
                defaultChecked={defaultValues?.active}
              />
            </FormControl>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                colorScheme="teal"
                isDisabled={!isValid || !isDirty}
                isLoading={isSubmitting}
              >
                {t('button.save')}
              </Button>
            </Flex>
          </Stack>
        </form>
      ) : null}
    </LoadingWrapper>
  );
};

export default ProjectForm;
