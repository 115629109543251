import {
  Box,
  Button,
  HStack,
  SimpleGrid,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingWrapper from 'components/LoadingWrapper';
import MembersButton from 'components/MembersButton';
import { useCard } from 'providers/CardProvider';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import api from 'utils/api';

const VoteResultTable = ({
  multiSelect,
  isAdminOrCollectionManager = true,
  wideHeaders = false
}) => {
  const { openCard } = useCard();
  const { t } = useTranslation();
  const { collectionId } = useParams();
  const [searchParams] = useSearchParams();

  const queryParams = [
    { option__field: multiSelect?.id },
    { card__collection: collectionId }
  ];

  const step = searchParams.get('step');
  if (step === '-1') {
    queryParams.push({ option__field__step__isnull: true });
  } else {
    queryParams.push({ option__field__step: step });
  }

  const sortPercentage = useMemo(
    () => (rowA, rowB, columnId) => {
      if (
        rowA?.values?.[columnId].percentage >
        rowB?.values?.[columnId].percentage
      )
        return 1;
      if (
        rowB?.values?.[columnId].percentage >
        rowA?.values?.[columnId].percentage
      )
        return -1;
      return 0;
    },
    []
  );

  const {
    status,
    data: selections,
    isSuccess
  } = useQuery(['selections', ...queryParams], async () => {
    const params = new URLSearchParams();
    if (collectionId) {
      params.append('card__collection', collectionId);
    }
    if (step === '-1') {
      params.append('option__field__step__isnull', true);
    } else if (step) {
      params.append('option__field__step', step);
    }
    if (multiSelect?.id) {
      params.append('option__field', multiSelect?.id);
    }
    const { data } = await api.get(`/selections?${params.toString()}`);
    const noNullMembers = [
      ...data.filter(selection => selection.creator !== null)
    ];
    return noNullMembers;
  });

  const data = useMemo(() => {
    const temp = [];
    selections?.forEach(selection => {
      if (!temp.find(r => r.card === selection.card.id)) {
        let row = {
          title: selection.card.title,
          card: selection.card.id
        };
        multiSelect?.options?.forEach(option => {
          const optionKey = option.option.replace(/\./g, '');
          row[optionKey] = selections.reduce(
            (accu, vote) => {
              if (
                vote.card.id === selection.card.id &&
                vote.option.id === option.id
              ) {
                accu.count += 1;
                const dividend = selections.filter(
                  s => s.card.id === vote.card.id
                ).length;
                accu.percentage =
                  dividend >= 1 ? accu.count / dividend : accu.count;
                accu.voters.push(vote.creator);
              }
              return accu;
            },
            {
              count: 0,
              percentage: 0,
              test: [],
              voters: []
            }
          );
        });
        temp.push(row);
      }
    });
    return temp;
  }, [selections, multiSelect]);

  const columns = useMemo(() => {
    const scoreColumn = (option, _) => ({
      Header: option?.option.replace(/\./g, ''),
      accessor: option?.option.replace(/\./g, ''),
      sortType: sortPercentage,
      borderRight: '1px solid',
      textAlign: 'left',
      pr: 4,
      pl: 4,
      minWidth: wideHeaders ? '10ch' : null,
      maxWidth: wideHeaders ? null : '20ch',
      wordBreak: wideHeaders ? 'break-word' : null,
      whiteSpace: wideHeaders ? 'normal' : 'nowrap'
    });

    let temp = [];
    if (isAdminOrCollectionManager) {
      multiSelect?.options?.forEach((option, index) => {
        temp.push(scoreColumn(option, index));
      });
    }
    return [
      {
        Header: t('common.contribution'),
        accessor: 'title',
        textAlign: 'left',
        sortType: 'string',
        borderRight: '1px solid',
        pr: 4,
        pl: 0,
        minWidth: '200px'
      },
      ...temp
    ];
  }, [
    isAdminOrCollectionManager,
    t,
    sortPercentage,
    wideHeaders,
    multiSelect?.options
  ]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns }, useSortBy);

  return (
    <Stack>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.cards').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <Table {...getTableProps()} variant="simple">
            <Thead>
              {headerGroups.map(headerGroup => (
                <Tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      borderRight={column?.borderRight}
                      borderRightColor="gray.200"
                      textAlign={column.textAlign}
                      whiteSpace="nowrap"
                      pl={column?.pl}
                      pr={column?.pr}
                    >
                      <HStack display="inline-flex" spacing={2}>
                        {column.id.startsWith('param') && column.Footer && (
                          <Box>{column.render('Footer')}</Box>
                        )}
                        <Tooltip
                          isDisabled={column?.disableSortBy === true}
                          label={column.render('Header')}
                          placement="top"
                          hasArrow
                        >
                          <Box
                            userSelect="none"
                            maxW={['15ch', null, '25ch']}
                            textOverflow={wideHeaders ? null : 'ellipsis'}
                            noOfLines={wideHeaders ? 99 : 1}
                            display="block"
                          >
                            {column.render('Header')}
                          </Box>
                        </Tooltip>
                        {!column.disableSortBy && (
                          <Box as="span">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  icon={faSortDown}
                                  aria-label="sorted descending"
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faSortUp}
                                  aria-label="sorted ascending"
                                />
                              )
                            ) : (
                              <FontAwesomeIcon icon={faSort} />
                            )}
                          </Box>
                        )}
                      </HStack>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <Td
                        {...cell.getCellProps()}
                        textAlign={cell.column.textAlign}
                        pl={cell.column?.pl}
                        pr={cell.column?.pr}
                        py={4}
                        borderRight={cell.column?.borderRight}
                        borderRightColor="gray.200"
                        borderBottom="none"
                      >
                        {cell.column.id === 'title' ? (
                          <Stack alignItems="flex-start" minWidth={8}>
                            <Button
                              variant="link"
                              onClick={() => {
                                openCard(cell.row.original.card);
                              }}
                            >
                              <Text
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                maxW={['15ch', null, '25ch']}
                              >
                                {cell.render('Cell')}
                              </Text>
                            </Button>
                          </Stack>
                        ) : (
                          <SimpleGrid columns={2} width="max-content">
                            <Text alignSelf="center">{`${
                              cell.value?.percentage
                                ? Math.floor(cell.value?.percentage * 100)
                                : 0
                            }%`}</Text>
                            <MembersButton
                              isDisabled={
                                cell.value?.voters
                                  ? cell.value?.voters?.length < 1
                                  : true
                              }
                              members={
                                cell.value?.voters ? [...cell.value.voters] : []
                              }
                              modalTitle={t('common.votes_for_option', {
                                option: cell.column.Header
                              })}
                            />
                          </SimpleGrid>
                        )}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}
      </LoadingWrapper>
    </Stack>
  );
};

export default VoteResultTable;
