import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

const PoweredByHives = () => {
  const { t } = useTranslation();
  return (
    <Text as="a" target="_blank" href="https://www.hives.co" variant="muted">
      {t('collection.powered_by_hives_co')}
    </Text>
  );
};

export default PoweredByHives;
