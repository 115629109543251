import { HStack, Skeleton } from '@chakra-ui/react';

const StatisticSkeleton = () => {
  return (
    <HStack justifyContent="space-between">
      <Skeleton rounded="md" height="25px" width="100px" />
      <Skeleton rounded="md" height="25px" width="50px" />
    </HStack>
  );
};

export default StatisticSkeleton;
