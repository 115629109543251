import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Stack } from '@chakra-ui/react';
import HeadingButton from './HeadingButton';
import TranslateButton from './TranslateButton';
import ReadMoreModal from './modals/ReadMoreModal';
import LanguageDetect from 'languagedetect';
import api from 'utils/api';
import Description from './Description';

const detector = new LanguageDetect();

const TitleDescription = ({
  title,
  description,
  button = null,
  noOfLines = 6,
  titleFontSize = '2xl',
  ...rest
}) => {
  const [translations, setTranslations] = useState(null);
  const [showTranslated, setShowTranslated] = useState(false);
  const [showReadMoreModal, setShowReadMoreModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTranslations(null);
    setShowTranslated(false);
  }, [title, description]);

  const toggleShowReadMoreModal = () => {
    setShowReadMoreModal(!showReadMoreModal);
  };

  const handleOnClickTranslate = async () => {
    if (!translations) {
      try {
        const { data } = await api.post('/services/translate', {
          texts: [title, description]
        });
        setTranslations(data.translations);
      } catch (e) {
        console.log(e);
      }
    }
    setShowTranslated(!showTranslated);
  };

  const charLimit = noOfLines * 75;

  const titleText = showTranslated && translations ? translations[0] : title;
  const descriptionText =
    showTranslated && translations ? translations[1] : description;

  const languages =
    titleText &&
    descriptionText &&
    detector.detect(`${title} ${description}`, 2);

  return (
    <Stack spacing={4} {...rest}>
      <HeadingButton
        title={titleText}
        button={button}
        fontSize={titleFontSize}
      />
      <Description>{descriptionText}</Description>
      <HStack spacing={4}>
        {languages &&
          languages.length > 0 &&
          languages[0][0] !== 'english' &&
          languages[0][1] > 0.2 && (
            <TranslateButton
              showTranslated={showTranslated}
              onClick={handleOnClickTranslate}
            />
          )}
        {descriptionText?.length > charLimit && (
          <Button variant="link" size="xs" onClick={toggleShowReadMoreModal}>
            {t('button.read_more')}
          </Button>
        )}
      </HStack>
      <ReadMoreModal
        title={titleText}
        description={descriptionText}
        isOpen={showReadMoreModal}
        onClose={toggleShowReadMoreModal}
      />
    </Stack>
  );
};

export default TitleDescription;
