import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';
import { faFolder as faFolderOutline } from '@fortawesome/pro-regular-svg-icons';

const FolderAssociationButton = ({ folder, onClickMoveToFolder }) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const FolderButtonContent = () => (
    <HStack spacing={2} fontSize="sm">
      <Text color="blue.200" fontWeight="bold">
        <FontAwesomeIcon icon={folder ? faFolder : faFolderOutline} />
      </Text>
      <Text variant="link">
        {folder ? folder?.name : t('collection.add_to_folder')}
      </Text>
    </HStack>
  );

  return folder ? (
    <Menu>
      <MenuButton
        as={Button}
        size="sm"
        variant="outline"
        width="fit-content"
        minWidth={20}
      >
        <FolderButtonContent />
      </MenuButton>
      <MenuList>
        <MenuItem onClick={onClickMoveToFolder}>
          {t('collection.change_folder')}
        </MenuItem>
        <MenuItem as={Link} to={`/${slug}/folder/${folder?.id}`}>
          {t('collection.go_to_folder')}
        </MenuItem>
      </MenuList>
    </Menu>
  ) : (
    <Button
      size="sm"
      variant="outline"
      width="fit-content"
      minWidth={20}
      onClick={onClickMoveToFolder}
    >
      <FolderButtonContent />
    </Button>
  );
};

export default FolderAssociationButton;
