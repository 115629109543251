import InfiniteScroll from 'react-infinite-scroller';
import { useUi } from 'providers/UiProvider';

const InfiniteScrollHelper = ({ children, ...rest }) => {
  const { scrollRef, scrollParent } = useUi();
  return (
    <InfiniteScroll
      {...rest}
      initialLoad={false}
      useWindow={false}
      getScrollParent={() =>
        scrollParent?.current ? scrollParent?.current : scrollRef?.current
      }
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollHelper;
