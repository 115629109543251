import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea
} from '@chakra-ui/react';
import { CreatableSelect } from 'chakra-react-select';
import { useEffect, useState } from 'react';

const InviteForm = ({ defaultValues, onSubmit }) => {
  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitted, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (isSubmitted) {
      setInputValue('');
      reset({ ...defaultValues, emails: [] });
    }
  }, [defaultValues, isSubmitted, reset]);

  const addField = (e, field) => {
    setInputValue('');
    if (field.value) {
      field.onChange([
        ...field.value,
        {
          label: e.target.value,
          value: e.target.value
        }
      ]);
    } else {
      field.onChange([
        {
          label: e.target.value,
          value: e.target.value
        }
      ]);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 0 }}>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>{t('common.to')}</FormLabel>
          <Controller
            name="emails"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                placeholder={t('placeholder.enter_one_or_more_email_addresses')}
                inputValue={inputValue}
                onInputChange={(textInput, { action }) => {
                  if (action === 'input-change') {
                    setInputValue(textInput);
                  } else if (action === 'set-value') {
                    setInputValue('');
                  }
                }}
                onBlur={e => {
                  setInputValue('');
                  if (/\S+@\S+\.\S+/.test(e.target.value)) {
                    addField(e, field);
                  }
                }}
                onKeyDown={e => {
                  if (e.code === 'Space' || e.code === 'Comma') {
                    e.preventDefault();
                    addField(e, field);
                  }
                }}
                isMulti
                components={{
                  DropdownIndicator: () => null,
                  Menu: () => null
                }}
              />
            )}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t('common.subject')}</FormLabel>
          <Input {...register('subject', { required: true })} />
        </FormControl>
        <FormControl>
          <FormLabel>{t('common.message')}</FormLabel>
          <Textarea {...register('message', { required: true })} />
        </FormControl>
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="blue"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          {t('common.send')}
        </Button>
      </Stack>
    </form>
  );
};

export default InviteForm;
