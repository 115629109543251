import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box, Skeleton, SimpleGrid } from '@chakra-ui/react';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import MemberListItem from './MemberListItem';

const MemberList = ({ search }) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const {
    status,
    data: members,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage,
    refetch: fetchMembers
  } = useInfiniteQuery(
    ['members', search],
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      params.append('profiles__hive__slug', slug);
      if (search) {
        params.append('search', search);
      }
      const { data } = await api.get(`/users?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers, search]);

  return (
    <Box>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.members').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <InfiniteScrollHelper
            hasMore={!isFetching && hasNextPage}
            loadMore={fetchNextPage}
          >
            <SimpleGrid columns={[1, 1, 2, 3]} spacing={8}>
              {members.pages.map(page =>
                page.results.map(member => (
                  <MemberListItem key={member.id} member={member} />
                ))
              )}
            </SimpleGrid>
            {isFetching && (
              <LoadingIndicator>
                <SimpleGrid
                  width="full"
                  height="full"
                  columns={[1, 1, 2, 3]}
                  spacing={8}
                >
                  {[...Array(2)].map((_, index) => (
                    <Skeleton key={index} width="100%" height="100%" />
                  ))}
                </SimpleGrid>
              </LoadingIndicator>
            )}
          </InfiniteScrollHelper>
        ) : null}
      </LoadingWrapper>
    </Box>
  );
};

export default MemberList;
