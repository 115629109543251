import { Text, useDisclosure } from '@chakra-ui/react';
import AvatarGroupButton from 'components/AvatarGroupButton';
import { useTranslation } from 'react-i18next';
import ScorersModal from './ScorersModal';
import { useEffect } from 'react';

const ScorersButton = ({
  code,
  scores,
  parameters,
  max = 3,
  isAdminOrCollectionManager = false,
  focusedScore,
  resetFocusedScore,
  ...rest
}) => {
  const { t } = useTranslation();
  const {
    isOpen: showScorersModal,
    onOpen: openScorersModal,
    onClose: closeScorersModal
  } = useDisclosure();

  const scorers = scores?.map(score => score.creator);

  useEffect(() => {
    if (focusedScore?.scoreId) {
      openScorersModal();
    }
  }, [
    showScorersModal,
    openScorersModal,
    closeScorersModal,
    focusedScore,
    scores
  ]);

  return (
    <>
      {scores.length > 0 ? (
        <>
          <AvatarGroupButton
            max={max}
            members={scorers}
            onClick={openScorersModal}
            {...rest}
          />
          <ScorersModal
            code={code}
            scores={scores}
            parameters={parameters}
            isOpen={showScorersModal}
            onClose={() => {
              if (resetFocusedScore) {
                resetFocusedScore();
              }
              closeScorersModal();
            }}
            isAdminOrCollectionManager={isAdminOrCollectionManager}
            focusedScore={focusedScore}
          />
        </>
      ) : (
        <Text>{t('chart.no_scores_yet')}</Text>
      )}
    </>
  );
};

export default ScorersButton;
