import { Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';

const Answerer = ({ creator, answeredAt }) => {
  const { slug } = useParams();
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      alignItems={{ base: 'flex-end', md: 'center' }}
      justifyContent={{ base: 'flex-end', md: null }}
    >
      <Text
        variant="light"
        fontSize="sm"
        as={Link}
        to={`/${slug}/member/${creator.id}`}
        _hover={{ textDecoration: 'underline' }}
      >
        {creator.full_name + ', '}
      </Text>
      <Text variant="muted">
        {`${format(new Date(answeredAt), 'd MMM yyyy, HH:mm')}`}
      </Text>
    </Stack>
  );
};

export default Answerer;
