import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';

const BackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <Button
        variant="outline"
        onClick={() => {
          navigate(-1);
        }}
        display={['none', null, 'inline-flex']}
      >
        <HStack>
          <FontAwesomeIcon icon={faArrowLeft} />
          <Text>{t('button.back')}</Text>
        </HStack>
      </Button>
    </Box>
  );
};

export default BackButton;
