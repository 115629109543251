import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  HStack,
  Progress,
  Radio,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';

const SurveyPreviewFields = ({ survey, fieldIndex, onDone, onNext }) => {
  const { t } = useTranslation();

  const currentField = survey.fields[fieldIndex];

  const goToNextField = () => {
    if (fieldIndex === survey.fields.length - 1) {
      onDone();
    } else {
      onNext();
    }
  };

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    if (currentField) {
      reset();
    }
  }, [currentField, reset]);

  return (
    <Stack spacing={4}>
      <Flex position="fixed" left={0} top={0} width="full">
        <Progress
          value={fieldIndex + 1}
          max={survey.fields.length > 0 ? survey.fields.length : 1}
          width="full"
          height="4px"
          colorScheme="teal"
        />
      </Flex>
      <HStack>
        <Heading fontSize="xl">{`${fieldIndex + 1}. ${
          currentField?.field
        }`}</Heading>
      </HStack>
      {currentField && (
        <Stack spacing={8}>
          {currentField.type === 'INPUT' && (
            <form id="survey-form" onSubmit={handleSubmit(goToNextField)}>
              <FormControl>
                <Textarea {...register('answer')} />
              </FormControl>
            </form>
          )}
          {currentField.type === 'MULTI_SELECT' && (
            <form id="survey-form" onSubmit={handleSubmit(goToNextField)}>
              <FormControl>
                <Stack>
                  {currentField.options.map(option => (
                    <Controller
                      key={option.id}
                      control={control}
                      name={`${option.id}`}
                      render={({ field: { onChange, value } }) => (
                        <Box
                          borderWidth={1}
                          borderRadius="lg"
                          cursor="pointer"
                          padding={4}
                          onClick={() => {
                            onChange(!value);
                          }}
                        >
                          <HStack spacing={4}>
                            <Checkbox isChecked={value} pointerEvents="none" />
                            <Text>{option.option}</Text>
                          </HStack>
                        </Box>
                      )}
                    />
                  ))}
                </Stack>
              </FormControl>
            </form>
          )}
          {currentField.type === 'SINGLE_SELECT' && (
            <form id="survey-form" onSubmit={handleSubmit(goToNextField)}>
              <FormControl>
                <Stack>
                  {currentField.options.map(option => (
                    <Controller
                      key={option.id}
                      control={control}
                      name="option"
                      render={({ field: { onChange, value } }) => (
                        <Box
                          borderWidth={1}
                          borderRadius="lg"
                          cursor="pointer"
                          padding={4}
                          onClick={() => onChange(option.id)}
                        >
                          <HStack spacing={4}>
                            <Radio
                              isChecked={value === option.id}
                              pointerEvents="none"
                            />
                            <Text>{option.option}</Text>
                          </HStack>
                        </Box>
                      )}
                    />
                  ))}
                </Stack>
              </FormControl>
            </form>
          )}
        </Stack>
      )}
      <Flex alignItems="center" justifyContent="flex-end">
        <Button
          form="survey-form"
          type="submit"
          colorScheme="teal"
          isLoading={isSubmitting}
        >
          {fieldIndex === survey.fields.length - 1
            ? t('common.done')
            : t('common.next')}
        </Button>
      </Flex>
    </Stack>
  );
};

export default SurveyPreviewFields;
