import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react';
import MemberAvatar from 'components/MemberAvatar';
import LoadingWrapper from 'components/LoadingWrapper';
import CardList from 'features/card/CardList';
import api from 'utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-duotone-svg-icons';

const MemberDetailed = () => {
  const { memberId } = useParams();

  const { slug } = useParams();
  const { data: current } = useQuery(['profile', slug]);

  const { t } = useTranslation();

  const {
    status,
    data: profile,
    isSuccess
  } = useQuery(['member', memberId], async () => {
    const { data } = await api.get(
      `/profiles?user=${memberId}&hive__slug=${slug}`
    );
    if (data.length > 0) {
      return data[0];
    }
    return null;
  });

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.member').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={8}>
            <VStack position="relative" borderWidth="1px" padding={8}>
              {profile.id === current.id && (
                <Flex alignSelf="flex-end">
                  <Button
                    variant="outline"
                    as={Link}
                    to={`/${slug}/settings`}
                    size="sm"
                  >
                    <HStack>
                      <FontAwesomeIcon icon={faCog} />
                      <Text>{t('common.settings')}</Text>
                    </HStack>
                  </Button>
                </Flex>
              )}
              <Stack alignItems="center" spacing={4}>
                <MemberAvatar member={profile.user} size="xl" />
                <Heading fontSize="xl">{profile.user.full_name}</Heading>
                <HStack>
                  <Badge>{profile.role}</Badge>
                  {profile?.group && <Badge>{profile.group.name}</Badge>}
                </HStack>
              </Stack>
            </VStack>
            <Tabs size="sm" variant="fresh" isLazy>
              <TabList>
                <Tab>{t('common.contributions')}</Tab>
                <Tab>{t('common.assigned_to')}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <CardList filter={{ creator: memberId }} />
                </TabPanel>
                <TabPanel>
                  <CardList filter={{ assignee: memberId }} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default MemberDetailed;
