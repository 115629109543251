import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Center,
  Container,
  Image,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useMsal } from '@azure/msal-react';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import LoadingIndicator from 'components/LoadingIndicator';
import { useAuth } from 'providers/AuthProvider';
import api from 'utils/api';

const AuthSilent = () => {
  const [error, setError] = useState(false);
  const { authenticate } = useAuth();
  const { instance } = useMsal();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const slug = searchParams.get('slug') || localStorage.getItem('slug');

  const { data: hive, isLoading } = useQuery(
    ['hive', slug],
    async () => {
      const { data } = await api.get(`/public/hives/${slug}`);
      return data;
    },
    {
      enabled: slug != null,
      retry: false,
      onError: () => {}
    }
  );

  const logo = useColorModeValue(
    hive?.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive?.light_logo ? hive.light_logo : '/hives_light.png'
  );

  useEffect(() => {
    const request = {
      scopes: ['User.Read'],
      redirectUri: `${process.env.REACT_APP_URL}/spa/auth/microsoft`,
      responseType: 'code'
    };

    const handleOnResponse = async response => {
      const { data } = await api.post('/auth/microsoft-silent', {
        token: response.accessToken
      });
      localStorage.setItem('token', data.token);
      authenticate(data.token);
      let redirect = '/join';
      if (slug) {
        redirect = `/${slug}`;
      }
      navigate(redirect);
    };
    async function login() {
      try {
        const response = await instance.ssoSilent(request);
        handleOnResponse(response);
      } catch (e) {
        console.log(e);
        if (e instanceof InteractionRequiredAuthError) {
          try {
            const response = await instance.loginPopup(request);
            handleOnResponse(response);
          } catch (e2) {
            console.log(e2);
            setError(true);
          }
        } else {
          setError(true);
        }
      }
    }
    login();
  }, [slug, instance, authenticate, navigate, searchParams]);

  return (
    <Container maxW="container.md">
      <Stack spacing={8}>
        {!isLoading && (
          <Center py={10}>
            <Image src={logo} height="40px" />
          </Center>
        )}
        {error ? (
          <Text textAlign="center">{t('login.silent_auth_error')}</Text>
        ) : (
          <LoadingIndicator />
        )}
      </Stack>
    </Container>
  );
};

export default AuthSilent;
