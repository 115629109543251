import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Textarea
} from '@chakra-ui/react';

const TextareaForm = ({ defaultValue, labelText, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="value">
          {labelText && <FormLabel>{labelText}</FormLabel>}
          <Textarea
            rows={5}
            {...register('value')}
            defaultValue={defaultValue}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default TextareaForm;
