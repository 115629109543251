import React from 'react';
import { useQuery } from 'react-query';
import { Stack } from '@chakra-ui/react';
import api from 'utils/api';
import CardFields from './CardFields';
import { getContributorFields } from './cardUtils';

const ContributorFeedback = ({ card }) => {
  const { data: me } = useQuery('me');

  const { data: panel } = useQuery(['panel', card.id, card.step], async () => {
    const params = new URLSearchParams({
      card: card.id
    });
    if (card.step) {
      params.append('step', card.step.id);
    } else {
      params.append('step__isnull', 'true');
    }
    const { data } = await api.get(`/panels?${params.toString()}`);
    if (data.length > 0) {
      return data[0];
    }
    return null;
  });

  const fields = getContributorFields(card, me, panel);

  return (
    <Stack spacing={4}>
      <CardFields
        card={card}
        fields={fields}
        panel={panel}
        forReport={false}
        lastField={fields.length > 0 ? null : fields[fields.length - 1]}
        panelMember={panel?.members.some(m => m.id === me?.id)}
      />
    </Stack>
  );
};

export default ContributorFeedback;
