import { Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { SimpleGrid, Stack, Text } from '@chakra-ui/react';

const TagFilter = ({ tags, onChange }) => {
  const { t } = useTranslation();
  return (
    <SimpleGrid columns={[1, 2]} spacing={4}>
      <Stack>
        <Text variant="muted">{t('common.tags')}</Text>
        <Select
          variant="flushed"
          options={tags}
          placeholder={t('common.select')}
          onChange={onChange}
          getOptionLabel={tag => tag.name}
          getOptionValue={tag => tag.id}
          isMulti
          useBasicStyles
        />
      </Stack>
    </SimpleGrid>
  );
};

export default TagFilter;
