import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react';
import CollectionList from './CollectionList';

const CollectionDrawer = ({ header, onClose, isOpen, workflowId }) => (
  <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="md">
    <DrawerOverlay />
    <DrawerContent>
      {header && <DrawerHeader>{header}</DrawerHeader>}
      <DrawerCloseButton />
      <DrawerBody>
        <CollectionList
          columns={[1]}
          workflowId={workflowId}
          breakpointCols={{
            default: 1
          }}
        />
      </DrawerBody>
      <DrawerFooter />
    </DrawerContent>
  </Drawer>
);

export default CollectionDrawer;
