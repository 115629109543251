import { useParams } from 'react-router-dom';
import { Flex, Heading, HStack, Text } from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import ItemWrapper from 'components/ItemWrapper';
import { getCollectingPlural, getCollectingSingular } from './collectionUtils';

const CollectionListItem = ({ collection, onClick }) => {
  const { slug } = useParams();

  return (
    <ItemWrapper
      direction="row"
      to={
        onClick
          ? null
          : {
              pathname: `/${slug}/collection/${collection.id}`
            }
      }
      onClick={onClick ? onClick : () => {}}
    >
      <HStack spacing={4}>
        <CoverMedia
          object={collection}
          height={['100px']}
          minW={['80px', '120px', null]}
          maxW={['80px', '120px', null]}
        />
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          height="full"
        >
          <Heading fontSize="md" noOfLines={3} wordBreak="break-word">
            {collection.title}
          </Heading>
          <Text fontSize="xs" textTransform="lowercase">
            {`${collection.metrics.cards} ${
              collection.metrics.cards === 1
                ? getCollectingSingular(collection.type)
                : getCollectingPlural(collection.type)
            }`}
          </Text>
        </Flex>
      </HStack>
    </ItemWrapper>
  );
};

export default CollectionListItem;
