import { Stack } from '@chakra-ui/react';
import SurveyAnswer from './SurveyAnswer';
import SurveySelection from './SurveySelection';

const SurveyResultTab = ({ fields }) => {
  return (
    <Stack spacing={8}>
      {fields.map(field =>
        field.type === 'MULTI_SELECT' || field.type === 'SINGLE_SELECT' ? (
          <SurveySelection key={field.id} field={field} />
        ) : (
          <SurveyAnswer key={field.id} field={field} />
        )
      )}
    </Stack>
  );
};

export default SurveyResultTab;
