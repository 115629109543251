import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Container, Heading, SimpleGrid, Stack } from '@chakra-ui/react';
import api from 'utils/api';
import LoadingWrapper from 'components/LoadingWrapper';
import SubfolderListItem from './SubfolderListItem';
import { useParams } from 'react-router-dom';
import EmptyState from 'components/EmptyState';

const FolderArchivedPage = () => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    data: folders,
    status,
    isSuccess
  } = useQuery(['archived', 'folders'], async () => {
    const { data } = await api.get(`/folders?archived_at__isnull=false`);
    return data;
  });

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <Heading fontSize="3xl">{t('common.archived_plural')}</Heading>
        <LoadingWrapper statuses={[status]}>
          {isSuccess &&
            (folders.length === 0 ? (
              <EmptyState
                title={t('common.nothing_here_yet')}
                description={t('folder.archived_empty_state_description')}
                filename="tree.svg"
                borderWidth={0}
              />
            ) : (
              <SimpleGrid
                columns={[1, 2, 3, 4]}
                spacing={4}
                gridTemplateRows="masonry"
              >
                {folders.map(folder => (
                  <SubfolderListItem
                    key={folder.id}
                    slug={slug}
                    folder={folder}
                  />
                ))}
              </SimpleGrid>
            ))}
        </LoadingWrapper>
      </Stack>
    </Container>
  );
};

export default FolderArchivedPage;
