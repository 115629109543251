import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text
} from '@chakra-ui/react';
import Description from 'components/Description';
import api from 'utils/api';

const ConsentModal = () => {
  const [consented, setConsented] = useState(false);
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { data: profile } = useQuery(['profile', slug]);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    payload => api.patch(`/profiles/${profile.id}/consent`, payload),
    {
      onSuccess: ({ data: profile }) => {
        queryClient.setQueryData(['profile', slug], profile);
      }
    }
  );

  const handleOnSubmitConsent = async () => {
    try {
      await mutateAsync();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      isOpen={true}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => {}}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('common.consent')}</ModalHeader>
        <ModalBody>
          <Stack spacing={8}>
            <Description>{hive.consent_text}</Description>
            <HStack>
              <Switch
                value={consented}
                onChange={_ => {
                  setConsented(!consented);
                }}
              />
              <Text>{t('consent.agree')}</Text>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="teal"
            alignSelf="flex-start"
            isDisabled={!consented}
            isLoading={isLoading}
            onClick={handleOnSubmitConsent}
          >
            {t('button.submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConsentModal;
