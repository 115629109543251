import { useTranslation } from 'react-i18next';
import {
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import CardList from 'features/card/CardList';
import { useUi } from 'providers/UiProvider';

let timeout = null;

const FeedPage = () => {
  const { filter, setFilter } = useUi();
  const { t } = useTranslation();

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <Heading fontSize="3xl">{t('common.feed')}</Heading>

        <InputGroup
          justifySelf={['flex-start', null, 'flex-end']}
          alignSelf="flex-end"
          maxWidth={['full', null, '280px']}
        >
          <InputLeftElement pointerEvents="none">
            <FontAwesomeIcon icon={faSearch} />
          </InputLeftElement>
          <Input
            placeholder={t('placeholder.search')}
            onChange={e => {
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                setFilter({ ...filter, search: e.target.value });
              }, 800);
            }}
          />
        </InputGroup>

        <CardList filter={filter} />
      </Stack>
    </Container>
  );
};

export default FeedPage;
