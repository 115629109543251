import { Flex, Text } from '@chakra-ui/react';
import MemberAvatar from 'components/MemberAvatar';

const AnswerListItem = ({ answer, onClick }) => {
  return (
    <Flex
      key={answer.id}
      flexDirection="column"
      justifyContent="space-between"
      cursor="pointer"
      rounded="lg"
      minHeight="220px"
      borderWidth={1}
      padding={6}
      onClick={onClick}
    >
      <Text noOfLines={5}>{answer.answer}</Text>
      <MemberAvatar
        zIndex={-1}
        member={answer?.creator}
        link={false}
        size="xs"
      />
    </Flex>
  );
};

export default AnswerListItem;
