import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Textarea
} from '@chakra-ui/react';
import TitleInput from 'components/TitleInput';

const ProjectStepForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <TitleInput control={control} />
        </FormControl>
        <FormControl id="description">
          <FormLabel>{t('common.description')}</FormLabel>
          <Textarea
            rows={5}
            {...register('description')}
            defaultValue={defaultValues?.description}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default ProjectStepForm;
