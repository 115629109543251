import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePDF } from 'react-to-pdf';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Image as ChakraImage,
  LightMode,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import LoadingWrapper from 'components/LoadingWrapper';
import Description from 'components/Description';
import api from 'utils/api';

const getBase64FromUrl = async url => {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = `${url}?__v=${Date.now()}`;
  return new Promise(resolve => {
    img.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const base64String = canvas.toDataURL('image/png');
      resolve(base64String);
    };
  });
};

const CollectionReportDetailed = () => {
  const [exporting, setExporting] = useState(false);
  const { toPDF, targetRef } = usePDF();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const { reportId } = useParams();
  const { t } = useTranslation();

  const {
    data: report,
    status,
    isSuccess
  } = useQuery(['report', reportId], async () => {
    const { data } = await api.get(
      `/collections/reports/${reportId}?hive__slug=${slug}`
    );
    return data;
  });

  const logo = useColorModeValue(
    hive.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive.light_logo ? hive.light_logo : '/hives_light.png'
  );

  const handleOnClickExport = async () => {
    setExporting(true);
    await toPDF({
      overrides: {
        canvas: {
          onclone: async (_, html) => {
            const images = html.querySelectorAll('img');
            for await (const img of images) {
              if (img.src.includes('data:image')) continue;
              img.src = await getBase64FromUrl(img.src);
            }
          }
        }
      }
    });
    setExporting(false);
  };

  return (
    <LightMode>
      <Container maxW="container.lg" marginY={8}>
        <LoadingWrapper statuses={[status]}>
          {isSuccess && (
            <Stack spacing={8}>
              <Flex alignItems="center" justifyContent="space-between">
                <Heading fontSize="3xl">{t('common.report')}</Heading>
                <Button
                  colorScheme="teal"
                  onClick={handleOnClickExport}
                  isLoading={exporting}
                >
                  <HStack>
                    <FontAwesomeIcon icon={faDownload} />
                    <Text>{t('common.download_pdf')}</Text>
                  </HStack>
                </Button>
              </Flex>
              <div ref={targetRef}>
                <Box
                  padding={16}
                  borderWidth={1}
                  rounded="lg"
                  data-theme="light"
                  bg="chakra-body-bg"
                  color="chakra-body-text"
                >
                  <Stack spacing={8}>
                    <Flex>
                      <ChakraImage src={logo} height={8} />
                    </Flex>
                    <Heading fontSize="2xl">{report.title}</Heading>
                    <Stack spacing={4}>
                      <Description>{report.report}</Description>
                    </Stack>
                  </Stack>
                </Box>
              </div>
            </Stack>
          )}
        </LoadingWrapper>
      </Container>
    </LightMode>
  );
};

export default CollectionReportDetailed;
