import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';

const TitleForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="title">
          <FormLabel htmlFor="title">{t('common.title')}</FormLabel>
          <Input {...register('title_override')} />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default TitleForm;
