import { useTranslation } from 'react-i18next';
import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSliders } from '@fortawesome/pro-regular-svg-icons';

let timeout = null;

const CollectionSearchFilter = ({
  filter,
  setFilter,
  showFilter,
  toggleFilter
}) => {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="flex-end" spacing={4}>
      <HStack>
        <Button
          variant="outline"
          onClick={() => {
            if (showFilter) {
              setFilter({
                ...filter,
                ordering: '-created_at',
                step: null
              });
            }
            toggleFilter();
          }}
        >
          <FontAwesomeIcon icon={faSliders} />
        </Button>
        <InputGroup
          justifySelf={['flex-start', null, 'flex-end']}
          maxWidth={['full', null, '280px']}
        >
          <InputLeftElement pointerEvents="none">
            <FontAwesomeIcon icon={faSearch} />
          </InputLeftElement>
          <Input
            placeholder={t('placeholder.search')}
            onChange={e => {
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                setFilter({ ...filter, search: e.target.value });
              }, 800);
            }}
          />
        </InputGroup>
      </HStack>
    </Stack>
  );
};

export default CollectionSearchFilter;
