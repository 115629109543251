import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button, Flex, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import DomainForm from './DomainForm';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const DomainList = () => {
  const {
    isOpen: showCreateDomainModal,
    onOpen: openCreateDomainModal,
    onClose: closeCreateDomainModal
  } = useDisclosure();
  const {
    isOpen: showDeleteDomainModal,
    onOpen: openDeleteDomainModal,
    onClose: closeDeleteDomainModal
  } = useDisclosure();
  const {
    isOpen: showUpdateDomainModal,
    onOpen: openUpdateDomainModal,
    onClose: closeUpdateDomainModal
  } = useDisclosure();

  const [selectedDomain, setSelectedDomain] = useState(null);

  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const queryClient = useQueryClient();

  const toast = useToast();

  const { t } = useTranslation();

  const {
    status,
    data: domains,
    isSuccess
  } = useQuery('domains', async () => {
    const { data } = await api.get(`/domains?hive=${hive.id}`);
    return data;
  });

  const createDomainMutation = useMutation(
    async domain => await api.post('/domains', domain),
    {
      onSuccess: ({ data: domain }) => {
        closeCreateDomainModal();
        const domains = queryClient.getQueryData('domains');
        queryClient.setQueryData('domains', [...domains, domain]);
        toast({
          title: 'Domain was successfully added.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const deleteDomainMutation = useMutation(
    () => api.delete(`/domains/${selectedDomain.id}`),
    {
      onSuccess: () => {
        closeDeleteDomainModal();
        const domains = queryClient.getQueryData('domains');
        queryClient.setQueryData(
          'domains',
          domains.filter(d => d.id !== selectedDomain.id)
        );
        setSelectedDomain(null);
        toast({
          title: 'Domain was successfully removed.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const updateDomainMutation = useMutation(
    domain => api.patch(`/domains/${selectedDomain.id}`, domain),
    {
      onSuccess: ({ data: domain }) => {
        closeUpdateDomainModal();
        const domains = queryClient.getQueryData('domains');
        queryClient.setQueryData(
          'domains',
          domains.map(d => (d.id === domain.id ? domain : d))
        );
        setSelectedDomain(null);
        toast({
          title: 'Domain was successfully updated.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnCreateDomain = async domain => {
    await createDomainMutation.mutateAsync({ ...domain, hive: hive.id });
  };

  const handleOnDeleteDomain = async () => {
    await deleteDomainMutation.mutateAsync();
  };

  const handleOnUpdateDomain = async domain => {
    await updateDomainMutation.mutateAsync(domain);
  };

  return (
    <LoadingWrapper
      indicator={undefined}
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.domains').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <>
          <Stack spacing={8}>
            <Stack spacing={4}>
              {domains.map(domain => (
                <Flex
                  px={4}
                  py={2}
                  borderWidth="1px"
                  rounded="lg"
                  key={domain.id}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="link"
                    onClick={() => {
                      setSelectedDomain(domain);
                      openUpdateDomainModal();
                    }}
                  >
                    {domain.domain}
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => {
                      setSelectedDomain(domain);
                      openDeleteDomainModal();
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </Button>
                </Flex>
              ))}
            </Stack>
            <Flex justifyContent="flex-end">
              <Button colorScheme="teal" onClick={openCreateDomainModal}>
                {t('button.create')}
              </Button>
            </Flex>
          </Stack>
          <ModalWrapper
            title={t('admin.add_domain')}
            size="full"
            isOpen={showCreateDomainModal}
            onClose={closeCreateDomainModal}
          >
            <DomainForm
              isOpen={showCreateDomainModal}
              onSubmit={handleOnCreateDomain}
            />
          </ModalWrapper>
          {selectedDomain && (
            <>
              <ConfirmationModal
                deleteText={t(
                  'confirmation.this_information_will_be_permanently_removed_and_cannot_be_restored'
                )}
                isOpen={showDeleteDomainModal}
                onClose={closeDeleteDomainModal}
                onDelete={handleOnDeleteDomain}
              />
              <ModalWrapper
                title={t('common.domain')}
                isOpen={showUpdateDomainModal}
                onClose={closeUpdateDomainModal}
              >
                <DomainForm
                  defaultValues={selectedDomain}
                  isOpen={showUpdateDomainModal}
                  onSubmit={handleOnUpdateDomain}
                />
              </ModalWrapper>
            </>
          )}
        </>
      ) : null}
    </LoadingWrapper>
  );
};

export default DomainList;
