import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, Input, Stack } from '@chakra-ui/react';

const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack spacing={4}>
        <FormControl id="email">
          <Input
            placeholder={t('placeholder.name_at_work_email_com')}
            {...register('email', {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            })}
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          {t('button.continue')}
        </Button>
      </Stack>
    </form>
  );
};

export default LoginForm;
