import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';
import DescriptionFormControl from 'components/DescriptionFormControl';
import ImageSelect from 'components/ImageSelect';
import TitleInput from 'components/TitleInput';

const SurveyForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const values = watch();

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="image">
          {!defaultValues && (
            <>
              <FormLabel display="inline-block">{`${t(
                'common.choose_media'
              )}`}</FormLabel>
              <FormHelperText display="inline">{`(${t(
                'common.optional'
              )})`}</FormHelperText>
            </>
          )}
          <ImageSelect
            object={values}
            setValue={setValue}
            uploadVideo
            preloadImage={!defaultValues}
          />
        </FormControl>
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <TitleInput control={control} />
        </FormControl>
        <DescriptionFormControl
          control={control}
          setValue={text => setValue('description', text)}
        />
        <Input {...register('image')} hidden />
        <Input {...register('unsplash')} hidden />
        <Input {...register('video')} hidden />
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default SurveyForm;
