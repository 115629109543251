import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure
} from '@chakra-ui/react';
import CardDetailed from 'features/card/CardDetailed';
import api from 'utils/api';
import NotificationList from './NotificationList';

const NotificationDrawer = ({ onClose, isOpen }) => {
  const [cardId, setCardId] = useState();
  const { t } = useTranslation();
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);

  const queryClient = useQueryClient();

  const {
    isOpen: showCardModal,
    onOpen: openCardModal,
    onClose: closeCardModal
  } = useDisclosure();

  const handleOnClickCard = cardId => {
    setCardId(cardId);
    openCardModal();
  };

  const handleOnClickMarkAllAsRead = async () => {
    try {
      await api.post(`/profiles/${profile.id}/mark-notifications-as-read`);
      const notifications = queryClient.getQueryData('notifications');
      if (notifications && notifications.pages) {
        const pages = notifications.pages.map(page => {
          return {
            ...page,
            results: page.results.map(n => ({ ...n, read: true }))
          };
        });
        queryClient.setQueryData('notifications', {
          ...notifications,
          pages
        });
        queryClient.setQueryData(['profile', slug], {
          ...profile,
          unread_notifications: 0
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const hasUnreadNotification = profile.unread_notifications > 0;

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{t('common.notifications')}</DrawerHeader>
        <DrawerBody>
          <NotificationList onClickCard={handleOnClickCard} />
          <CardDetailed
            cardId={cardId}
            isOpen={showCardModal}
            onClose={() => {
              setCardId(null);
              closeCardModal();
            }}
          />
        </DrawerBody>
        <DrawerFooter>
          <Button
            isDisabled={!hasUnreadNotification}
            variant="outline"
            colorScheme="red"
            onClick={handleOnClickMarkAllAsRead}
          >
            {t('notifications.mark_all_as_read')}
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NotificationDrawer;
