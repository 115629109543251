import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Badge, Flex, Heading, HStack } from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import ItemWrapper from 'components/ItemWrapper';

const SurveyListItem = ({ survey, onClick }) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  return (
    <ItemWrapper
      direction="row"
      to={
        onClick
          ? null
          : {
              pathname: `/${slug}/survey/${survey.id}`
            }
      }
      onClick={onClick ? onClick : () => {}}
    >
      <HStack spacing={4}>
        <CoverMedia
          object={survey}
          height={['100px']}
          minW={['80px', '120px', null]}
          maxW={['80px', '120px', null]}
        />
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          height="full"
        >
          <Heading fontSize="md" noOfLines={3} wordBreak="break-word">
            {survey.title}
          </Heading>
          <Badge alignSelf="flex-start">{t('common.survey')}</Badge>
        </Flex>
      </HStack>
    </ItemWrapper>
  );
};

export default SurveyListItem;
