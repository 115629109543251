import { AsyncSelect } from 'chakra-react-select';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button, Stack, Text } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const fetchCollections = async (query, slug) => {
  const { data } = await api.get(
    `/collections?search=${query}&hive__slug=${slug}&transferable=true`
  );
  return data;
};

const ChangeCollectionModal = ({ collection, isOpen, onClose, onSubmit }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  const {
    data: collections,
    status,
    isSuccess,
    refetch
  } = useQuery(
    ['collections', { hive__slug: slug }],
    async () => {
      const { data } = await api.get(
        `/collections?hive__slug=${slug}&transferable=true`
      );
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [refetch, isOpen]);

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  return (
    <ModalWrapper
      headerContent={t('collection.change_collection')}
      isOpen={isOpen}
      onClose={onClose}
    >
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.collections').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <Text>{t('card.change_collection_warning')}</Text>
              <Controller
                control={control}
                name="collection"
                rules={{ required: true }}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    defaultValue={collection}
                    defaultOptions={collections}
                    loadOptions={query => fetchCollections(query, slug)}
                    placeholder={t('placeholder.search')}
                    getOptionLabel={collection => collection.title}
                    getOptionValue={collection => collection.id}
                    useBasicStyles
                  />
                )}
              />
              <Button
                type="submit"
                alignSelf="flex-end"
                colorScheme="teal"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                {t('button.change')}
              </Button>
            </Stack>
          </form>
        )}
      </LoadingWrapper>
    </ModalWrapper>
  );
};

export default ChangeCollectionModal;
