import { useTranslation } from 'react-i18next';
import {
  Center,
  HStack,
  Progress,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react';
import IconTooltip from 'components/tooltips/IconTooltip';

const AverageScore = ({ chart, averages, totals }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={8}>
      <Text variant="muted">
        {chart.average ? t('card.average_score') : t('card.total_score')}
      </Text>
      {chart.parameters.map((parameter, index) => (
        <Stack key={index}>
          <HStack>
            <HStack spacing={4}>
              <Text variant="muted" wordBreak="break-word">
                {parameter.label}
              </Text>
              <Center>
                <IconTooltip color="gray" label={parameter.description} />
              </Center>
            </HStack>
            <Spacer />
            {chart.average ? (
              <HStack
                alignItems="flex-end"
                opacity={averages[index] >= parameter.min ? 1 : 0.4}
              >
                <Text variant="muted">{t('common.average_abbreviated')}</Text>
                <Text fontWeight="bold" fontSize="sm" whiteSpace="nowrap">
                  {averages[index] >= parameter.min
                    ? averages[index].toFixed(1)
                    : '--'}
                </Text>
                <Text
                  variant="muted"
                  whiteSpace="nowrap"
                >{`/ ${parameter.max}`}</Text>
              </HStack>
            ) : (
              <Text fontWeight="bold" fontSize="sm" whiteSpace="nowrap">
                {totals[index].toFixed(1)}
              </Text>
            )}
          </HStack>
          {chart.average && (
            <Progress
              opacity={averages[index] >= parameter.min ? 1 : 0.25}
              rounded="lg"
              value={averages[index]}
              min={parameter.min}
              max={parameter.max}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default AverageScore;
