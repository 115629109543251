import { Badge, Center } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BadgeIcon = ({
  icon,
  width = 8,
  height = 8,
  rounded = 'md',
  colorScheme = 'gray',
  ...rest
}) => {
  return (
    <Badge
      height={height}
      width={width}
      rounded={rounded}
      colorScheme={colorScheme}
      {...rest}
    >
      <Center height="full" fontSize="md">
        <FontAwesomeIcon icon={icon} />
      </Center>
    </Badge>
  );
};

export default BadgeIcon;
