import { scaleOrdinal } from 'd3-scale';
import Wordcloud from 'react-d3-cloud';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  useColorModeValue,
  useTheme
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import { useCallback, useMemo, useState } from 'react';
import SurveyAnswerList from './SurveyAnswerList';

const AnswerDrawer = ({ filter, header, onClose, isOpen, onClickAnswer }) => {
  return (
    <Drawer
      blockScrollOnMount={false}
      placement="right"
      onClose={onClose}
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        {header && <DrawerHeader>{header}</DrawerHeader>}
        <DrawerCloseButton />
        <DrawerBody>
          <Stack spacing={2}>
            <SurveyAnswerList
              columns={1}
              filter={filter}
              onClickAnswer={onClickAnswer}
            />
          </Stack>
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

const SurveyAnswerWordcloud = ({ fieldId, emptyState, onClickAnswer }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [word, setWord] = useState();

  const shade = useColorModeValue('500', '200');

  const schemeCategory10ScaleOrdinal = scaleOrdinal([
    theme.colors['blue'][shade],
    theme.colors['orange'][shade],
    theme.colors['green'][shade],
    theme.colors['red'][shade],
    theme.colors['purple'][shade],
    theme.colors['pink'][shade]
  ]);

  const {
    status,
    data: wordcloud,
    isSuccess
  } = useQuery(['survey', 'wordcloud', fieldId], async () => {
    const { data } = await api.get(`/surveys/fields/${fieldId}/wordcloud`);
    return data;
  });

  const handleOnWordClick = useCallback((_, { text }) => {
    setWord(text);
  }, []);

  const wordcloudMemo = useMemo(
    () => (
      <Wordcloud
        data={wordcloud}
        width={300}
        height={200}
        font="Poppins"
        fontSize={word => Math.log2(word.value * 2) * 8}
        spiral="rectangular"
        rotate={0}
        padding={2}
        random={Math.random}
        fill={(_, i) => {
          const value = schemeCategory10ScaleOrdinal(i);
          return value;
        }}
        onWordClick={handleOnWordClick}
      />
    ),
    [handleOnWordClick, schemeCategory10ScaleOrdinal, wordcloud]
  );

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.wordcloud').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        wordcloud?.length > 0 ? (
          <Box className="wordcloud">{wordcloudMemo}</Box>
        ) : (
          emptyState
        )
      ) : null}
      <AnswerDrawer
        header={word}
        isOpen={word}
        onClose={() => setWord(null)}
        filter={{ search: word, field: fieldId }}
        onClickAnswer={onClickAnswer}
      />
    </LoadingWrapper>
  );
};

export default SurveyAnswerWordcloud;
