import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import ProjectList from './ProjectList';
import { Container, HStack, Heading, Stack } from '@chakra-ui/react';
import FilterButton from 'components/FilterButton';

const MyProjects = () => {
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const { t } = useTranslation();

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={8}>
        <HStack>
          {profile?.is_admin_or_manager && (
            <FilterButton
              title={t('common.channels')}
              as={Link}
              to={`/${slug}/channels`}
            />
          )}
          <FilterButton
            title={t('common.my_projects')}
            selected
            as={Link}
            to={`/${slug}/channels`}
          />
        </HStack>
        <Stack spacing={8}>
          <Heading fontSize="3xl">{t('common.my_projects')}</Heading>
          <ProjectList
            active
            connected
            headerText={t('common.ongoing_projects')}
          />
          <ProjectList connected headerText={t('common.archived_projects')} />
        </Stack>
      </Stack>
    </Container>
  );
};

export default MyProjects;
