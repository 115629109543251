import { AspectRatio, SimpleGrid, Skeleton, Stack } from '@chakra-ui/react';

const HomeSkeleton = () => (
  <Stack spacing={12}>
    <Stack spacing={4}>
      <Skeleton height="48px" maxWidth="300px" />
      <Skeleton height="32px" maxWidth="600px" />
    </Stack>
    <SimpleGrid columns={[1, null, 2]} spacing={8}>
      <AspectRatio ratio={16 / 9}>
        <Skeleton rounded="lg" />
      </AspectRatio>
      <Stack spacing={8}>
        <Skeleton height="32px" />
        <Skeleton height="96px" />
      </Stack>
    </SimpleGrid>
    <Skeleton height="48px" rounded="lg" />
    <SimpleGrid columns={[1, null, 3]} spacing={8}>
      <Skeleton height="96px" rounded="lg" />
      <Skeleton height="96px" rounded="lg" />
      <Skeleton height="96px" rounded="lg" />
    </SimpleGrid>
  </Stack>
);

export default HomeSkeleton;
