import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  Center,
  Heading,
  HStack,
  Image,
  Text
} from '@chakra-ui/react';

const CheckYourEmail = ({ email, showHeading = false }) => {
  const { t } = useTranslation();

  const handleOnClickOpenGmail = () => {
    window.open('https://gmail.com', '_blank');
  };

  const handleOnClickOpenOutlook = () => {
    window.open('https://outlook.com', '_blank');
  };

  return (
    <>
      {showHeading && <Heading as="h1">{t('login.check_your_email')}</Heading>}
      <Text>
        <Trans
          t={t}
          i18nKey="login.magic_link_sent"
          values={{
            email
          }}
          components={[null, <Text as="span" fontWeight="bold" />, null]}
        />
      </Text>
      <Center>
        <ButtonGroup size="sm" variant="ghost">
          <Button onClick={handleOnClickOpenGmail}>
            <HStack>
              <Image src="/gmail.svg" height={4} />
              <Text>{t('login.open_gmail')}</Text>
            </HStack>
          </Button>
          <Button onClick={handleOnClickOpenOutlook}>
            <HStack>
              <Image src="/outlook.svg" height={4} />
              <Text>{t('login.open_outlook')}</Text>
            </HStack>
          </Button>
        </ButtonGroup>
      </Center>
      <Text>{t('login.check_spam_folder')}</Text>
    </>
  );
};

export default CheckYourEmail;
