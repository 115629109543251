import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Flex,
  HStack,
  Image,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons';

const LoginHeader = ({ darkLogo, lightLogo, openLoginModal }) => {
  const { t } = useTranslation();

  const logo = useColorModeValue(
    darkLogo ? darkLogo : '/hives_dark.png',
    lightLogo ? lightLogo : '/hives_light.png'
  );

  return (
    <Flex borderBottomWidth={1} py={4} height="70px">
      <Container maxW="container.lg">
        <Flex alignItems="center" justifyContent="space-between">
          <Image src={logo} height={8} />
          <Button variant="outline" onClick={openLoginModal}>
            <HStack>
              <FontAwesomeIcon icon={faSignIn} />
              <Text>{t('common.sign_in')}</Text>
            </HStack>
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
};

export default LoginHeader;
