import { Link } from '@chakra-ui/react';

export const renderLink = ({ attributes, content }) => {
  const { href, ...props } = attributes;
  if (href.startsWith('mailto')) {
    return (
      <Link href={href} {...props}>
        {content}
      </Link>
    );
  }
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer" {...props}>
      {content}
    </Link>
  );
};
