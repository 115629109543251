import {
  HStack,
  Spacer,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useCard } from 'providers/CardProvider';
import { useTranslation } from 'react-i18next';

const ScoreTooltip = ({ active, payload, isCardAverage = false }) => {
  const { showCardModal } = useCard();
  const { t } = useTranslation();
  const bg = useColorModeValue('gray.100', 'gray.600');
  if (active && payload && payload.length) {
    return (
      <Stack bg={bg} p={4} opacity={0.9}>
        <Text fontWeight="bold" noOfLines={1}>
          {payload[0].payload.title}
        </Text>
        {payload.map((item, index) => (
          <HStack key={index}>
            <Text>{item.name}</Text>
            <Spacer />
            <Text fontWeight="bold">{item.value}</Text>
          </HStack>
        ))}
        {!isCardAverage && (
          <HStack>
            <Text>{t('common.comment')}</Text>
            <Spacer />
            <Text fontWeight="bold" noOfLines={1}>
              {payload[0].payload.comment?.length > 0
                ? `"${payload[0].payload.comment}"`
                : '-'}
            </Text>
          </HStack>
        )}
        {(!showCardModal || !isCardAverage) && (
          <Text opacity="0.4" fontWeight="light" fontStyle="italic">
            {t('common.click_to_open')}
          </Text>
        )}
      </Stack>
    );
  }
  return null;
};

export default ScoreTooltip;
