import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Select } from 'chakra-react-select';
import { Button, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import { useParams } from 'react-router-dom';

const CollectionWorkflowForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const {
    status,
    data: workflows,
    isSuccess
  } = useQuery(['workflows', slug], async () => {
    const params = new URLSearchParams({
      hive__slug: slug
    });
    const { data } = await api.get(`/workflows?${params.toString()}`);
    return data;
  });
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, isOpen, reset]);

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.workflows').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <FormControl id="workflow">
              <FormLabel>{t('common.workflow')}</FormLabel>
              <Controller
                name="workflow"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={workflows}
                    placeholder=""
                    getOptionLabel={workflow => workflow.name}
                    getOptionValue={workflow => workflow.id}
                    autoFocus
                    onChange={value => {
                      setValue('chart', null);
                      field.onChange(value);
                    }}
                    useBasicStyles
                  />
                )}
              />
            </FormControl>
            <Button
              alignSelf="flex-end"
              type="submit"
              colorScheme="teal"
              isDisabled={!isValid}
              isLoading={isSubmitting}
            >
              {t('button.save')}
            </Button>
          </Stack>
        </form>
      ) : null}
    </LoadingWrapper>
  );
};

export default CollectionWorkflowForm;
