import { Box } from '@chakra-ui/react';
import { useUi } from 'providers/UiProvider';

const Scrollable = ({ children, hasHeader = false }) => {
  const { scrollRef } = useUi();
  return (
    <Box
      ref={scrollRef}
      overflowY="scroll"
      height={hasHeader ? 'calc(100vh - 70px)' : '100vh'}
    >
      {children}
    </Box>
  );
};

export default Scrollable;
