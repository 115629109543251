import { Box, Divider, Flex, Heading, Skeleton } from '@chakra-ui/react';

const LabeledBox = ({ label, right, children, asSkeleton = false }) => (
  <Box rounded="lg" borderWidth={1}>
    <Flex alignItems="center" justifyContent="space-between">
      <Heading fontSize="lg" padding={4}>
        {asSkeleton ? (
          <Skeleton rounded="2xl" height="20px" width="150px" my={1} mr={3} />
        ) : (
          label
        )}
      </Heading>
      {right}
    </Flex>
    <Divider />
    <Box padding={4}>{children}</Box>
  </Box>
);

export default LabeledBox;
