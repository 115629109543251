import { authentication } from '@microsoft/teams-js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Center,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useAuth } from 'providers/AuthProvider';
import api from 'utils/api';

const TeamsLogin = () => {
  const { authenticate } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOnClickSignIn = async () => {
    authentication.initialize();
    try {
      const authToken = await authentication.getAuthToken();
      const { data } = await api.post('/auth/teams', {
        token: authToken
      });
      authenticate(data.token);
      const slug = localStorage.getItem('slug');
      if (slug) {
        navigate(`/${slug}`);
      } else {
        navigate('/join');
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container my={16} maxW="container.xs">
      <Stack spacing={16}>
        <Center>
          <Image
            src={useColorModeValue('/hives_dark.png', '/hives_light.png')}
            height="40px"
          />
        </Center>
        <Stack spacing={8} padding={8} borderWidth={1} rounded="lg">
          <Heading textAlign="center" fontSize="2xl">
            {t('teams.sign_in_to_hives_with_microsoft_teams')}
          </Heading>
          <Button onClick={handleOnClickSignIn} colorScheme="blue">
            {t('button.continue')}
          </Button>
        </Stack>
        <Text textAlign="center">{t('teams.hives_pitch')}</Text>
        <Text fontSize="xs">{t('teams.active_hives_account')}</Text>
      </Stack>
    </Container>
  );
};

export default TeamsLogin;
