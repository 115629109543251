import { useParams } from 'react-router-dom';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';
import { getCollectionShareUrl } from './shareUtils';
import CopyLinkButton from './CopyLinkButton';
import { HStack, Stack, Text } from '@chakra-ui/react';

const ShareButtons = ({ collection }) => {
  const { slug } = useParams();
  const url = getCollectionShareUrl(slug, collection);
  const fakeButtonStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    _hover: { backgroundColor: 'gray.100' },
    _active: { backgroundColor: 'gray.200' },
    borderRadius: '5px',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem'
  };
  return (
    <Stack spacing={4}>
      {collection.share_social_media && (
        <>
          <LinkedinShareButton url={url}>
            <HStack spacing={2} {...fakeButtonStyle}>
              <LinkedinIcon borderRadius="100%" size={24} />
              <Text>LinkedIn</Text>
            </HStack>
          </LinkedinShareButton>
          <TwitterShareButton url={url}>
            <HStack spacing={2} {...fakeButtonStyle}>
              <TwitterIcon borderRadius="100%" size={24} />
              <Text>Twitter</Text>
            </HStack>
          </TwitterShareButton>
          <FacebookShareButton url={url}>
            <HStack spacing={2} {...fakeButtonStyle}>
              <FacebookIcon borderRadius="100%" size={24} />
              <Text>Facebook</Text>
            </HStack>
          </FacebookShareButton>
        </>
      )}
      <CopyLinkButton url={url} isRound />
    </Stack>
  );
};

export default ShareButtons;
