import { Button, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react';
import LabeledBox from 'components/LabeledBox';
import StepDistributionChart from 'components/StepDistributionChart';
import CollectionDrawer from 'features/collection/CollectionDrawer';
import { useTranslation } from 'react-i18next';

const WorkflowStats = ({ workflow }) => {
  const {
    isOpen: showCollectionDrawer,
    onOpen: openCollectionDrawer,
    onClose: closeCollectionDrwaer
  } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <LabeledBox label={workflow.name}>
        <Stack spacing={4}>
          <Flex>
            {workflow.metrics.collections > 0 ? (
              <Button
                variant="link"
                textTransform="lowercase"
                onClick={openCollectionDrawer}
              >{`${workflow.metrics.collections} ${t(
                'common.collections'
              )}`}</Button>
            ) : (
              <Text variant="muted">{t('dashboard.no_collections')}</Text>
            )}
          </Flex>
          {workflow.steps.some(step => step.count > 0) && (
            <StepDistributionChart steps={workflow.steps} workflow={workflow} />
          )}
        </Stack>
      </LabeledBox>
      <CollectionDrawer
        header={t('common.collections')}
        isOpen={showCollectionDrawer}
        onClose={closeCollectionDrwaer}
        workflowId={workflow.id}
      />
    </>
  );
};

export default WorkflowStats;
