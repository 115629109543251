import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  HStack,
  Radio,
  Stack,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
  RadioGroup,
  useToast,
  useColorMode,
  FormControl,
  FormLabel,
  useDisclosure,
  Button
} from '@chakra-ui/react';
import AboutForm from './AboutForm';
import { getLanguageCode } from 'i18n';
import api from 'utils/api';
import SelectGroupForm from 'features/user/SelectGroupForm';
import ModalWrapper from 'components/ModalWrapper';

const SettingsPage = () => {
  const { colorMode, setColorMode } = useColorMode();

  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);

  const {
    isOpen: showSelectGroupModal,
    onOpen: openSelectGroupModal,
    onClose: closeSelectGroupModal
  } = useDisclosure();

  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation();

  const updateMeMutation = useMutation(
    payload => api.patch('/users/me', payload),
    {
      onSuccess: ({ data: me }) => {
        queryClient.setQueryData('me', me);
      },
      timeout: 180000
    }
  );

  const updateProfileMutation = useMutation(
    payload => api.patch(`/profiles/${profile.id}`, payload),
    {
      onSuccess: ({ data: profile }) => {
        queryClient.setQueryData(['profile', slug], profile);
      }
    }
  );

  const handleOnUpdateMe = async (form, avatarChanged) => {
    try {
      const payload = new FormData();
      payload.append('first_name', form.first_name);
      payload.append('last_name', form.last_name);
      if (avatarChanged) {
        payload.append('avatar', form.avatar ? form.avatar : '');
        payload.append('third_party_avatar', '');
      }
      await updateMeMutation.mutateAsync(payload);
      toast({
        title: t('toast.your_profile_was_successfully_updated'),
        status: 'success',
        position: 'bottom-right',
        isClosable: true
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnUpdateGroup = async ({ group }) => {
    try {
      await updateProfileMutation.mutateAsync({
        group: group?.id ? group.id : null
      });
      closeSelectGroupModal();
      toast({
        title: t('toast.your_profile_was_successfully_updated'),
        status: 'success',
        position: 'bottom-right',
        isClosable: true
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <Stack spacing={4}>
        <Heading fontSize="2xl">{t('common.settings')}</Heading>
        <Tabs size="sm" variant="fresh" isLazy>
          <TabList>
            <Tab>{t('common.about')}</Tab>
            <Tab>{t('common.appearance')}</Tab>
            <Tab>{t('common.language')}</Tab>
          </TabList>
          {me && (
            <TabPanels>
              <TabPanel>
                <Box padding={8} borderWidth="1px">
                  <Stack spacing={4}>
                    <Heading fontSize="lg">{t('common.about')}</Heading>
                    <AboutForm
                      defaultValues={{
                        avatar: me.avatar,
                        first_name: me.first_name,
                        last_name: me.last_name
                      }}
                      onSubmit={handleOnUpdateMe}
                    />
                    <FormControl id="first_name">
                      <FormLabel>{t('common.group')}</FormLabel>
                      <Button variant="link" onClick={openSelectGroupModal}>
                        {profile?.group
                          ? profile.group.name
                          : t('group.select_group')}
                      </Button>
                    </FormControl>
                  </Stack>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box padding={8} borderWidth="1px">
                  <Stack spacing={4}>
                    <Heading fontSize="lg">{t('common.appearance')}</Heading>
                    <RadioGroup
                      onChange={value => {
                        setColorMode(value);
                      }}
                      value={colorMode}
                    >
                      <HStack spacing={4}>
                        <Radio value="light" cursor="pointer">
                          {t('common.light')}
                        </Radio>
                        <Radio value="dark" cursor="pointer">
                          {t('common.dark')}
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </Stack>
                </Box>
              </TabPanel>
              <TabPanel>
                <Box padding={8} borderWidth="1px">
                  <Stack spacing={4}>
                    <Heading fontSize="lg">{t('common.language')}</Heading>
                    <RadioGroup
                      defaultValue={getLanguageCode()}
                      onChange={value => {
                        i18n.changeLanguage(value);
                      }}
                    >
                      <HStack spacing={4}>
                        <Radio value="en" cursor="pointer">
                          English
                        </Radio>
                        <Radio value="sv" cursor="pointer">
                          Svenska
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </Stack>
                </Box>
              </TabPanel>
            </TabPanels>
          )}
        </Tabs>
      </Stack>
      <ModalWrapper
        title={t('group.select_group')}
        isOpen={showSelectGroupModal}
        onClose={closeSelectGroupModal}
      >
        <SelectGroupForm
          defaultValues={{ group: profile?.group }}
          onSubmit={handleOnUpdateGroup}
        />
      </ModalWrapper>
    </Container>
  );
};

export default SettingsPage;
