import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';
import { AsyncSelect, Select } from 'chakra-react-select';
import ImageSelect from 'components/ImageSelect';
import MemberOptionLabel from 'features/member/MemberOptionLabel';
import api from 'utils/api';
import DescriptionFormControl from './DescriptionFormControl';
import TitleInput from './TitleInput';

const fetchDomains = async (query, slug) => {
  const { data } = await api.get(`/domains?search=${query}&hive__slug=${slug}`);
  return data;
};

const fetchGroups = async (query, slug) => {
  const { data } = await api.get(`/groups?search=${query}&hive__slug=${slug}`);
  return data;
};

const fetchUsers = async (query, slug) => {
  const { data } = await api.get(
    `/users?search=${query}&profiles__hive__slug=${slug}`
  );
  return data;
};

const accessOptions = [
  { id: 'TEAM', access: i18next.t('common.all_members_of_this_hive') },
  { id: 'DOMAINS', access: i18next.t('common.specific_domains') },
  { id: 'GROUPS', access: i18next.t('common.specific_groups') },
  { id: 'MEMBERS', access: i18next.t('common.specific_members') }
];

const AccessForm = ({
  defaultValues,
  isOpen,
  onSubmit,
  showImageSelect = false
}) => {
  const { slug } = useParams();
  const [defaultDomainOptions, setDefaultDomainOptions] = useState([]);
  const [defaultGroupOptions, setDefaultGroupOptions] = useState([]);
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    let access = accessOptions.find(
      option => option.id === defaultValues?.access
    );
    reset({
      ...defaultValues,
      access: access ? access : accessOptions[0]
    });
  }, [defaultValues, isOpen, reset]);

  const access = watch('access');

  const values = watch();

  useEffect(() => {
    async function fetchData() {
      const { data: domains } = await api.get(`/domains?hive__slug=${slug}`);
      setDefaultDomainOptions(domains);
      const { data: groups } = await api.get(`/groups?hive__slug=${slug}`);
      setDefaultGroupOptions(groups);
    }
    fetchData();
  }, [slug]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        {showImageSelect && (
          <ImageSelect
            object={values}
            setValue={setValue}
            preloadImage={!defaultValues}
          />
        )}
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <TitleInput control={control} />
        </FormControl>
        <DescriptionFormControl
          control={control}
          setValue={text => setValue('description', text)}
        />
        <FormControl id="access">
          <FormLabel>{t('common.access')}</FormLabel>
          <Controller
            name="access"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder={t('common.select')}
                options={accessOptions}
                getOptionLabel={access => access.access}
                getOptionValue={access => access.id}
                useBasicStyles
              />
            )}
          />
        </FormControl>
        <FormControl id="domains" hidden={access?.id !== 'DOMAINS'}>
          <FormLabel>{t('common.domains')}</FormLabel>
          <Controller
            name="domains"
            control={control}
            rules={{ required: access?.id === 'DOMAINS' }}
            render={({ field }) => (
              <AsyncSelect
                {...field}
                isMulti
                defaultOptions={defaultDomainOptions}
                loadOptions={query => fetchDomains(query, slug)}
                placeholder={t('placeholder.search')}
                getOptionLabel={domain => domain.domain}
                getOptionValue={domain => domain.id}
                useBasicStyles
              />
            )}
          />
        </FormControl>
        <FormControl id="groups" hidden={access?.id !== 'GROUPS'}>
          <FormLabel>{t('common.groups')}</FormLabel>
          <Controller
            name="groups"
            control={control}
            rules={{ required: access?.id === 'GROUPS' }}
            render={({ field }) => (
              <AsyncSelect
                {...field}
                isMulti
                defaultOptions={defaultGroupOptions}
                loadOptions={query => fetchGroups(query, slug)}
                placeholder={t('placeholder.search')}
                getOptionLabel={group => group.name}
                getOptionValue={group => group.id}
                useBasicStyles
              />
            )}
          />
        </FormControl>
        <FormControl id="members" hidden={access?.id !== 'MEMBERS'}>
          <FormLabel>{t('common.members')}</FormLabel>
          <Controller
            name="members"
            control={control}
            rules={{ required: access?.id === 'MEMBERS' }}
            render={({ field }) => (
              <AsyncSelect
                {...field}
                isMulti
                loadOptions={query => fetchUsers(query, slug)}
                placeholder={t('placeholder.search')}
                getOptionLabel={member => <MemberOptionLabel member={member} />}
                getOptionValue={member => member.id}
                useBasicStyles
              />
            )}
          />
        </FormControl>
        {showImageSelect && (
          <>
            <Input {...register('image')} hidden />
            <Input {...register('unsplash')} hidden />
          </>
        )}
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default AccessForm;
