import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Flex, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import IconTooltip from 'components/tooltips/IconTooltip';

const HiveAccessForm = ({ defaultValues, onSubmit }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({ mode: 'onChange', defaultValues });

  const { t } = useTranslation();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <Stack spacing={4}>
          <Flex alignItems="center" justifyContent="space-between">
            <HStack alignItems="center">
              <Text>{t('button.magic_link')}</Text>
              <IconTooltip
                label={t('button.magic_link_tooltip')}
                alignSelf="center"
                color="gray"
              />
            </HStack>
            <Switch {...register('magic_link')} />
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{t('button.continue_with_google')}</Text>
            <Switch {...register('continue_with_google')} />
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{t('button.continue_with_microsoft')}</Text>
            <Switch {...register('continue_with_microsoft')} />
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{t('button.continue_with_slack')}</Text>
            <Switch {...register('continue_with_slack')} />
          </Flex>
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{t('button.continue_with_linkedin')}</Text>
            <Switch {...register('continue_with_linkedin')} />
          </Flex>
          {/* <Flex alignItems="center" justifyContent="space-between">
            <Text>{t('button.continue_with_facebook')}</Text>
            <Switch {...register('continue_with_facebook')} />
          </Flex> */}
        </Stack>
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isDisabled={!isDirty || !isValid}
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default HiveAccessForm;
