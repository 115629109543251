import { Center, HStack, Text } from '@chakra-ui/react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewItem = ({ text, onClick }) => (
  <Center
    userSelect="none"
    cursor="pointer"
    minHeight="120px"
    rounded="lg"
    borderStyle="dashed"
    borderWidth={2}
    padding={6}
    onClick={onClick}
  >
    {text ? (
      <HStack>
        <FontAwesomeIcon icon={faPlus} />
        <Text>{text}</Text>
      </HStack>
    ) : (
      <FontAwesomeIcon icon={faPlus} />
    )}
  </Center>
);

export default NewItem;
