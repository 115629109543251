import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Divider,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text
} from '@chakra-ui/react';
import HiveAccessForm from './HiveAccessForm';
import BoxContainer from 'components/BoxContainer';
import LoadingWrapper from 'components/LoadingWrapper';
import DomainList from 'features/domain/DomainList';
import api from 'utils/api';
import SpinnerSwitch from 'components/SpinnerSwitch';
import IconTooltip from 'components/tooltips/IconTooltip';
import ConsentForm from './ConsentForm';

const AdminHive = () => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    status,
    data: hive,
    isSuccess
  } = useQuery(['hive', slug], async () => {
    const { data } = await api.get(`/hives/${slug}`);
    return data;
  });

  const updateHiveMutation = useMutation(
    payload => api.patch(`/hives/${slug}`, payload),
    {
      onSuccess: ({ data: hive }) => {
        queryClient.setQueryData(['hive', slug], hive);
      }
    }
  );

  const handleOnUpdateHive = async payload => {
    await updateHiveMutation.mutateAsync(payload);
  };

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.hive').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        <Tabs size="sm" variant="fresh" isLazy>
          <TabList>
            {/* <Tab>{t('common.branding')}</Tab> */}
            <Tab>{t('common.access')}</Tab>
          </TabList>
          <TabPanels>
            {/* <TabPanel></TabPanel> */}
            <TabPanel>
              <SimpleGrid columns={[1, null, 2]} spacing={[4, null, 8]}>
                <BoxContainer alignSelf="flex-start">
                  <Stack spacing={8}>
                    <Stack spacing={4}>
                      <Heading fontSize="lg">{t('common.settings')}</Heading>
                      <Flex alignItems="center" justifyContent="space-between">
                        <HStack>
                          <Text>{t('admin.force_group_selection')}</Text>
                          <IconTooltip
                            label={t('admin.force_group_selection_tooltip')}
                            alignSelf="center"
                            color="gray"
                          />
                        </HStack>
                        <SpinnerSwitch
                          isChecked={hive.force_group_selection}
                          optionKey="force_group_selection"
                          onChange={async () => {
                            await handleOnUpdateHive({
                              force_group_selection: !hive.force_group_selection
                            });
                          }}
                        />
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{t('admin.use_share')}</Text>
                        <SpinnerSwitch
                          isChecked={hive?.use_share}
                          optionKey="use_share"
                          onChange={async () => {
                            await handleOnUpdateHive({
                              use_share: !hive?.use_share
                            });
                          }}
                        />
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{t('admin.use_feed')}</Text>
                        <SpinnerSwitch
                          isChecked={hive.use_feed}
                          optionKey="use_feed"
                          onChange={async () => {
                            await handleOnUpdateHive({
                              use_feed: !hive.use_feed
                            });
                          }}
                        />
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{t('admin.use_downvotes')}</Text>
                        <SpinnerSwitch
                          isChecked={hive.use_downvotes}
                          optionKey="use_downvotes"
                          onChange={async () => {
                            await handleOnUpdateHive({
                              use_downvotes: !hive.use_downvotes
                            });
                          }}
                        />
                      </Flex>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{t('admin.language_filter')}</Text>
                        <SpinnerSwitch
                          isChecked={hive.sanitize_text}
                          optionKey="sanitize_text"
                          onChange={async () => {
                            await handleOnUpdateHive({
                              sanitize_text: !hive.sanitize_text
                            });
                          }}
                        />
                      </Flex>
                    </Stack>
                    <Divider />
                    <Stack spacing={4}>
                      <Heading fontSize="lg">
                        {t('common.sign_in_options')}
                      </Heading>
                      <HiveAccessForm
                        defaultValues={{
                          magic_link: hive.magic_link,
                          continue_with_facebook: hive.continue_with_facebook,
                          continue_with_google: hive.continue_with_google,
                          continue_with_linkedin: hive.continue_with_linkedin,
                          continue_with_microsoft: hive.continue_with_microsoft,
                          continue_with_slack: hive.continue_with_slack
                        }}
                        onSubmit={handleOnUpdateHive}
                      />
                    </Stack>
                  </Stack>
                </BoxContainer>
                <BoxContainer alignSelf="flex-start">
                  <Stack spacing={8}>
                    <Stack spacing={4}>
                      <Heading fontSize="lg">{t('common.open_hive')}</Heading>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{t('invite.anyone_can_join_this_hive')}</Text>
                        <SpinnerSwitch
                          isChecked={hive.open}
                          optionKey="open"
                          onChange={async () => {
                            await handleOnUpdateHive({ open: !hive.open });
                          }}
                        />
                      </Flex>
                    </Stack>
                    <Divider />
                    <Stack spacing={4}>
                      <Heading fontSize="lg">
                        {t('common.allowed_domains')}
                      </Heading>
                      <DomainList />
                    </Stack>
                  </Stack>
                </BoxContainer>

                <BoxContainer alignSelf="flex-start">
                  <Stack spacing={4}>
                    <Stack spacing={4}>
                      <Heading fontSize="lg">{t('common.consent')}</Heading>
                      <Flex alignItems="center" justifyContent="space-between">
                        <Text>{t('admin.require_consent')}</Text>
                        <SpinnerSwitch
                          isChecked={hive.require_consent}
                          optionKey="require_consent"
                          onChange={async () => {
                            await handleOnUpdateHive({
                              require_consent: !hive.require_consent
                            });
                          }}
                        />
                      </Flex>
                    </Stack>
                    <ConsentForm
                      defaultValues={{ consent_text: hive.consent_text }}
                      onSubmit={handleOnUpdateHive}
                    />
                  </Stack>
                </BoxContainer>
              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      ) : null}
    </LoadingWrapper>
  );
};

export default AdminHive;
