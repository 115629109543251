import {
  Button,
  Flex,
  Heading,
  HStack,
  Progress,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import ProgressDots from './ProgressDots';

const StepModalHeader = ({
  currentTitle,
  currentStepIndex,
  setCurrentStepIndex,
  lastStepIndex,
  onClose = {},
  showBackButton = true,
  useProgressBar = false
}) => {
  return (
    <Stack spacing={4} margin={0} padding={0}>
      <Flex alignItems="flex-start" justifyContent="space-between">
        <HStack spacing={4}>
          {showBackButton && (
            <Button
              variant="outline"
              onClick={() => {
                currentStepIndex > 0
                  ? setCurrentStepIndex(currentStepIndex - 1)
                  : onClose();
              }}
            >
              <HStack>
                <FontAwesomeIcon icon={faChevronLeft} />
              </HStack>
            </Button>
          )}
          <Heading
            alignSelf="center"
            noOfLines={3}
            mr={8}
            fontSize={['lg', 'xl', 'xl']}
          >
            {currentTitle}
          </Heading>
        </HStack>
        {!useProgressBar && (
          <ProgressDots
            steps={lastStepIndex + 1}
            currentStepIndex={currentStepIndex}
            pt={4}
          />
        )}
      </Flex>
      {useProgressBar && (
        <Progress
          colorScheme="teal"
          size="sm"
          value={(currentStepIndex / lastStepIndex) * 100}
        />
      )}
    </Stack>
  );
};

export default StepModalHeader;
