import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Heading,
  HStack,
  Progress,
  Radio,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';
import api from 'utils/api';

const SurveyFields = ({
  code,
  fieldIndex,
  survey,
  surveyResponse,
  sessionId,
  onDone,
  onNext
}) => {
  const { t } = useTranslation();

  const currentField = survey.fields[fieldIndex];

  const queryClient = useQueryClient();

  const createAnswerMutation = useMutation(
    payload =>
      api.post(
        sessionId
          ? `/surveys/responses/${surveyResponse.id}/answer?session_id=${sessionId}`
          : `/surveys/responses/${surveyResponse.id}/answer`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['survey', 'response', code]);
      },
      onError: () => {
        console.log('Error creating answer');
      }
    }
  );

  const createSelectionMutation = useMutation(
    payload =>
      api.post(
        sessionId
          ? `/surveys/responses/${surveyResponse.id}/selection?session_id=${sessionId}`
          : `/surveys/responses/${surveyResponse.id}/selection`,
        payload
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['survey', 'response', code]);
      },
      onError: () => {
        console.log('Error creating selection');
      }
    }
  );

  const goToNextField = () => {
    if (fieldIndex === survey.fields.length - 1) {
      onDone();
    } else {
      onNext();
    }
  };

  const onSubmitAnswer = async form => {
    const field = survey.fields[fieldIndex];
    await createAnswerMutation.mutateAsync({
      survey: survey.id,
      field: field.id,
      answer: form.answer
    });
    goToNextField();
  };

  const onSubmitMultiSelection = async form => {
    const field = survey.fields[fieldIndex];
    const entries = Object.entries(form);
    let options = [];
    for (let [key, value] of entries) {
      if (Boolean(value)) {
        options.push(parseInt(key));
      }
    }
    await createSelectionMutation.mutateAsync({
      field: field.id,
      options
    });
    goToNextField();
  };

  const onSubmitSingleSelection = async form => {
    const field = survey.fields[fieldIndex];
    const selectedOption = Object.values(form)[0];
    const options = selectedOption ? [parseInt(selectedOption)] : [];
    await createSelectionMutation.mutateAsync({
      field: field.id,
      options
    });
    goToNextField();
  };

  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    if (currentField) {
      reset();
      if (currentField.type === 'INPUT') {
        const answer = surveyResponse.answers.find(
          answer => answer.field === currentField.id
        );
        if (answer) {
          setValue('answer', answer.answer);
        } else {
          setValue('answer', '');
        }
      } else if (currentField.type === 'MULTI_SELECT') {
        const selection = surveyResponse.multi_selections.find(
          selection => selection.field === currentField.id
        );

        if (selection) {
          selection.options.forEach(option => {
            setValue(`${option.id}`, true);
          });
        }
      } else if (currentField.type === 'SINGLE_SELECT') {
        const selection = surveyResponse.single_selections.find(
          selection => selection.field === currentField.id
        );
        if (selection) {
          setValue('option', selection.option.id);
        }
      }
    }
  }, [currentField, setValue, reset, surveyResponse]);

  return (
    <Stack spacing={4}>
      <Flex position="fixed" left={0} top={0} width="full">
        <Progress
          value={fieldIndex + 1}
          max={survey.fields.length > 0 ? survey.fields.length : 1}
          width="full"
          height="4px"
          colorScheme="teal"
        />
      </Flex>
      <HStack>
        <Heading fontSize="xl">{`${fieldIndex + 1}. ${
          currentField?.field
        }`}</Heading>
      </HStack>
      {currentField && (
        <Stack spacing={8}>
          {currentField.type === 'INPUT' && (
            <form id="survey-form" onSubmit={handleSubmit(onSubmitAnswer)}>
              <FormControl>
                <Textarea {...register('answer')} />
              </FormControl>
            </form>
          )}
          {currentField.type === 'MULTI_SELECT' && (
            <form
              id="survey-form"
              onSubmit={handleSubmit(onSubmitMultiSelection)}
            >
              <FormControl>
                <Stack>
                  {currentField.options.map(option => (
                    <Controller
                      key={option.id}
                      control={control}
                      name={`${option.id}`}
                      render={({ field: { onChange, value } }) => (
                        <Box
                          borderWidth={1}
                          borderRadius="lg"
                          cursor="pointer"
                          padding={4}
                          onClick={() => {
                            onChange(!value);
                          }}
                        >
                          <HStack spacing={4}>
                            <Checkbox isChecked={value} pointerEvents="none" />
                            <Text>{option.option}</Text>
                          </HStack>
                        </Box>
                      )}
                    />
                  ))}
                </Stack>
              </FormControl>
            </form>
          )}
          {currentField.type === 'SINGLE_SELECT' && (
            <form
              id="survey-form"
              onSubmit={handleSubmit(onSubmitSingleSelection)}
            >
              <FormControl>
                <Stack>
                  {currentField.options.map(option => (
                    <Controller
                      key={option.id}
                      control={control}
                      name="option"
                      render={({ field: { onChange, value } }) => (
                        <Box
                          borderWidth={1}
                          borderRadius="lg"
                          cursor="pointer"
                          padding={4}
                          onClick={() => onChange(option.id)}
                        >
                          <HStack spacing={4}>
                            <Radio
                              isChecked={value === option.id}
                              pointerEvents="none"
                            />
                            <Text>{option.option}</Text>
                          </HStack>
                        </Box>
                      )}
                    />
                  ))}
                </Stack>
              </FormControl>
            </form>
          )}
        </Stack>
      )}
      <Flex alignItems="center" justifyContent="flex-end">
        <Button
          form="survey-form"
          type="submit"
          colorScheme="teal"
          isLoading={isSubmitting}
        >
          {fieldIndex === survey.fields.length - 1
            ? t('common.done')
            : t('common.next')}
        </Button>
      </Flex>
    </Stack>
  );
};

export default SurveyFields;
