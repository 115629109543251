import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { HStack, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import LabeledBox from 'components/LabeledBox';
import api from 'utils/api';
import LeaderboardListItem from './LeaderboardListItem';

const LeaderboardList = ({ query, title, collectionId = null }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const {
    status,
    data: contributors,
    isSuccess
  } = useQuery([query, slug], async () => {
    const params = new URLSearchParams({
      slug: slug
    });
    if (collectionId) {
      params.append('collection', collectionId);
    }
    const { data } = await api.get(`/metrics/${query}?${params.toString()}`);
    return data;
  });

  return (
    <LoadingWrapper
      indicator={
        <LoadingIndicator>
          <LabeledBox label={title} asSkeleton>
            <Stack py={2} spacing={4}>
              {[...Array(6)].map((_, index) => (
                <HStack key={index} justifyContent="space-between">
                  <HStack spacing={4}>
                    <SkeletonCircle />
                    <HStack>
                      <Skeleton rounded="md" height="25px" width="100px" />
                      <Skeleton rounded="md" height="25px" width="140px" />
                    </HStack>
                  </HStack>
                  <Skeleton rounded="md" height="25px" width="50px" />
                </HStack>
              ))}
            </Stack>
          </LabeledBox>
        </LoadingIndicator>
      }
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.contributors').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        contributors.length > 0 ? (
          <LabeledBox label={title}>
            <Stack spacing={4}>
              {contributors.map(contributor => (
                <LeaderboardListItem
                  key={contributor.id}
                  contributor={contributor}
                />
              ))}
            </Stack>
          </LabeledBox>
        ) : null
      ) : null}
    </LoadingWrapper>
  );
};

export default LeaderboardList;
