import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  SimpleGrid
} from '@chakra-ui/react';
import CharacterCounterInput from 'components/CharacterCounterInput';

const ParameterForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid, isDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  const min = watch('min', '');

  const validateMax = max => {
    const minInt = parseInt(min);
    const maxInt = parseInt(max);
    if (Number.isInteger(minInt) && Number.isInteger(maxInt)) {
      return minInt >= maxInt ? t('chart.must_be_larger_than_min') : null;
    }
    return null;
  };

  const descriptionMaxLength = 200;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="label">
          <FormLabel>{t('common.label')}</FormLabel>
          <Input
            maxLength={80}
            {...register('label', { required: true })}
            autoFocus
          />
        </FormControl>
        <SimpleGrid columns={[1, 2]} spacing={4}>
          <FormControl id="min">
            <FormLabel>{t('chart.min_value')}</FormLabel>
            <Input
              type="number"
              {...register('min', { required: 'required' })}
            />
          </FormControl>
          <FormControl id="max" isInvalid={errors?.max}>
            <FormLabel>{t('chart.max_value')}</FormLabel>
            <Input
              type="number"
              {...register('max', {
                required: true,
                validate: validateMax
              })}
            />
            <FormErrorMessage>{errors?.max?.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <FormControl id="description">
          <FormLabel>{t('common.description')}</FormLabel>
          <CharacterCounterInput
            isTextArea
            useControllerProps={{
              name: 'description',
              control: control,
              rules: {
                required: false,
                maxLength: {
                  value: descriptionMaxLength,
                  message: t('common.character_limit_error', {
                    limit: descriptionMaxLength
                  })
                }
              }
            }}
            defaultValue={defaultValues?.description}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isLoading={isSubmitting}
            isDisabled={!isValid || !isDirty}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default ParameterForm;
