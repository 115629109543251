import { Badge, HStack, Stack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getCollectingColor,
  getCollectingIcon,
  getCollectingPlural
} from 'features/collection/collectionUtils';
import { useTranslation } from 'react-i18next';

const WorkflowListItem = ({ workflow, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Stack spacing={4} alignItems="flex-start" {...rest}>
      <Text variant="muted">
        {t('collection.collecting_type', {
          type: getCollectingPlural(workflow.type)
        })}
      </Text>
      <HStack spacing={4}>
        <Badge
          display="flex"
          alignItems="center"
          justifyContent="center"
          minW={10}
          minH={10}
          rounded="md"
          colorScheme={getCollectingColor(workflow.type)}
        >
          <Text fontSize="lg">
            <FontAwesomeIcon icon={getCollectingIcon(workflow.type)} />
          </Text>
        </Badge>
        <Text fontWeight="bold" wordBreak="break-word">
          {workflow.name}
        </Text>
      </HStack>
    </Stack>
  );
};

export default WorkflowListItem;
