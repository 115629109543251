import { scaleOrdinal } from 'd3-scale';
import Wordcloud from 'react-d3-cloud';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Box, useColorModeValue, useTheme } from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import { useParams } from 'react-router-dom';

const CollectionWordcloud = ({
  collectionId,
  step,
  emptyState,
  onWordClick = null
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { slug } = useParams();

  const shade = useColorModeValue('500', '200');

  const schemeCategory10ScaleOrdinal = scaleOrdinal([
    theme.colors['blue'][shade],
    theme.colors['orange'][shade],
    theme.colors['green'][shade],
    theme.colors['red'][shade],
    theme.colors['purple'][shade],
    theme.colors['pink'][shade]
  ]);

  const {
    status,
    data: wordcloud,
    isSuccess
  } = useQuery(['wordcloud', collectionId, step], async () => {
    const params = new URLSearchParams();
    if (step) {
      params.append(
        step === -1 ? 'card_step__isnull' : 'card_step',
        step === -1 ? true : step
      );
    }
    params.append('hive__slug', slug);
    const { data } = await api.get(
      `/collections/${collectionId}/wordcloud?${params.toString()}`
    );
    return data;
  });

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.wordcloud').toLowerCase()
        })
      ]}
    >
      {isSuccess ? (
        wordcloud?.length > 0 ? (
          <Box className="wordcloud">
            <Wordcloud
              data={wordcloud}
              width={300}
              height={200}
              font="Poppins"
              fontSize={word => Math.log2(word.value * 2) * 8}
              spiral="rectangular"
              rotate={0}
              padding={2}
              random={Math.random}
              fill={(_, i) => {
                const value = schemeCategory10ScaleOrdinal(i);
                return value;
              }}
              onWordClick={onWordClick}
            />
          </Box>
        ) : (
          emptyState
        )
      ) : null}
    </LoadingWrapper>
  );
};

export default CollectionWordcloud;
