import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, HStack, Stack, Text } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import MemberAvatar from 'components/MemberAvatar';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';
import MemberOptionLabel from 'features/member/MemberOptionLabel';

const fetchUsers = async query => {
  const { data } = await api.get(`/users?search=${query}`);
  return data;
};

const OwnerModal = ({ canEdit, owner, isOpen, onClose, onChangeOwner }) => {
  const { slug } = useParams();
  const [selectedOwner, setSelectedOwner] = useState(owner);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedOwner(owner);
  }, [owner]);

  return (
    <ModalWrapper
      title={t('common.owner')}
      isOpen={isOpen}
      onClose={onClose}
      pb={6}
    >
      <Stack spacing={8}>
        <HStack
          key={owner.id}
          spacing={4}
          as={Link}
          to={`/${slug}/member/${owner.id}`}
        >
          <MemberAvatar member={owner} size="sm" />
          <Text whiteSpace="break-spaces">{owner.full_name}</Text>
        </HStack>
        {canEdit && (
          <FormControl id="owner">
            <FormLabel>{t('button.change')}</FormLabel>
            <AsyncSelect
              value={selectedOwner}
              loadOptions={fetchUsers}
              placeholder={t('placeholder.search')}
              getOptionLabel={member => <MemberOptionLabel member={member} />}
              getOptionValue={member => member.id}
              onChange={onChangeOwner}
              useBasicStyles
            />
          </FormControl>
        )}
      </Stack>
    </ModalWrapper>
  );
};

export default OwnerModal;
