import { useTranslation } from 'react-i18next';
import {
  Badge,
  Button,
  Center,
  Heading,
  HStack,
  Stack
} from '@chakra-ui/react';
import AccordionBase from 'components/AccordionBase';
import HeadingButton from 'components/HeadingButton';
import CollectionList from 'features/collection/CollectionList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const ProjectStep = ({
  step,
  stepNumber,
  projectId,
  noOfCollections,
  onClickCreateCollection
}) => {
  const { t } = useTranslation();
  return (
    <AccordionBase
      left={
        <HStack spacing={4}>
          <Center minWidth={8} maxWidth={8}>
            <Heading fontSize="lg">{stepNumber}</Heading>
          </Center>
          <HStack width="max">
            <Heading fontSize="lg" textAlign="left" pr={8}>
              {step?.title}
            </Heading>
          </HStack>
          <Badge minWidth="fit-content">{`${noOfCollections} ${t(
            'common.collections'
          )}`}</Badge>
        </HStack>
      }
    >
      <Stack spacing={8}>
        <HeadingButton
          title={t('common.collections')}
          button={
            <Button
              colorScheme="teal"
              onClick={() => onClickCreateCollection(step)}
            >
              <HStack>
                <FontAwesomeIcon icon={faPlus} />
              </HStack>
            </Button>
          }
          fontSize="lg"
        />
        <CollectionList projectId={projectId} stepId={step?.id} />
      </Stack>
    </AccordionBase>
  );
};

export default ProjectStep;
