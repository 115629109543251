import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/react';

const BoxContainer = ({
  children,
  to,
  innerRef,
  borderColor = null,
  onClick,
  ...rest
}) => (
  <Box
    ref={innerRef}
    as={to ? Link : 'div'}
    to={to}
    padding={[4, 5, 5]}
    rounded="lg"
    borderColor={borderColor}
    borderWidth={1}
    cursor={onClick || to ? 'pointer' : 'initial'}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Box>
);

export default BoxContainer;
