import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Box, Hide, useMediaQuery } from '@chakra-ui/react';
import CreateButton from 'components/CreateButton';
import Scrollable from 'components/Scrollable';
import ConsentModal from 'features/hive/ConsentModal';
import NameModal from 'features/member/NameModal';
import GroupModal from 'features/user/GroupModal';
import { useAuth } from 'providers/AuthProvider';
import { CardProvider } from 'providers/CardProvider';
import api from 'utils/api';
import Header from 'features/navigation/Header';

let timer = null;

const ProfileRoute = ({
  children,
  showChildOnly = false,
  escapeContainerScroll = false
}) => {
  const { authenticated } = useAuth();
  const { boot, shutdown, update } = useIntercom();
  const navigate = useNavigate();
  const { slug } = useParams();

  const queryClient = useQueryClient();

  const [isMobile] = useMediaQuery('(max-width: 62em)');

  const { data: profile, isSuccess } = useQuery(
    ['profile', slug],
    async () => {
      const { data } = await api.get(`/profiles/me/${slug}`);
      return data;
    },
    {
      onError: async () => {
        try {
          await api.post('/auth/join', { slug });
          window.location.reload();
        } catch (e) {
          localStorage.removeItem('slug');
          navigate(`/${slug}/login`);
        }
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 24 * 60 * 60 * 1000
    }
  );

  queryClient.setQueryDefaults(['profile', slug], {
    queryFn: async () => {
      const { data } = await api.get(`/profiles/me/${slug}`);
      return data;
    }
  });

  useEffect(() => {
    return () => {
      shutdown();
    };
  }, [shutdown]);

  useEffect(() => {
    if (profile && slug) {
      boot({
        userId: profile.user.id,
        name: profile.user.full_name,
        email: profile.user.email,
        customAttributes: {
          role: profile.role,
          team_creator: profile.is_hive_creator
        }
      });
    }
  }, [boot, profile, slug]);

  useEffect(() => {
    // Temporary solution
    clearTimeout(timer);
    timer = setTimeout(() => {
      update({
        customAttributes: {
          is_mobile: isMobile
        }
      });
    }, 1000);
  }, [isMobile, update]);

  return authenticated && isSuccess ? (
    <CardProvider>
      {showChildOnly ? (
        escapeContainerScroll ? (
          <Box>{children}</Box>
        ) : (
          <Scrollable>{children}</Scrollable>
        )
      ) : (
        <>
          <Header />
          <Hide above="lg">
            <CreateButton />
          </Hide>
          {escapeContainerScroll ? (
            <Box>{children}</Box>
          ) : (
            <Scrollable hasHeader>{children}</Scrollable>
          )}
          {profile.requires_consent ? (
            <ConsentModal />
          ) : (
            <>
              <NameModal />
              <GroupModal />
            </>
          )}
        </>
      )}
    </CardProvider>
  ) : null;
};

export default ProfileRoute;
