import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import CharacterCounterInput from 'components/CharacterCounterInput';

const FolderForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  const folderNameMaxLength = 50;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="name">
          <FormLabel>{t('common.name')}</FormLabel>
          <CharacterCounterInput
            autoComplete="off"
            autoFocus
            useControllerProps={{
              name: 'name',
              control: control,
              rules: {
                required: true,
                maxLength: {
                  value: folderNameMaxLength,
                  message: t('common.character_limit_error', {
                    limit: folderNameMaxLength
                  })
                }
              }
            }}
            defaultValue={defaultValues?.name}
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default FolderForm;
