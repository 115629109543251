import { createContext, useContext, useRef, useCallback } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const ConfettiContext = createContext();

const confettiCanvasStyle = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 9999
};

const ConfettiProvider = ({ children }) => {
  const refConfettiInstance = useRef(null);
  const getConfettiInstance = useCallback(instance => {
    refConfettiInstance.current = instance;
  }, []);
  const makeShot = useCallback((particleRatio, opts) => {
    refConfettiInstance.current &&
      refConfettiInstance.current({
        ...opts,
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.5, {
      spread: 80,
      startVelocity: 50
    });
  }, [makeShot]);

  return (
    <ConfettiContext.Provider
      value={{
        fire
      }}
    >
      {children}
      <ReactCanvasConfetti
        refConfetti={getConfettiInstance}
        style={confettiCanvasStyle}
      />
    </ConfettiContext.Provider>
  );
};

const useConfetti = () => useContext(ConfettiContext);

export { ConfettiProvider, useConfetti };
