import { Circle, HStack, useColorMode } from '@chakra-ui/react';

const ProgressDots = ({ steps, currentStepIndex, ...rest }) => {
  const { colorMode } = useColorMode();
  const ProgressDot = ({ stepIndex }) => {
    return (
      <Circle
        bg={
          currentStepIndex >= stepIndex
            ? colorMode === 'light'
              ? 'teal.500'
              : 'teal.200'
            : colorMode === 'light'
            ? 'gray.300'
            : 'gray.600'
        }
        size={2}
      />
    );
  };

  return (
    <HStack {...rest}>
      {[...Array(steps)].map((_, index) => (
        <ProgressDot key={index} stepIndex={index} />
      ))}
    </HStack>
  );
};

export default ProgressDots;
