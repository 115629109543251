import { useTranslation } from 'react-i18next';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Divider,
  HStack,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionMenu, { EditMenuItem } from 'components/ActionMenu';
import ModalWrapper from 'components/ModalWrapper';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import FieldTable from './FieldTable';
import InboxStepForm from './InboxStepForm';
import FormTable from './FormTable';

const FormStepListItem = ({
  workflow,
  onSubmit,
  onUpdateWorkflow,
  onFieldChangeStep,
  isOpen
}) => {
  const { t } = useTranslation();
  const {
    isOpen: showUpdateStepModal,
    onOpen: openUpdateStepModal,
    onClose: closeUpdateStepModal
  } = useDisclosure();

  const handleOnSubmit = async values => {
    await onSubmit(values);
    closeUpdateStepModal();
  };

  return (
    <AccordionItem borderWidth={0} borderBottom="none">
      <HStack spacing={4} py={4}>
        <Text variant="light">
          <FontAwesomeIcon icon={faLock} />
        </Text>
        <AccordionButton
          px={0}
          _hover={{
            bg: null
          }}
        >
          <HStack spacing={4} width="full">
            <Badge>{t('common.form')}</Badge>
            <Divider />
          </HStack>
          <AccordionIcon ml={2} />
        </AccordionButton>
        <ActionMenu isDisabled>
          <EditMenuItem onClick={openUpdateStepModal} />
        </ActionMenu>
      </HStack>
      <AccordionPanel pb={8}>
        <Stack>
          <FormTable workflow={workflow} onUpdateWorkflow={onUpdateWorkflow} />
          <FieldTable
            step={null}
            workflow={workflow}
            form
            onFieldChangeStep={onFieldChangeStep}
            isOpen={isOpen}
          />
        </Stack>
      </AccordionPanel>
      <ModalWrapper
        title={t('common.step')}
        isOpen={showUpdateStepModal}
        onClose={closeUpdateStepModal}
      >
        <InboxStepForm
          defaultValues={{ hide_inbox_cards: workflow.hide_inbox_cards }}
          isOpen={showUpdateStepModal}
          onSubmit={handleOnSubmit}
        />
      </ModalWrapper>
    </AccordionItem>
  );
};

export default FormStepListItem;
