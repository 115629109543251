import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';

const AttachmentForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="name">
          <FormLabel>{t('common.name')}</FormLabel>
          <Input {...register('name')} defaultValue={defaultValues?.name} />
        </FormControl>
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default AttachmentForm;
