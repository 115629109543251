import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-day-picker';
import { Controller, useForm } from 'react-hook-form';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  HStack,
  Stack,
  Text
} from '@chakra-ui/react';
import { useUi } from 'providers/UiProvider';

const MultiSelectForm = ({ answers, options, helpText, onSubmit }) => {
  const { t } = useTranslation();
  const { setModalClosureLocked } = useUi();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty }
  } = useForm({
    mode: 'onChange'
  });

  const checkIfChecked = option => {
    if (answers?.options) {
      const result = answers.options.find(o => o.id === option.id);
      if (result) {
        return result.selected;
      }
    }
    return false;
  };

  useEffect(() => {
    setModalClosureLocked(isDirty);
  }, [setModalClosureLocked, isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl>
          <Stack spacing={4}>
            {options.map(option => (
              <Controller
                key={option.id}
                control={control}
                defaultValue={checkIfChecked(option)}
                name={`${option.id}`}
                render={({ field: { onChange, value } }) => (
                  <HStack>
                    <Checkbox defaultChecked={value} onChange={onChange} />
                    <Text>{option.option}</Text>
                  </HStack>
                )}
              />
            ))}
            <FormControl>
              {helpText && <FormHelperText>{helpText}</FormHelperText>}
            </FormControl>
          </Stack>
        </FormControl>
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default MultiSelectForm;
