import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQuery,
  useInfiniteQuery,
  useQueryClient,
  useMutation
} from 'react-query';
import { Link, useParams } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import {
  Badge,
  Box,
  Center,
  HStack,
  Table,
  Tbody,
  TableContainer,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  useDisclosure,
  Button,
  Tooltip
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/pro-regular-svg-icons';
import LoadingWrapper from 'components/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import FilterButton from 'components/FilterButton';
import MemberAvatar from 'components/MemberAvatar';
import ModalWrapper from 'components/ModalWrapper';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import RoleForm from 'features/member/RoleForm';
import SearchForm from 'features/search/SearchForm';
import { useUi } from 'providers/UiProvider';
import api from 'utils/api';
import SelectGroupForm from './SelectGroupForm';

const ProfileTable = ({ hasStarterOrBetter = false }) => {
  const { slug } = useParams();
  const { filter, setFilter } = useUi();
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const {
    isOpen: showGroupModal,
    onClose: closeGroupModal,
    onOpen: openGroupModal
  } = useDisclosure();

  const {
    isOpen: showRoleModal,
    onClose: closeRoleModal,
    onOpen: openRoleModal
  } = useDisclosure();

  const updateProfileMutation = useMutation(async payload =>
    api.patch(`/profiles/${selectedParticipant.id}`, payload)
  );

  const handleOnSubmitGroup = async ({ group }) => {
    try {
      await updateProfileMutation.mutateAsync({
        group: group ? group.id : null
      });
      fetchProfiles();
    } catch (e) {
      console.log(e);
    }
    closeGroupModal();
  };

  const handleOnSubmitRole = async ({ role }) => {
    try {
      await updateProfileMutation.mutateAsync({ role: role.id });
      fetchProfiles();
      fetchMetrics();
    } catch (e) {
      console.log(e);
    }
    closeRoleModal();
  };

  const handleOnChangeRoleFilter = role => {
    setFilter({ ...filter, role: role });
  };

  const handleOnSubmitSearchQuery = async ({ search }) => {
    setFilter({ ...filter, search: search.length > 0 ? search : null });
  };

  const { data: membersMetrics, refetch: fetchMetrics } = useQuery(
    'members-metrics',
    async () => {
      const { data } = await api.get(
        `/metrics/members?slug=${slug}${
          filter?.search ? `&search=${filter.search}` : ''
        }`
      );
      return data;
    }
  );

  const roleCounts = {
    all: membersMetrics?.metrics?.members,
    admins:
      membersMetrics?.metrics?.roles.find(role => role.role === 'ADMIN')
        ?.members || 0,
    managers:
      membersMetrics?.metrics?.roles.find(role => role.role === 'MANAGER')
        ?.members || 0,
    members:
      membersMetrics?.metrics?.roles.find(role => role.role === 'MEMBER')
        ?.members || 0,
    guests:
      membersMetrics?.metrics?.roles.find(role => role.role === 'GUEST')
        ?.members || 0
  };

  const {
    status,
    data: profiles,
    isSuccess,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch: fetchProfiles
  } = useInfiniteQuery(
    'profiles',
    async ({ pageParam = 0 }) => {
      const params = new URLSearchParams({
        offset: pageParam,
        limit: 20
      });
      params.append('hive__slug', slug);
      if (filter?.role) {
        params.append('role', filter.role);
      }
      if (filter?.group) {
        params.append('group', filter.group);
      }
      if (filter?.search) {
        params.append('search', filter.search);
      }
      const { data } = await api.get(`/profiles?${params.toString()}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  useEffect(() => {
    if (Object.keys(filter).length < 1) {
      setFilter({
        ...filter,
        role: null
      });
    }
  }, [setFilter, filter]);

  useEffect(() => {
    queryClient.removeQueries('profiles');
    fetchProfiles();
    queryClient.removeQueries('metrics');
    fetchMetrics();
  }, [filter, fetchProfiles, queryClient, fetchMetrics]);

  const data = useMemo(() => {
    const temp = [];
    const handleOnClickGroup = profile => {
      setSelectedParticipant(profile);
      openGroupModal();
    };
    profiles?.pages?.forEach(page =>
      page?.results?.forEach(profile => {
        temp.push({
          profile: (
            <HStack
              key={profile.id}
              as={Link}
              to={`/${slug}/member/${profile.member_id}`}
              maxWidth="fit-content"
              spacing={4}
            >
              <MemberAvatar member={profile.user} size="sm" />
              <Text>{profile.user.full_name}</Text>
            </HStack>
          ),
          email: <Text>{profile.user.email}</Text>,
          role: profile?.role ? (
            <Badge
              cursor={hasStarterOrBetter && 'pointer'}
              onClick={
                hasStarterOrBetter
                  ? () => {
                      setSelectedParticipant(profile);
                      openRoleModal();
                    }
                  : null
              }
            >
              {t(`common.${profile.role.toLowerCase()}`)}
            </Badge>
          ) : (
            '-'
          ),
          group: profile?.group ? (
            <Badge
              cursor={hasStarterOrBetter && 'pointer'}
              onClick={
                hasStarterOrBetter ? () => handleOnClickGroup(profile) : null
              }
            >
              {profile.group.name}
            </Badge>
          ) : (
            <Button
              isDisabled={!hasStarterOrBetter}
              variant="link"
              size="sm"
              onClick={
                hasStarterOrBetter ? () => handleOnClickGroup(profile) : null
              }
            >
              {t('common.select')}
            </Button>
          )
        });
      })
    );
    return temp;
  }, [t, slug, profiles, openGroupModal, openRoleModal, hasStarterOrBetter]);

  const columns = useMemo(() => {
    return [
      {
        Header: t('common.name'),
        accessor: 'profile',
        sortType: 'string',
        textAlign: 'left'
      },
      {
        Header: t('common.email'),
        accessor: 'email',
        sortType: 'string',
        textAlign: 'left'
      },
      {
        Header: t('common.role'),
        accessor: 'role',
        sortType: 'string',
        textAlign: 'center',
        pl: 0
      },
      {
        Header: t('common.group'),
        accessor: 'group',
        sortType: 'string',
        textAlign: 'center',
        pl: 0
      }
    ];
  }, [t]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns }, useSortBy);

  const TooltipWrapper = ({ tooltipLabel, children }) => (
    <Tooltip hasArrow label={tooltipLabel}>
      <Box>{children}</Box>
    </Tooltip>
  );

  return (
    <Stack spacing={4}>
      <HStack>
        <FilterButton
          title={t('common.all')}
          selected={filter?.role === null}
          count={roleCounts.all}
          onClick={() => handleOnChangeRoleFilter(null)}
        />
        <TooltipWrapper tooltipLabel={t('admin.admins_tooltip')}>
          <FilterButton
            title={t('common.admins')}
            selected={filter?.role === 'ADMIN'}
            count={roleCounts.admins}
            onClick={() => handleOnChangeRoleFilter('ADMIN')}
          />
        </TooltipWrapper>
        <TooltipWrapper tooltipLabel={t('admin.managers_tooltip')}>
          <FilterButton
            title={t('common.managers')}
            selected={filter?.role === 'MANAGER'}
            count={roleCounts.managers}
            onClick={() => handleOnChangeRoleFilter('MANAGER')}
          />
        </TooltipWrapper>
        <TooltipWrapper tooltipLabel={t('admin.members_tooltip')}>
          <FilterButton
            title={t('common.members')}
            selected={filter?.role === 'MEMBER'}
            count={roleCounts.members}
            onClick={() => handleOnChangeRoleFilter('MEMBER')}
          />
        </TooltipWrapper>
        <TooltipWrapper tooltipLabel={t('admin.guests_tooltip')}>
          <FilterButton
            title={t('common.guests')}
            selected={filter?.role === 'GUEST'}
            count={roleCounts.guests}
            onClick={() => handleOnChangeRoleFilter('GUEST')}
          />
        </TooltipWrapper>
      </HStack>
      <Box maxWidth={['full', '50%', null]}>
        <SearchForm onSubmit={handleOnSubmitSearchQuery} />
      </Box>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.users').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <TableContainer>
            <InfiniteScrollHelper
              hasMore={!isFetching && hasNextPage}
              loadMore={fetchNextPage}
            >
              <Table {...getTableProps()} variant="simple">
                <Thead>
                  {headerGroups.map(headerGroup => (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          textAlign={column.textAlign}
                          whiteSpace="nowrap"
                          pl={column?.pl}
                          pr={column?.pr}
                          py={6}
                        >
                          <HStack display="inline-flex" spacing={2}>
                            <Box userSelect="none">
                              {column.render('Header')}
                            </Box>
                            {!column.disableSortBy && (
                              <Box as="span">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <FontAwesomeIcon
                                      icon={faSortDown}
                                      aria-label="sorted descending"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      icon={faSortUp}
                                      aria-label="sorted ascending"
                                    />
                                  )
                                ) : (
                                  <FontAwesomeIcon icon={faSort} />
                                )}
                              </Box>
                            )}
                          </HStack>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {rows.map(row => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <Td
                            {...cell.getCellProps()}
                            textAlign={cell.column.textAlign}
                            pl={cell.column?.pl}
                            pr={cell.column?.pr}
                            py={4}
                          >
                            {cell.render('Cell')}
                          </Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </InfiniteScrollHelper>
            {isFetching && <LoadingIndicator />}
            {isSuccess && data.length < 1 && (
              <Center py={12}>
                <Text variant="muted">{t('common.no_results_were_found')}</Text>
              </Center>
            )}
          </TableContainer>
        ) : null}
        <ModalWrapper
          title={t('common.group')}
          isOpen={showGroupModal}
          onClose={closeGroupModal}
        >
          <SelectGroupForm
            defaultValues={selectedParticipant}
            isOpen={showGroupModal}
            onSubmit={handleOnSubmitGroup}
          />
        </ModalWrapper>
        <ModalWrapper
          title={t('common.role')}
          isOpen={showRoleModal}
          onClose={closeRoleModal}
        >
          <RoleForm
            defaultValues={selectedParticipant}
            isOpen={showRoleModal}
            onSubmit={handleOnSubmitRole}
          />
        </ModalWrapper>
      </LoadingWrapper>
    </Stack>
  );
};

export default ProfileTable;
