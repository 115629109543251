import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Divider,
  HStack,
  Stack
} from '@chakra-ui/react';

const AccordionBase = ({ left, right, children, ...rest }) => (
  <AccordionItem borderWidth="1px" rounded="md" px={0} my={4} {...rest}>
    <HStack spacing={4}>
      <AccordionButton
        rounded="md"
        justifyContent="space-between"
        px={0}
        py={2}
        _hover={{
          bg: null
        }}
      >
        {left}
        <Divider mx={4} />
        {right}
        <AccordionIcon />
      </AccordionButton>
    </HStack>
    <AccordionPanel padding={0}>
      <Stack py={4} spacing={8}>
        {children}
      </Stack>
    </AccordionPanel>
  </AccordionItem>
);

export default AccordionBase;
