import {
  Button,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure
} from '@chakra-ui/react';
import LoadingWrapper from 'components/LoadingWrapper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import api from 'utils/api';
import VoteResultTable from './VoteResultTable';
import ModalWrapper from 'components/ModalWrapper';
import EmptyState from 'components/EmptyState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/pro-regular-svg-icons';

const StepVoteResults = ({ filter, workflowId, collectionId }) => {
  const {
    isOpen: showFullscreenModal,
    onOpen: openFullscreenModal,
    onClose: closeFullscreenModal
  } = useDisclosure();

  const [tabIndex, setTabIndex] = useState(0);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    status,
    data: multiSelects,
    isSuccess,
    refetch
  } = useQuery(
    ['multi-selects', { many: true }, workflowId, { step: filter?.step }],
    async () => {
      const params = new URLSearchParams();
      if (workflowId) {
        params.append('workflow', workflowId);
      }
      if (filter?.step) {
        if (filter?.step === -1) {
          params.append('step__isnull', true);
        } else {
          params.append('step', filter.step);
        }
      }
      if (filter?.step__isnull) {
        params.append('step__isnull', filter.step__isnull);
      }
      params.append('type', 'MULTI_SELECT');
      params.append('many', true);
      const { data } = await api.get(`/fields?${params.toString()}`);
      return data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    queryClient.removeQueries('multi-selects');
    refetch();
  }, [refetch, queryClient, collectionId, workflowId, filter]);

  return (
    <Stack>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.cards').toLowerCase()
          })
        ]}
      >
        {isSuccess && (
          <Tabs
            size="sm"
            variant="fresh"
            index={tabIndex}
            onChange={tabIndex => {
              setTabIndex(tabIndex);
            }}
          >
            <HStack justifyContent="space-between" alignItems="flex-start">
              <TabList>
                {multiSelects?.length > 0 ? (
                  multiSelects?.map(multiSelect => (
                    <Tab key={multiSelect.id}>{multiSelect.field}</Tab>
                  ))
                ) : (
                  <EmptyState
                    title={t('common.nothing_here_yet')}
                    filename="tea.svg"
                    borderWidth={0}
                  />
                )}
              </TabList>
              <Button
                isDisabled={multiSelects?.length < 1}
                variant="outline"
                onClick={openFullscreenModal}
              >
                <FontAwesomeIcon icon={faExpand} />
              </Button>
            </HStack>
            <TabPanels>
              {multiSelects?.map(multiSelect => (
                <TabPanel key={multiSelect.id}>
                  <VoteResultTable
                    multiSelect={multiSelect}
                    isAdminOrCollectionManager={true}
                  />
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
        <ModalWrapper
          isOpen={showFullscreenModal}
          onClose={closeFullscreenModal}
          size="full"
          maxWidth="full"
          height="full"
          blockScrollOnMount={false}
        >
          {multiSelects && (
            <VoteResultTable
              multiSelect={multiSelects[tabIndex]}
              isAdminOrCollectionManager={true}
              wideHeaders
            />
          )}
        </ModalWrapper>
      </LoadingWrapper>
    </Stack>
  );
};

export default StepVoteResults;
