import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Stack, Text } from '@chakra-ui/react';
import ModalWrapper from 'components/ModalWrapper';

const ConfirmationModal = ({
  deleteHeader,
  deleteText,
  buttonText = i18next.t('common.delete'),
  isLoading,
  isOpen,
  onClose,
  onDelete
}) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      title={deleteHeader ? deleteHeader : t('common.delete')}
      isOpen={isOpen}
      onClose={onClose}
      pb={6}
    >
      <Stack spacing={8}>
        <Text>{deleteText}</Text>
        <HStack spacing={4} width="full" justifyContent="flex-end">
          <Button onClick={onClose}>{t('button.cancel')}</Button>
          <Button colorScheme="red" onClick={onDelete} isLoading={isLoading}>
            {buttonText}
          </Button>
        </HStack>
      </Stack>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
