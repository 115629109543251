import CharacterCounterInput from './CharacterCounterInput';
import { useTranslation } from 'react-i18next';

const TitleInput = ({ control, maxLength = 200 }) => {
  const { t } = useTranslation();

  return (
    <CharacterCounterInput
      useControllerProps={{
        name: 'title',
        control: control,
        rules: {
          required: true,
          maxLength: {
            value: maxLength,
            message: t('common.character_limit_error', {
              limit: maxLength
            })
          }
        }
      }}
    />
  );
};

export default TitleInput;
