import { Center, Image, useColorModeValue } from '@chakra-ui/react';

const Illustration = ({ filename, maxHeight = '300px', ...rest }) => (
  <Center>
    <Image
      src={`/placeholders/${useColorModeValue('light', 'dark')}/${filename}`}
      maxHeight={maxHeight}
      {...rest}
    />
  </Center>
);

export default Illustration;
