import {
  Button,
  ButtonGroup,
  HStack,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import {
  faThumbsDown as faThumbsDownSolid,
  faThumbsUp as faThumbsUpSolid
} from '@fortawesome/pro-solid-svg-icons';

const Vote = ({
  voted,
  downVotes,
  upVotes,
  onClickVote,
  useDownvotes = false
}) => {
  const downVoteColor = useColorModeValue('red.500', 'red.200');
  const upVoteColor = useColorModeValue('blue.500', 'blue.200');
  return (
    <Stack
      spacing={4}
      justifyContent={['flex-end', null, 'flex-start']}
      alignItems="flex-end"
    >
      <ButtonGroup variant="outline">
        <Button onClick={() => onClickVote('UP')}>
          <HStack spacing={upVotes >= 10 ? 2 : 1}>
            <Text color={voted === 'UP' && upVoteColor}>
              <FontAwesomeIcon
                icon={voted === 'UP' ? faThumbsUpSolid : faThumbsUp}
              />
            </Text>
            <Text minW="16px" textAlign="right">
              {upVotes}
            </Text>
          </HStack>
        </Button>
        {useDownvotes && (
          <Button onClick={() => onClickVote('DOWN')}>
            <HStack spacing={downVotes >= 10 ? 2 : 1}>
              <Text color={voted === 'DOWN' && downVoteColor}>
                <FontAwesomeIcon
                  icon={voted === 'DOWN' ? faThumbsDownSolid : faThumbsDown}
                />
              </Text>
              <Text minW="16px" textAlign="right">
                {downVotes}
              </Text>
            </HStack>
          </Button>
        )}
      </ButtonGroup>
    </Stack>
  );
};

export default Vote;
