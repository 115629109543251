import {
  faDrawCircle,
  faLightbulb,
  faMessageLines,
  faNewspaper,
  faPuzzlePiece
} from '@fortawesome/pro-duotone-svg-icons';
import i18next from 'i18next';

export const isCollectionManager = (collection, me) => {
  return collection
    ? collection.managers.some(member => member.id === me?.id)
    : false;
};

export const getCollectingSingular = collectionType => {
  switch (collectionType) {
    case 'IDEA':
      return i18next.t('common.idea');
    case 'INSIGHT':
      return i18next.t('common.insight');
    case 'POST':
      return i18next.t('common.post');
    case 'FEEDBACK':
      return i18next.t('common.feedback');
    case 'SOLUTION':
      return i18next.t('common.solution');
    default:
      return i18next.t('common.idea');
  }
};

export const getYourCollectingSingular = collectionType => {
  switch (collectionType) {
    case 'IDEA':
      return i18next.t('common.your_idea');
    case 'INSIGHT':
      return i18next.t('common.your_insight');
    case 'POST':
      return i18next.t('common.your_post');
    case 'FEEDBACK':
      return i18next.t('common.your_feedback');
    case 'SOLUTION':
      return i18next.t('common.your_solution');
    default:
      return i18next.t('common.your_idea');
  }
};

export const getCollectingPlural = collectionType => {
  switch (collectionType) {
    case 'IDEA':
      return i18next.t('common.ideas');
    case 'INSIGHT':
      return i18next.t('common.insights');
    case 'POST':
      return i18next.t('common.posts');
    case 'FEEDBACK':
      return i18next.t('common.feedback');
    case 'SOLUTION':
      return i18next.t('common.solutions');
    default:
      return i18next.t('common.ideas');
  }
};

export const getCollectingIcon = collectionType => {
  switch (collectionType) {
    case 'IDEA':
      return faLightbulb;
    case 'INSIGHT':
      return faDrawCircle;
    case 'POST':
      return faNewspaper;
    case 'FEEDBACK':
      return faMessageLines;
    case 'SOLUTION':
      return faPuzzlePiece;
    default:
      return faLightbulb;
  }
};

export const getCollectingColor = collectionType => {
  switch (collectionType) {
    case 'IDEA':
      return 'yellow';
    case 'INSIGHT':
      return 'blue';
    case 'POST':
      return 'pink';
    case 'FEEDBACK':
      return 'green';
    case 'SOLUTION':
      return 'purple';
    default:
      return 'yellow';
  }
};

export const getMyContributions = collection => {
  switch (collection?.workflow?.type) {
    case 'INSIGHT':
      return i18next.t('collection.my_insights');
    case 'POST':
      return i18next.t('collection.my_posts');
    case 'FEEDBACK':
      return i18next.t('collection.my_feedback');
    case 'SOLUTION':
      return i18next.t('collection.my_solutions');
    default:
      return i18next.t('collection.my_ideas');
  }
};

export const getAllContributions = collection => {
  switch (collection?.workflow?.type) {
    case 'INSIGHT':
      return i18next.t('collection.all_insights');
    case 'POST':
      return i18next.t('collection.all_posts');
    case 'FEEDBACK':
      return i18next.t('collection.all_feedback');
    case 'SOLUTION':
      return i18next.t('collection.all_solutions');
    default:
      return i18next.t('collection.all_ideas');
  }
};

export const getShareSingular = collectionType => {
  switch (collectionType) {
    case 'IDEA':
      return i18next.t('card.share_idea');
    case 'INSIGHT':
      return i18next.t('card.share_insight');
    case 'POST':
      return i18next.t('card.share_post');
    case 'FEEDBACK':
      return i18next.t('card.share_feedback');
    case 'SOLUTION':
      return i18next.t('card.share_solution');
    default:
      return i18next.t('card.share_idea');
  }
};

export const appendCollectionPropsToURLParams = (props, params) => {
  let arr = [];
  if (props.open) {
    params.append('open', true);
  }
  if (props.noStep) {
    params.append('step__isnull', true);
  }
  if (props.boardId) {
    params.append('board', props.boardId);
  }
  if (props.folderId) {
    params.append('folder', props.folderId);
  }
  if (props.managerId) {
    params.append('managers', props.managerId);
  }
  if (props.projectId) {
    params.append('project', props.projectId);
  }
  if (props.stepId) {
    params.append('step', props.stepId);
  }
  if (props.workflowId) {
    params.append('workflow', props.workflowId);
  }
  if (props.search) {
    params.append('search', props.search);
  }
  return arr;
};
