import { Alert, Stack } from '@chakra-ui/react';
import LoadingIndicator from './LoadingIndicator';

const LoadingWrapper = ({
  areFetching,
  errorMessages,
  indicator,
  statuses,
  children
}) => {
  const errors = [];
  statuses?.forEach(
    (status, index) => status === 'error' && errors.push(errorMessages?.[index])
  );
  return (
    <>
      {statuses?.some(status => status === 'loading') ? (
        indicator ? (
          indicator
        ) : (
          <LoadingIndicator />
        )
      ) : errors.length > 0 ? (
        <Stack spacing={4}>
          {errors?.map((error, index) => (
            <Alert key={index} colorScheme="red">
              {error}
            </Alert>
          ))}
        </Stack>
      ) : statuses?.every(status => status === 'success') ? (
        <>
          {children}
          {areFetching?.some(fetch => fetch === true) && <LoadingIndicator />}
        </>
      ) : null}
    </>
  );
};

export default LoadingWrapper;
