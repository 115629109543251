import {
  Center,
  HStack,
  Skeleton,
  SkeletonCircle,
  Stack
} from '@chakra-ui/react';

const ChartSkeleton = () => {
  return (
    <Stack height="400px" justifyContent="center">
      <HStack px={5} justifyContent="flex-start">
        <Skeleton height="80px" width="30px" />
        <Skeleton height="250px" width="2px" />
        <HStack width="full" height="full">
          <Stack
            width="full"
            height="100px"
            alignItems="center"
            justifyContent="flex-end"
          >
            <SkeletonCircle size={8} />
          </Stack>
          <Stack
            width="full"
            height="200px"
            alignItems="center"
            justifyContent="flex-start"
          >
            <SkeletonCircle size={12} />
          </Stack>
          <Stack
            width="full"
            height="100px"
            alignItems="center"
            justifyContent="flex-start"
          >
            <SkeletonCircle size={10} />
          </Stack>
        </HStack>
      </HStack>
      <Stack alignItems="flex-end" px={4}>
        <Skeleton height="2px" width="88%" />
        <Center width="full">
          <Skeleton height="25px" width="80px" />
        </Center>
      </Stack>
    </Stack>
  );
};

export default ChartSkeleton;
