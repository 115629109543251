import { Link, useParams } from 'react-router-dom';
import { Avatar } from '@chakra-ui/react';

const MemberAvatar = ({ member, link = true, ...rest }) => {
  const { slug } = useParams();
  return (
    <Avatar
      as={link ? Link : null}
      to={member?.id ? `/${slug}/member/${member.id}` : null}
      cursor={link ? 'pointer' : null}
      src={
        member?.avatar
          ? member.avatar
          : member?.third_party_avatar
          ? member.third_party_avatar
          : null
      }
      name={member?.full_name}
      {...rest}
    />
  );
};

export default MemberAvatar;
