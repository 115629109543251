import React, { useRef } from 'react';
import { Text, Tooltip } from '@chakra-ui/react';

const TruncationTooltip = ({ label, children, ...rest }) => {
  const ref = useRef();

  const isEllipsisActive = element => {
    return element.offsetHeight < element.scrollHeight;
  };

  return (
    <Tooltip
      isDisabled={ref.current ? !isEllipsisActive(ref.current) : true}
      label={label}
      hasArrow
    >
      <Text noOfLines={1} ref={ref} {...rest}>
        {children}
      </Text>
    </Tooltip>
  );
};

export default TruncationTooltip;
