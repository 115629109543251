import { useRef, useState } from 'react';
import {
  Center,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  useColorMode
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import { findMedia, getMediaType } from 'utils/media';

const CoverMedia = ({
  children,
  object,
  attachment,
  hideIfEmpty = false,
  ...rest
}) => {
  const ref = useRef();
  const [previewMedia, setPreviewMedia] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [videoClicked, setVideoClicked] = useState(false);
  const { colorMode } = useColorMode();

  let media = findMedia(object);

  let hasImageFile = object?.image instanceof File;
  let hasVideoFile = object?.video instanceof File;

  let type = null;

  const readMediaAsURL = file => {
    const reader = new FileReader();
    reader.onload = () => {
      if (type !== 'video') {
        setPreviewMedia(reader.result);
      } else {
        setFileName(file.name);
        setPreviewMedia('placeholder');
      }
    };
    reader.readAsDataURL(file);
  };

  if (attachment) {
    type = attachment.type?.split('/')[0];
    if (type === 'unsplash') {
      type = 'image';
    } else {
      readMediaAsURL(attachment);
    }
  } else if (object) {
    if (hasImageFile) {
      type = 'image';
      readMediaAsURL(object.image);
    } else if (hasVideoFile) {
      type = 'video';
      readMediaAsURL(object.video);
    } else {
      //normal image url:s fall under here
      type = getMediaType(media);
    }
  }

  const handleOnClickPlay = () => {
    if (ref.current) {
      setVideoClicked(true);
      ref.current.play();
    }
  };

  if (hideIfEmpty && !hasImageFile && !hasVideoFile && !type) {
    return null;
  }

  return (
    <Center
      bg={
        type === 'video'
          ? 'black'
          : colorMode === 'light'
          ? 'gray.50'
          : 'whiteAlpha.100'
      }
      rounded="lg"
      position="relative"
      {...rest}
    >
      {type === 'image' ? (
        <Image
          src={
            attachment?.file
              ? attachment.file
              : previewMedia
              ? previewMedia
              : media
          }
          height="full"
          width="full"
          rounded="lg"
          objectFit="cover"
          pointerEvents="none"
          {...rest}
        />
      ) : type === 'video' ? (
        media && previewMedia !== 'placeholder' ? (
          <Flex bg="blackAlpha.500" rounded="lg" height="full" width="full">
            <video
              ref={ref}
              src={media}
              controls={videoClicked}
              position="relative"
              width="100%"
              style={{ borderRadius: '10px' }}
            >
              Your browser does not support the video tag.
            </video>
            {!videoClicked && (
              <Flex
                justify="center"
                align="center"
                position="absolute"
                bg="blackAlpha.500"
                height="full"
                width="full"
                rounded="lg"
                cursor="pointer"
                top={0}
                left={0}
                onClick={handleOnClickPlay}
              >
                <Text color="gray.200">
                  <FontAwesomeIcon icon={faPlayCircle} size="3x" />
                </Text>
              </Flex>
            )}
          </Flex>
        ) : (
          <Flex
            bg="blackAlpha.500"
            rounded="lg"
            height="full"
            width="full"
            justify="center"
            align="center"
            color="gray.200"
          >
            <HStack spacing={4} alignItems="flex-start">
              <FontAwesomeIcon icon={faPlayCircle} size="3x" />
              <Stack justifyContent="center" alignItems="center">
                <Text maxWidth="230px" noOfLines={4} color="gray.200">
                  {fileName}
                </Text>
              </Stack>
            </HStack>
          </Flex>
        )
      ) : (
        <Text color={colorMode === 'light' ? 'gray.400' : 'whiteAlpha.400'}>
          <FontAwesomeIcon icon={faImage} size="3x" />
        </Text>
      )}
      {children}
    </Center>
  );
};

export default CoverMedia;
