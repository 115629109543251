import axios from 'axios';
import { useTeams } from 'msteams-react-base-component';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react';
import BoxContainer from 'components/BoxContainer';
import StepModalHeader from 'components/StepModalHeader';
import LoadingWrapper from 'components/LoadingWrapper';
import WorkflowListItem from 'features/workflow/WorkflowListItem';
import WorkflowStepList from 'features/workflow/WorkflowStepList';
import api from 'utils/api';
import ShareCollection from './ShareCollection';
import CollectionForm from './CollectionForm';
import { getCollectingSingular } from './collectionUtils';

const CollectionCreationForm = ({
  board,
  folder,
  onClose,
  onCollectionCreated,
  newCollection
}) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const [{ inTeams }] = useTeams();
  const [showSkipButton, setShowSkipButton] = useState(true);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [lastStepIndex] = useState(inTeams || !!!hive?.use_share ? 1 : 2);
  const [collection, setCollection] = useState(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const queryClient = useQueryClient();

  const toast = useToast();
  const { t } = useTranslation();

  const {
    status,
    data: workflows,
    isSuccess
  } = useQuery(['workflows', slug], async () => {
    const params = new URLSearchParams({
      hive__slug: slug
    });
    const { data } = await api.get(`/workflows?${params.toString()}`);
    return data;
  });

  const createCollectionMutation = useMutation(
    collection => api.post('/collections', collection),
    {
      onSuccess: ({ data: collection }) => {
        const hive = queryClient.getQueryData(['hive', collection.hive.slug]);
        queryClient.setQueryData(['hive', collection.hive.slug], {
          ...hive,
          metrics: {
            ...hive.metrics,
            collections: hive.metrics.collections + 1
          }
        });
        toast({
          title: 'Collection was successfully created.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        setCollection(collection);
        onCollectionCreated(collection);
        setCurrentStepIndex(2);
      },
      onError: () => {
        toast({
          title: 'Could not create collection. Please try again later.',
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnCreateCollection = async collection => {
    const payload = new FormData();
    payload.append('hive', hive.id);
    if (board) {
      payload.append('board', board.id);
    }
    if (folder) {
      payload.append('folder', folder.id);
    }
    if (selectedWorkflow) {
      payload.append('workflow', selectedWorkflow.id);
    }
    payload.append('image', collection.image ? collection.image : '');
    payload.append('unsplash', collection.unsplash ? collection.unsplash : '');
    payload.append('title', collection.title);
    payload.append(
      'description',
      collection.description ? collection.description : ''
    );
    if (collection.video instanceof File) {
      try {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: collection.video.name
        });
        await axios.put(data.url, collection.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      } catch (e) {
        console.log(e);
      }
    }
    await createCollectionMutation.mutateAsync(payload);
  };

  const AddToBoardText = () =>
    board ? (
      <Stack spacing={4}>
        <Text width="full" variant="muted" noOfLines={4}>
          <Trans
            i18nKey="collection.will_be_added_to_board_title"
            values={{
              title: `"${board?.title}"`
            }}
          />
        </Text>
        <Divider />
      </Stack>
    ) : null;

  useEffect(() => {
    if (newCollection?.id) {
      if (currentStepIndex > lastStepIndex) {
        onClose();
      }
    }
  }, [newCollection, currentStepIndex, lastStepIndex, onClose]);

  return (
    <LoadingWrapper
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.workflows').toLowerCase()
        })
      ]}
    >
      <Stack>
        {isSuccess &&
          (collection && currentStepIndex === 2 && hive?.use_share ? (
            <Stack spacing={8}>
              <StepModalHeader
                currentTitle={t('common.share_collection')}
                currentStepIndex={currentStepIndex}
                setCurrentStepIndex={setCurrentStepIndex}
                lastStepIndex={lastStepIndex}
                showBackButton={false}
              />
              <Flex flexDirection="column" minHeight="240px">
                <ShareCollection
                  collection={collection}
                  onLinkCreated={data => {
                    setShowSkipButton(false);
                    setCollection(data);
                  }}
                  onLinkDeleted={data => {
                    setCollection(data);
                  }}
                  onChangeBoard={async board => {
                    setShowSkipButton(false);
                    try {
                      const { data } = await api.patch(
                        `/collections/${collection?.id}?hive__slug=${slug}`,
                        { board }
                      );
                      setCollection(data);
                    } catch (e) {
                      toast({
                        title:
                          'Could not update collection. Please try again later.',
                        status: 'error',
                        position: 'bottom-right',
                        isClosable: true
                      });
                    }
                  }}
                  onInviteSent={() => {
                    setShowSkipButton(false);
                  }}
                  onShareSocialMediaChange={data => {
                    setCollection(data);
                  }}
                />
              </Flex>
              <Flex justifyContent="flex-end">
                <HStack spacing={4}>
                  {showSkipButton && (
                    <Button variant="link" onClick={onClose}>
                      {t('button.skip_for_now')}
                    </Button>
                  )}
                  <Button colorScheme="teal" onClick={onClose}>
                    {t('common.done')}
                  </Button>
                </HStack>
              </Flex>
            </Stack>
          ) : (
            <>
              {currentStepIndex === 0 && (
                <Stack spacing={8}>
                  <StepModalHeader
                    currentTitle={t('common.create_collection')}
                    currentStepIndex={currentStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                    lastStepIndex={lastStepIndex}
                    onClose={onClose}
                  />
                  <AddToBoardText />
                  <Stack spacing={4}>
                    <Text>
                      {hive?.plan === 'FREEMIUM'
                        ? t('common.pick_a_workflow_freemium')
                        : t('common.pick_a_workflow')}
                    </Text>

                    <Masonry
                      breakpointCols={{
                        992: 2,
                        768: 1
                      }}
                      className="masonry-grid"
                      columnClassName="masonry-column"
                    >
                      {workflows.map(workflow => (
                        <BoxContainer
                          boxShadow={
                            selectedWorkflow?.id === workflow.id
                              ? 'outline'
                              : null
                          }
                          key={workflow.id}
                          onClick={() => {
                            setSelectedWorkflow(
                              selectedWorkflow?.id === workflow.id
                                ? null
                                : workflow
                            );
                          }}
                        >
                          <Stack spacing={4}>
                            <WorkflowListItem
                              key={workflow.id}
                              workflow={workflow}
                            />
                            <Collapse in={selectedWorkflow?.id === workflow.id}>
                              <Stack spacing={8}>
                                <WorkflowStepList workflow={workflow} />
                                <Button
                                  alignSelf="flex-start"
                                  colorScheme="teal"
                                  onClick={e => {
                                    e.stopPropagation();
                                    setCurrentStepIndex(currentStepIndex + 1);
                                  }}
                                >
                                  {t('common.select')}
                                </Button>
                              </Stack>
                            </Collapse>
                          </Stack>
                        </BoxContainer>
                      ))}
                    </Masonry>
                  </Stack>
                </Stack>
              )}
              {currentStepIndex === 1 && (
                <Stack spacing={8}>
                  <StepModalHeader
                    currentTitle={t('collection.type_collection', {
                      type: getCollectingSingular(selectedWorkflow?.type)
                    })}
                    currentStepIndex={currentStepIndex}
                    lastStepIndex={lastStepIndex}
                    setCurrentStepIndex={setCurrentStepIndex}
                  />
                  <AddToBoardText />
                  <CollectionForm onSubmit={handleOnCreateCollection} />
                </Stack>
              )}
            </>
          ))}
      </Stack>
    </LoadingWrapper>
  );
};

export default CollectionCreationForm;
