import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Spacer,
  Stack,
  Tooltip,
  useColorModeValue,
  useMediaQuery
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faLock,
  faLockOpen
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import CollectionSteps from './CollectionSteps';
import FilterBox from './FilterBox';
import { useSearchParams } from 'react-router-dom';

const CollectionSidebar = ({
  collection,
  filter,
  isOpen,
  setFilter,
  selectedView,
  setSelectedView,
  changeCardStep,
  onToggleSidebar,
  onClickOpen,
  onClickStepEdit
}) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 62em)');
  const sidebarBackground = useColorModeValue('white', 'gray.800');
  const [searchParams, setSearchParams] = useSearchParams();

  const SidebarHeading = ({ isMobile }) => (
    <Stack
      spacing={4}
      width="full"
      position="sticky"
      top={0}
      bg={isMobile ? null : sidebarBackground}
      zIndex={1}
    >
      <HStack px={4} pt={4} mt={0}>
        <Heading fontSize="xl">{t('collection.manager_menu')}</Heading>
        <Tooltip hasArrow label={t('collection.manager_tooltip')}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
        <Spacer />
        <Button variant="outline" onClick={onToggleSidebar}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>
      </HStack>
      <Divider />
    </Stack>
  );

  const SidebarContent = () => (
    <Stack spacing={4} padding={4} pb={12}>
      <FilterBox
        text={t('common.collection')}
        selected={selectedView === 'collection'}
        onClick={() => {
          setSelectedView('collection');
          setFilter({ ...filter, step: null });
          setSearchParams({ ...searchParams.delete('step') });
          if (window.innerWidth < 1024) {
            onToggleSidebar();
          }
        }}
      />
      <FilterBox
        text={t('common.dashboard')}
        selected={selectedView === 'dashboard'}
        onClick={() => {
          setSelectedView('dashboard');
          setFilter({ ...filter, step: null });
          setSearchParams({ ...searchParams.delete('step') });
          if (window.innerWidth < 1024) {
            onToggleSidebar();
          }
        }}
      />
      <HStack py={4}>
        <Heading fontSize="lg">{t('common.steps')}</Heading>
        <Tooltip hasArrow label={t('collection.step_tooltip')}>
          <Box>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Box>
        </Tooltip>
        <Spacer />
        <Tooltip
          hasArrow
          label={
            collection.open
              ? t('collection.open_collection_click_to_close_tooltip')
              : t('collection.closed_collection_click_to_open_tooltip')
          }
        >
          <Button variant="outline" size="sm" onClick={onClickOpen}>
            <FontAwesomeIcon
              icon={collection.open ? faLockOpen : faLock}
              fixedWidth
            />
          </Button>
        </Tooltip>
        <Button size="sm" variant="outline" onClick={onClickStepEdit}>
          {t('common.edit')}
        </Button>
      </HStack>
      {collection && (
        <CollectionSteps
          collection={collection}
          filter={filter}
          selectedView={selectedView}
          setFilter={setFilter}
          setSelectedView={setSelectedView}
          onClickCallback={() => {
            if (window.innerWidth < 1024) {
              onToggleSidebar();
            }
          }}
          changeCardStep={changeCardStep}
        />
      )}
    </Stack>
  );

  return isMobile ? (
    <Drawer placement="left" onClose={onToggleSidebar} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent position="relative">
        <DrawerHeader px={0}>
          <SidebarHeading isMobile />
        </DrawerHeader>
        <DrawerBody px={0}>
          <SidebarContent />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  ) : (
    <Stack position="relative">
      <SidebarHeading />
      <SidebarContent />
    </Stack>
  );
};

export default CollectionSidebar;
