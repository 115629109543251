import { useTranslation } from 'react-i18next';
import { Stack } from '@chakra-ui/react';
import { faFolderOpen, faPenField } from '@fortawesome/pro-duotone-svg-icons';
import CollectionStepItem from './CollectionStepItem';
import FilterBox from './FilterBox';
import { useSearchParams } from 'react-router-dom';

const CollectionSteps = ({
  collection,
  filter,
  selectedView,
  changeCardStep,
  setFilter,
  setSelectedView,
  onClickCallback
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const getAllCards = type => {
    switch (type) {
      case 'IDEA':
        return t('common.all_ideas');
      case 'INSIGHT':
        return t('common.all_insights');
      case 'POST':
        return t('common.all_posts');
      case 'FEEDBACK':
        return t('common.all_feedback');
      case 'SOLUTION':
        return t('common.all_solutions');
      default:
        return null;
    }
  };

  return (
    <Stack spacing={4}>
      <FilterBox
        text={getAllCards(collection.type)}
        selected={selectedView === 'step' && filter.step === null}
        onClick={() => {
          setFilter({ ...filter, step: null });
          setSelectedView('step');
          setSearchParams({ ...searchParams.delete('step') });
          onClickCallback();
        }}
      />
      <CollectionStepItem
        icon={faFolderOpen}
        count={collection?.metrics?.cards_per_step[0].cards}
        selected={selectedView === 'step' && parseInt(filter?.step) === -1}
        step={null}
        text={t('common.inbox')}
        type={collection.type}
        changeCardStep={changeCardStep}
        onClick={() => {
          setFilter({ ...filter, step: -1 });
          setSelectedView('step');
          setSearchParams({ ...searchParams, step: -1 });
          onClickCallback();
        }}
        hideCards={collection.workflow?.hide_inbox_cards}
      />
      {collection.workflow?.steps.map((step, index) => (
        <CollectionStepItem
          key={index}
          badgeIconColorScheme={step.color}
          count={
            collection.metrics.cards_per_step.find(
              item => item.step === step.id
            )
              ? collection.metrics.cards_per_step.find(
                  item => item.step === step.id
                ).cards
              : 0
          }
          icon={faPenField}
          selected={
            selectedView === 'step' && parseInt(filter?.step) === step.id
          }
          step={step.id}
          text={step.name}
          type={collection.type}
          changeCardStep={changeCardStep}
          onClick={() => {
            setFilter({ ...filter, step: step.id });
            setSelectedView('step');
            setSearchParams({ ...searchParams, step: step.id });
            onClickCallback();
          }}
          hideCards={step.hide_cards}
        />
      ))}
    </Stack>
  );
};

export default CollectionSteps;
