import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Stack,
  Text
} from '@chakra-ui/react';
import CollectionCardFilter from 'features/collection/CollectionCardFilter';
import { useQuery } from 'react-query';
import api from 'utils/api';
import LoadingWrapper from './LoadingWrapper';
import { Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { getActiveStepFilters } from 'features/workflow/workflowUtils';

const FilterDrawer = ({
  filter,
  setFilter,
  onClose,
  isOpen,
  collection,
  onChangeOptions
}) => {
  const { t } = useTranslation();
  const {
    data: fields,
    isSuccess,
    status
  } = useQuery(
    ['fields', collection?.workflow?.id, { type: 'MULTI_SELECT' }],
    async () => {
      const params = new URLSearchParams();
      if (collection) {
        params.append('collection', collection.id);
        if (collection?.workflow?.id) {
          params.append('workflow', collection.workflow.id);
        }
      }
      params.append('type', 'MULTI_SELECT');
      const { data } = await api.get(`/fields?${params.toString()}`);
      return data;
    }
  );

  const noOfActiveFilters = getActiveStepFilters(filter);

  return (
    <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {noOfActiveFilters > 0
            ? noOfActiveFilters === 1
              ? t('common.nr_filters_active_with_count_one', {
                  nr: noOfActiveFilters
                })
              : t('common.nr_filters_active_with_count_several', {
                  nr: noOfActiveFilters
                })
            : t('common.filters')}
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Stack spacing={4}>
            <LoadingWrapper statuses={[status]}>
              {isSuccess && (
                <Stack spacing={8}>
                  <CollectionCardFilter
                    collection={collection}
                    filter={filter}
                    setFilter={setFilter}
                    omittedFilters={{ step: true }}
                    fields={fields}
                    menuPortalTarget={null}
                    columns={[1]}
                  />
                  {collection.workflow?.steps.length > 0 &&
                    fields?.map(field => (
                      <Box key={field.id}>
                        <Text variant="muted">{field.field}</Text>
                        <Select
                          variant="flushed"
                          defaultValue={field.options.find(
                            option => option.id === filter?.options?.[field.id]
                          )}
                          placeholder={t('common.select')}
                          isClearable
                          menuPortalTarget={null}
                          options={field?.options}
                          getOptionLabel={option => option.option}
                          getOptionValue={option => option?.id}
                          onChange={option => {
                            let newFilter = { ...filter };
                            newFilter.options = { ...filter?.options };
                            if (option?.id && newFilter.options) {
                              newFilter.options[field.id] = option.id;
                            } else if (newFilter.options?.[field.id]) {
                              delete newFilter.options?.[field.id];
                            }
                            onChangeOptions(newFilter.options);
                          }}
                          useBasicStyles
                        />
                      </Box>
                    ))}
                </Stack>
              )}
            </LoadingWrapper>
          </Stack>
        </DrawerBody>
        <DrawerFooter />
      </DrawerContent>
    </Drawer>
  );
};

export default FilterDrawer;
