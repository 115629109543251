import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  AspectRatio,
  Button,
  Container,
  Heading,
  HStack,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import CoverMedia from 'components/CoverMedia';
import TitleDescription from 'components/TitleDescription';
import AnnouncementDetailed from 'features/announcement/AnnouncementDetailed';
import AnnouncementListItem from 'features/announcement/AnnouncementListItem';
import BoardList from 'features/board/BoardList';
import HomeForm from './HomeForm';
import HomeSkeleton from './HomeSkeleton';
import ModalWrapper from 'components/ModalWrapper';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';

import HomeActionMenu from './HomeActionMenu';
import { Plans, hasPlan } from 'features/hive/hiveUtils';
import MyCollections from 'features/collection/MyCollections';

const HomePage = () => {
  const { slug } = useParams();
  const { data: me } = useQuery('me');
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  const {
    isOpen: showAnnouncementModal,
    onOpen: openAnnouncementModal,
    onClose: closeAnnouncementModal
  } = useDisclosure();

  const {
    isOpen: showHomeModal,
    onOpen: openHomeModal,
    onClose: closeHomeModal
  } = useDisclosure();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const {
    status,
    data: home,
    isSuccess
  } = useQuery(['home', slug], async () => {
    const { data } = await api.get(`/homes?hive=${hive.id}`);
    if (data && data.length > 0) {
      return data[0];
    }
    return null;
  });

  const { data: metrics } = useQuery(['metrics', 'home', slug], async () => {
    const { data } = await api.get(`/metrics/home?slug=${slug}`);
    return data.metrics;
  });

  const createHomeMutation = useMutation(
    async home => await api.post('/homes', home, { timeout: 0 }),
    {
      onSuccess: ({ data: home }) => {
        queryClient.setQueryData(['home', slug], home);
        closeHomeModal();
      }
    }
  );

  const deleteHomeMutation = useMutation(
    async () => {
      await api.delete(`/homes/${home?.id}`);
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(['home', slug], null);
        closeHomeModal();
      }
    }
  );

  const updateHomeMutation = useMutation(
    async payload =>
      await api.patch(`/homes/${home.id}`, payload, { timeout: 0 }),
    {
      onSuccess: ({ data: home }) => {
        queryClient.setQueryData(['home', slug], home);
        closeHomeModal();
      }
    }
  );

  const handleOnCreateOrUpdateHome = async home => {
    const payload = new FormData();
    if (!home.image || home.image instanceof File) {
      payload.append('image', home.image ? home.image : '');
    }
    if (!home.video) {
      payload.append('video', '');
    }
    payload.append('unsplash', home.unsplash ? home.unsplash : '');
    payload.append('title', home.title);
    payload.append('description', home.description ? home.description : '');
    if (home.video instanceof File) {
      try {
        const { data } = await api.post('/s3/generate-presigned-url', {
          filename: home.video.name
        });
        await axios.put(data.url, home.video, {
          headers: { 'x-amz-acl': 'public-read' }
        });
        payload.append('video', data.key);
      } catch (e) {
        console.log(e);
      }
    }
    if (home.id) {
      await updateHomeMutation.mutateAsync(payload);
    } else {
      payload.append('hive', hive.id);
      await createHomeMutation.mutateAsync(payload);
    }
  };

  const handleOnDeleteHome = async () => {
    await deleteHomeMutation.mutateAsync();
  };

  return hasPlan(hive?.plan, Plans.Freemium) ? (
    <MyCollections />
  ) : (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        indicator={<HomeSkeleton />}
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.home').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <>
            <Stack spacing={16}>
              <Stack spacing={8}>
                {me ? (
                  <>
                    <Stack>
                      <HStack justifyContent="space-between">
                        <Heading fontSize="3xl">
                          {t('home.hi_name', { name: me.first_name })}
                        </Heading>
                        {profile?.is_admin && (
                          <Show below="md">
                            <HomeActionMenu onClickHome={openHomeModal} />
                          </Show>
                        )}
                      </HStack>
                      <Text>{t('home.happy_to_see_you_here_today')}</Text>
                    </Stack>
                  </>
                ) : null}
                <SimpleGrid columns={[1, null, 2]} spacing={8}>
                  <AspectRatio ratio={16 / 9}>
                    <CoverMedia object={home} />
                  </AspectRatio>
                  <TitleDescription
                    title={home?.title}
                    description={home?.description}
                    button={
                      profile?.is_admin && (
                        <Show above="sm">
                          <HomeActionMenu onClickHome={openHomeModal} />
                        </Show>
                      )
                    }
                    noOfLines={24}
                  />
                </SimpleGrid>
                {home?.announcement && (
                  <>
                    <Stack spacing={4}>
                      <AnnouncementListItem
                        announcement={home.announcement}
                        onClickReadMore={openAnnouncementModal}
                      />
                      <Button
                        alignSelf="flex-start"
                        as={Link}
                        variant="link"
                        to={`/${slug}/announcements`}
                      >
                        {t('button.see_all_announcements')}
                      </Button>
                    </Stack>
                    <AnnouncementDetailed
                      announcement={home.announcement}
                      isOpen={showAnnouncementModal}
                      onClose={closeAnnouncementModal}
                      canEdit={profile?.is_admin_or_manager}
                    />
                  </>
                )}
              </Stack>
              {metrics?.boards > 0 && (
                <Stack spacing={8}>
                  <BoardList />
                </Stack>
              )}
            </Stack>
            <ModalWrapper
              title={t('common.home')}
              isOpen={showHomeModal}
              onClose={closeHomeModal}
              pb={6}
            >
              <HomeForm
                defaultValues={home}
                isOpen={showHomeModal}
                onDelete={handleOnDeleteHome}
                onSubmit={handleOnCreateOrUpdateHome}
              />
            </ModalWrapper>
          </>
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default HomePage;
