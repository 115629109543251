import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormHelperText,
  Stack,
  Textarea
} from '@chakra-ui/react';
import { useUi } from 'providers/UiProvider';

const InputForm = ({ defaultValue, helpText, isOpen, onSubmit, ...rest }) => {
  const { t } = useTranslation();
  const { setModalClosureLocked } = useUi();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset();
  }, [reset, isOpen]);

  useEffect(() => {
    setModalClosureLocked(isDirty);
  }, [setModalClosureLocked, isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...rest}>
      <Stack spacing={4}>
        <FormControl id="value">
          <Stack>
            {helpText && <FormHelperText>{helpText}</FormHelperText>}
            <Textarea
              rows={5}
              {...register('value')}
              defaultValue={defaultValue}
              placeholder={t('placeholder.your_response')}
            />
          </Stack>
        </FormControl>
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default InputForm;
