import { useTranslation } from 'react-i18next';
import { Button, Center, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import AccordionBase from 'components/AccordionBase';
import HeadingButton from 'components/HeadingButton';
import CollectionList from 'features/collection/CollectionList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleVerticalHistory } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const CollectionStep = ({ projectId, onClickCreateCollection }) => {
  const { t } = useTranslation();
  return (
    <AccordionBase
      left={
        <HStack spacing={4}>
          <Center width={8}>
            <FontAwesomeIcon icon={faRectangleVerticalHistory} fixedWidth />
          </Center>
          <Heading fontSize="lg">{t('common.collections')}</Heading>
        </HStack>
      }
    >
      <Stack spacing={8}>
        <HeadingButton
          title=""
          fontSize="lg"
          button={
            <Button
              colorScheme="teal"
              onClick={() => onClickCreateCollection(null)}
            >
              <HStack>
                <FontAwesomeIcon icon={faPlus} />
                <Text>{t('common.collection')}</Text>
              </HStack>
            </Button>
          }
        />
        <CollectionList projectId={projectId} noStep />
      </Stack>
    </AccordionBase>
  );
};

export default CollectionStep;
