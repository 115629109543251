import { Badge, Button, HStack, Text } from '@chakra-ui/react';

const FilterButton = ({ title, count, selected, ...rest }) => (
  <Button size="sm" variant="filter" selected={selected} {...rest}>
    <HStack>
      <Text>{title}</Text>
      {count && (
        <Badge variant={selected ? 'solid' : 'subtle'}>
          {count ? count : 0}
        </Badge>
      )}
    </HStack>
  </Button>
);

export default FilterButton;
