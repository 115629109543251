import { Link, useParams } from 'react-router-dom';
import { Button, HStack, Spacer, Text } from '@chakra-ui/react';
import MemberAvatar from 'components/MemberAvatar';

const LeaderboardListItem = ({ contributor }) => {
  const { slug } = useParams();

  return (
    <HStack spacing={4}>
      <MemberAvatar member={contributor} size="sm" />
      <Button
        variant="link"
        as={Link}
        to={`/${slug}/member/${contributor.id}`}
        flexShrink={1}
        whiteSpace="normal"
      >
        {contributor.full_name}
      </Button>
      <Spacer />
      <Text fontWeight="bold">{contributor.score}</Text>
    </HStack>
  );
};

export default LeaderboardListItem;
