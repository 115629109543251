import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Button,
  Circle,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Stack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const AboutForm = ({ defaultValues, onSubmit }) => {
  const [avatarChanged, setAvatarChanged] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const fileRef = useRef();
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({ mode: 'onChange', defaultValues });

  useEffect(() => {
    if (!isSubmitting) {
      setAvatarChanged(false);
      setPreviewImage(defaultValues?.avatar);
      reset(defaultValues);
    }
  }, [defaultValues, reset, isSubmitting]);

  const handleOnClickAvatar = () => {
    fileRef.current.click();
  };

  const handleOnChangeImage = e => {
    const image = e.target.files[0];
    const reader = new FileReader();
    reader.onload = e => {
      setPreviewImage(e.target.result);
    };
    reader.readAsDataURL(image);
    setValue('avatar', image);
    setAvatarChanged(true);
  };

  const handleOnDeleteImage = () => {
    setPreviewImage(null);
    setValue('avatar', null);
    setAvatarChanged(true);
  };

  return (
    <form onSubmit={handleSubmit(values => onSubmit(values, avatarChanged))}>
      <Stack spacing={4}>
        <Circle position="relative" size={24}>
          <Avatar
            src={previewImage}
            name={
              defaultValues?.first_name && defaultValues?.last_name
                ? `${defaultValues?.first_name} ${defaultValues?.last_name}`
                : null
            }
            size="xl"
            onClick={handleOnClickAvatar}
            cursor="pointer"
          />
          {previewImage && (
            <Button
              variant="overlay"
              position="absolute"
              right={-3}
              top={-3}
              rounded="full"
              h={10}
              w={10}
              onClick={handleOnDeleteImage}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          )}
        </Circle>
        <SimpleGrid columns={[1, null, 2]} spacing={4}>
          <FormControl id="first_name">
            <FormLabel>{t('common.first_name')}</FormLabel>
            <Input
              placeholder={t('placeholder.enter_first_name')}
              {...register('first_name', {
                required: true
              })}
            />
          </FormControl>
          <FormControl id="last_name">
            <FormLabel>{t('common.last_name')}</FormLabel>
            <Input
              placeholder={t('placeholder.enter_last_name')}
              {...register('last_name', {
                required: true
              })}
            />
          </FormControl>
          <Input
            {...register('avatar')}
            accept="image/*"
            type="file"
            ref={fileRef}
            onChange={handleOnChangeImage}
            hidden
          />
        </SimpleGrid>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!avatarChanged && (!isDirty || !isValid)}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default AboutForm;
