import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import 'styles/Masonry.css';
import 'styles/CustomScrollbar.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProviders from 'providers/AppProviders';
import AppRouter from 'routers/AppRouter';
import { ColorModeScript } from '@chakra-ui/color-mode';
import theme from 'theme';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(_ => {
      console.log('Service worker registered');
    })
    .catch(e => {
      console.log(e);
    });
}

const root = createRoot(document.getElementById('root'));

root.render(
  <>
    <ColorModeScript initialColorMode={theme} />
    <Router>
      <AppProviders>
        <AppRouter />
      </AppProviders>
    </Router>
  </>
);
