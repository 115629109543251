import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@chakra-ui/react';
import CustomMDXEditor from 'components/CustomMDXEditor';

const ConsentForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <Controller
          control={control}
          name="consent_text"
          defaultValue={defaultValues?.consent_text}
          render={({ field }) => (
            <CustomMDXEditor field={field} setValue={setValue} />
          )}
        />
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default ConsentForm;
