import { Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { FormControl, Stack, Text } from '@chakra-ui/react';
import ModalWrapper from 'components/ModalWrapper';
import api from 'utils/api';

const PublishCollectionModal = ({ board, isOpen, onClose, onChange }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const { t } = useTranslation();

  const { status, data: boards } = useQuery('boards', async () => {
    const { data } = await api.get(`/boards?hive=${hive.id}`);
    return data;
  });

  return (
    <ModalWrapper
      title={t('common.publish_collection')}
      isOpen={isOpen}
      onClose={onClose}
      pb={8}
    >
      <Stack spacing={4}>
        <Text>{t('collection.publish_explainer')}</Text>
        <FormControl id="board">
          <Select
            defaultValue={board}
            options={status === 'success' ? boards : []}
            getOptionLabel={board => board.title}
            getOptionValue={board => board.id}
            onChange={board => {
              onChange(board?.id ? board.id : null);
            }}
            isClearable
            useBasicStyles
          />
        </FormControl>
      </Stack>
    </ModalWrapper>
  );
};

export default PublishCollectionModal;
