import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import CheckYourEmail from 'components/CheckYourEmail';
import { useAuth } from 'providers/AuthProvider';
import api from 'utils/api';
import InfoColumn from './InfoColumn';
import LoginForm from './LoginForm';
import LoadingWrapper from 'components/LoadingWrapper';

const LoginPage = () => {
  const { authenticated } = useAuth();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug], { enabled: !!slug });

  const [confirmationEmailSent, setConfirmationEmailSent] = useState(false);
  const [email, setEmail] = useState(null);
  const [antiFlickerDelay, setAntiFlickerDelay] = useState('loading');

  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (authenticated) {
      const slug = localStorage.getItem('slug');
      if (slug) {
        navigate(`/${slug}`);
      } else {
        navigate('/join');
      }
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    setTimeout(() => {
      setAntiFlickerDelay('success');
    }, 200);
  }, []);

  const addFromParamIfNecessary = params => {
    const from = new URLSearchParams(location.search).get('from');
    if (from) {
      params.append(
        'state',
        window.btoa(
          JSON.stringify({
            from,
            slug
          })
        )
      );
    } else {
      params.append(
        'state',
        window.btoa(
          JSON.stringify({
            slug
          })
        )
      );
    }
  };

  const createConfirmationMutation = useMutation(
    payload => api.post('/auth/confirm', payload),
    {
      onSuccess: () => {
        setConfirmationEmailSent(true);
      }
    }
  );

  const handleOnContinueWithMail = async ({ email }) => {
    setEmail(email);
    const from = new URLSearchParams(location.search).get('from');
    if (
      from &&
      (from.match(/^\/shared\/collection\/([a-zA-Z0-9-]+)$/) ||
        from.match(/^\/invited\/collection\/([a-zA-Z0-9-]+)$/))
    ) {
      await createConfirmationMutation.mutateAsync({
        email,
        slug,
        state: window.btoa(
          JSON.stringify({
            from,
            slug
          })
        )
      });
    } else {
      const state = slug
        ? window.btoa(
            JSON.stringify({
              slug
            })
          )
        : null;
      if (state) {
        await createConfirmationMutation.mutateAsync({ email, slug, state });
      } else {
        await createConfirmationMutation.mutateAsync({ email, slug });
      }
    }
  };

  // const handleOnContinueWithFacebook = () => {
  //   updateSlug();
  //   const url = `https://www.facebook.com/v15.0/dialog/oauth`;
  //   const params = new URLSearchParams({
  //     client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
  //     response_type: 'code',
  //     redirect_uri: `${process.env.REACT_APP_URL}/auth/facebook`,
  //     scope: 'email'
  //   });
  //   addFromParamIfNecessary(params);
  //   window.open(`${url}?${params.toString()}`, '_self');
  // };

  const handleOnContinueWithGoogle = () => {
    const url = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/google`,
      scope: 'openid email profile',
      access_type: 'offline',
      prompt: 'consent'
    });
    addFromParamIfNecessary(params);
    window.open(`${url}?${params.toString()}`, '_self');
  };

  const handleOnContinueWithLinkedIn = () => {
    const url = 'https://www.linkedin.com/oauth/v2/authorization';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      response_type: 'code',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/linkedin`,
      scope: 'r_liteprofile r_emailaddress'
    });
    addFromParamIfNecessary(params);
    window.open(`${url}?${params.toString()}`, '_self');
  };

  const handleOnContinueWithMicrosoft = () => {
    const url =
      'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
      response_type: 'code',
      response_mode: 'query',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/microsoft`,
      scope: 'https://graph.microsoft.com/.default'
    });
    addFromParamIfNecessary(params);
    window.open(`${url}?${params.toString()}`, '_self');
  };

  const handleOnContinueWithSlack = () => {
    const url = 'https://slack.com/oauth/v2/authorize';
    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_SLACK_CLIENT_ID,
      user_scope: 'identity.avatar,identity.basic,identity.email',
      redirect_uri: `${process.env.REACT_APP_URL}/auth/slack`
    });
    addFromParamIfNecessary(params);
    window.open(`${url}?${params.toString()}`, '_self');
  };

  const logo = useColorModeValue(
    hive?.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive?.light_logo ? hive.light_logo : '/hives_light.png'
  );

  const loginHint = hive?.hints?.find(h => h.type === 'LOGIN');

  return (
    <LoadingWrapper statuses={[antiFlickerDelay]}>
      <Flex height="full">
        {hive ? (
          <InfoColumn title={t('login.join_hive', { hive: hive.name })} />
        ) : (
          <InfoColumn
            title={t('login.hives_pitch_title')}
            description={t('login.hives_pitch_description')}
          />
        )}
        <Flex width="full" alignItems="center" justifyContent="center">
          <Container maxW="container.xs">
            <Center py={10}>
              <Image src={logo} height="40px" />
            </Center>
            <Stack textAlign="center" spacing={6}>
              {confirmationEmailSent ? (
                <CheckYourEmail email={email} showHeading />
              ) : (
                <>
                  {loginHint && (
                    <>
                      <Heading as="h1" fontSize="3xl">
                        {loginHint
                          ? loginHint.title
                          : t('login.enter_your_email')}
                      </Heading>
                      <Box>
                        <ReactMarkdown
                          components={ChakraUIRenderer()}
                          children={
                            loginHint
                              ? loginHint.description
                              : t(
                                  'login.we_suggest_using_the_email_address_you_use_at_work'
                                )
                          }
                          linkTarget="_blank"
                          skipHtml
                        />
                      </Box>
                    </>
                  )}
                  {(!hive || hive.magic_link) && (
                    <LoginForm onSubmit={handleOnContinueWithMail} />
                  )}
                  {(!hive ||
                    (hive.magic_link &&
                      (hive.continue_with_microsoft ||
                        hive.continue_with_google ||
                        hive.continue_with_slack))) && (
                    <HStack>
                      <Divider />
                      <Text variant="muted">{t('common.or')}</Text>
                      <Divider />
                    </HStack>
                  )}
                  <Stack spacing={4}>
                    {(!hive || hive.continue_with_microsoft) && (
                      <Button
                        onClick={handleOnContinueWithMicrosoft}
                        variant="outline"
                      >
                        <HStack>
                          <Image src="/microsoft.svg" height={4} />
                          <Text>{t('button.continue_with_microsoft')}</Text>
                        </HStack>
                      </Button>
                    )}
                    {(!hive || hive.continue_with_google) && (
                      <Button
                        onClick={handleOnContinueWithGoogle}
                        variant="outline"
                      >
                        <HStack>
                          <Image src="/google.svg" height={4} />
                          <Text>{t('button.continue_with_google')}</Text>
                        </HStack>
                      </Button>
                    )}
                    {(!hive || hive.continue_with_slack) && (
                      <Button
                        onClick={handleOnContinueWithSlack}
                        variant="outline"
                      >
                        <HStack>
                          <Image src="/slack.svg" height={4} />
                          <Text>{t('button.continue_with_slack')}</Text>
                        </HStack>
                      </Button>
                    )}
                    {(!hive || hive.continue_with_linkedin) && (
                      <Button
                        onClick={handleOnContinueWithLinkedIn}
                        variant="outline"
                      >
                        <HStack>
                          <Image src="/linkedin.svg" height={4} />
                          <Text>{t('button.continue_with_linkedin')}</Text>
                        </HStack>
                      </Button>
                    )}
                    {/* {(!hive || hive.continue_with_facebook) && (
                      <Button
                        onClick={handleOnContinueWithFacebook}
                        variant="outline"
                  
                      >
                        <HStack>
                          <Image src="/facebook.svg" height={4} />
                          <Text>{t('button.continue_with_facebook')}</Text>
                        </HStack>
                      </Button>
                    )} */}
                  </Stack>
                </>
              )}
            </Stack>
          </Container>
        </Flex>
      </Flex>
    </LoadingWrapper>
  );
};

export default LoginPage;
