import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faCheck,
  faInfoCircle,
  faTimes
} from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const HiveRegisterForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid, errors }
  } = useForm({ mode: 'onChange' });

  const name = watch('name');

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Stack spacing={8}>
        <Stack spacing={4}>
          <FormControl id="email">
            <FormLabel>{t('register.your_email')}</FormLabel>
            <Input
              placeholder={t('placeholder.business_email')}
              {...register('email', {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              })}
            />
          </FormControl>
          <FormControl id="hive" isInvalid={errors.name}>
            <Flex alignItems="center" justifyContent="space-between">
              <FormLabel>{t('register.hive_name')}</FormLabel>
              <Tooltip hasArrow label={t('register.hive_tooltip')}>
                <Box mb={2}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Box>
              </Tooltip>
            </Flex>
            <InputGroup>
              <Input
                placeholder={t('placeholder.hive_name')}
                {...register('name', {
                  required: true,
                  validate: async name => {
                    if (name) {
                      try {
                        setLoading(true);
                        await api.post('/hives/registered-name', { name });
                        setLoading(false);
                      } catch (e) {
                        setLoading(false);
                        if (e.response && e.response.status === 404) {
                          return null;
                        }
                      }
                      return t('register.name_already_registered');
                    }
                  }
                })}
              />
              <InputRightElement>
                {loading ? (
                  <Spinner size="sm" />
                ) : name ? (
                  <Text variant="validation" error={errors.name}>
                    <FontAwesomeIcon icon={errors.name ? faTimes : faCheck} />
                  </Text>
                ) : null}
              </InputRightElement>
            </InputGroup>
            {errors?.name?.message && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Button
          type="submit"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          <HStack>
            <Text>{t('register.get_started')}</Text>
            <FontAwesomeIcon icon={faArrowRight} />
          </HStack>
        </Button>
      </Stack>
    </form>
  );
};

export default HiveRegisterForm;
