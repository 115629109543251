import { useTranslation } from 'react-i18next';
import {
  Table,
  Tbody,
  Td,
  Tr,
  Text,
  HStack,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import ModalWrapper from 'components/ModalWrapper';
import SpinnerSwitch from 'components/SpinnerSwitch';
import TitleForm from './TitleForm';

const FormTable = ({ workflow, onUpdateWorkflow }) => {
  const { t } = useTranslation();

  const {
    isOpen: showTitleModal,
    onOpen: openTitleModal,
    onClose: closeTitleModal
  } = useDisclosure();

  return (
    <Table variant="unstyled">
      <Tbody>
        <Tr>
          <Td pl={0}>{t('common.image')}</Td>
          <Td pr={0} textAlign="end">
            <SpinnerSwitch
              isChecked={workflow.use_card_image}
              optionKey="use_card_image"
              onChange={() => {
                onUpdateWorkflow({ use_card_image: !workflow.use_card_image });
              }}
            />
          </Td>
        </Tr>
        <Tr>
          <Td pl={0}>
            <HStack spacing={2}>
              <Button variant="link" onClick={openTitleModal}>
                <Text>
                  {workflow.title_override
                    ? workflow.title_override
                    : t('common.title')}
                </Text>
              </Button>
              <Text fontSize="sm" variant="light">
                {`(${t('template.label_editable').toLowerCase()})`}
              </Text>
            </HStack>
          </Td>
          <Td pr={0} textAlign="end">
            <HStack alignItems="center" justifyContent="flex-end">
              <FontAwesomeIcon icon={faLock} />
              <Text>{t('common.locked')}</Text>
            </HStack>
          </Td>
        </Tr>
        <Tr>
          <Td pl={0}>{t('common.description')}</Td>
          <Td pr={0} textAlign="end">
            <SpinnerSwitch
              isChecked={workflow.use_card_description}
              optionKey="use_card_description"
              onChange={() => {
                onUpdateWorkflow({
                  use_card_description: !workflow.use_card_description
                });
              }}
            />
          </Td>
        </Tr>
      </Tbody>
      <ModalWrapper
        size="md"
        title={t('common.form')}
        isOpen={showTitleModal}
        onClose={closeTitleModal}
      >
        <TitleForm
          defaultValues={{ title_override: workflow.title_override }}
          onSubmit={async values => {
            await onUpdateWorkflow(values);
            closeTitleModal();
          }}
        />
      </ModalWrapper>
    </Table>
  );
};

export default FormTable;
