import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Stack,
  Text,
  useColorModeValue,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import CheckYourEmail from 'components/CheckYourEmail';
import InfoColumn from 'features/auth/InfoColumn';
import api from 'utils/api';
import HiveRegisterForm from './HiveRegisterForm';

const HiveRegisterPage = () => {
  // https://chakra-ui.com/docs/styled-system/responsive-styles
  const [isMobile] = useMediaQuery('(max-width: 62em)');

  const [email, setEmail] = useState(null);
  const { t } = useTranslation();

  const toast = useToast();

  const registrationMutation = useMutation(
    registration => {
      api.post('/hives/register', registration);
    },
    {
      onSuccess: (_, registration) => {
        try {
          setEmail(registration.email);
        } catch (e) {
          toast({
            title: t('toast.something_went_wrong_please_try_again_later'),
            status: 'error',
            position: 'bottom-right',
            isClosable: true
          });
        }
      }
    }
  );

  const iconColor = useColorModeValue('teal.500', 'teal.200');
  const logoSrc = useColorModeValue('/hives_dark.png', '/hives_light.png');

  const handleOnRegisterHive = async registration => {
    await registrationMutation.mutateAsync(registration);
  };

  return (
    <Flex height="full">
      <InfoColumn
        title={t('login.hives_pitch_title')}
        description={t('login.hives_pitch_description')}
      />
      <Flex width="full" alignItems="center" justifyContent="center">
        <Container maxW="container.xs">
          <Stack spacing={8}>
            {isMobile && (
              <Flex>
                <Image src={logoSrc} height="32px" />
              </Flex>
            )}
            {email ? (
              <CheckYourEmail email={email} showHeading />
            ) : (
              <Stack spacing={4}>
                <Heading as="h1" fontSize="3xl">
                  {t('register.free_account')}
                </Heading>
                <Stack direction={['column', null, 'row']} spacing={4}>
                  <HStack>
                    <Text color={iconColor}>
                      <FontAwesomeIcon icon={faCheck} />
                    </Text>
                    <Text>{t('register.easy_registration')}</Text>
                  </HStack>
                  <HStack>
                    <Text color={iconColor}>
                      <FontAwesomeIcon icon={faCheck} />
                    </Text>
                    <Text>{t('register.no_credit_card_required')}</Text>
                  </HStack>
                </Stack>
                <HiveRegisterForm onSubmit={handleOnRegisterHive} />
              </Stack>
            )}
          </Stack>
        </Container>
      </Flex>
    </Flex>
  );
};

export default HiveRegisterPage;
