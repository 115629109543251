import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxOpenFull,
  faMegaphone,
  faRectangleHistory,
  faUserPlus
} from '@fortawesome/pro-regular-svg-icons';
import { hasPlanGreaterThanOrEqual, Plans } from 'features/hive/hiveUtils';

const CreateDrawer = ({
  isOpen,
  onClose,
  openCreateAnnouncementModal,
  openCreateCollectionModal,
  openCreateProjectModal,
  openInviteModal
}) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { t } = useTranslation();

  const CreateNewButton = ({ icon, text, onClick }) => (
    <VStack spacing={4}>
      <Circle
        borderWidth={1}
        fontSize="2xl"
        size={20}
        cursor="pointer"
        onClick={() => {
          onClose();
          onClick();
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </Circle>
      <Text>{text}</Text>
    </VStack>
  );

  return (
    <>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>{t('common.create')}</DrawerHeader>
          <DrawerBody>
            <SimpleGrid columns={3} spacing={4}>
              <CreateNewButton
                icon={faUserPlus}
                text={t('common.invite')}
                onClick={openInviteModal}
              />
              <CreateNewButton
                icon={faRectangleHistory}
                text={t('common.collection')}
                onClick={openCreateCollectionModal}
              />
              {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Professional) && (
                <CreateNewButton
                  icon={faBoxOpenFull}
                  text={t('common.project')}
                  onClick={openCreateProjectModal}
                />
              )}
              <CreateNewButton
                icon={faMegaphone}
                text={t('common.announcement')}
                onClick={openCreateAnnouncementModal}
              />
            </SimpleGrid>
          </DrawerBody>
          <DrawerFooter />
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CreateDrawer;
