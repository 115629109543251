import axios from 'axios';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import { useAuth } from 'providers/AuthProvider';

const providers = ['google', 'linkedin', 'microsoft', 'hives'];

const SpaAuth = () => {
  const { authenticate } = useAuth();
  const { provider } = useParams();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { mutateAsync, isLoading } = useMutation(
    payload =>
      axios.post(`${process.env.REACT_APP_API_URL}/auth/${provider}`, payload, {
        headers: {
          'Api-Key': process.env.REACT_APP_API_KEY
        }
      }),
    {
      onSuccess: async ({ data }) => {
        const state = searchParams.get('state');
        const slug = searchParams.get('slug') || localStorage.getItem('slug');
        authenticate(data.token);
        let redirect = '/join';
        if (state) {
          try {
            const result = JSON.parse(window.atob(state));
            if (
              result.from?.match(/.*\/invited\/collection\/([a-zA-Z0-9-]+)$/) ||
              result.from?.match(/.*\/shared\/collection\/([a-zA-Z0-9-]+)$/)
            ) {
              redirect = result.from;
            } else if (result.slug) {
              redirect = `/${result.slug}`;
            }
          } catch (e) {
            console.log(e);
          }
        } else if (slug) {
          redirect = `/${slug}`;
        }
        navigate(redirect);
      }
    }
  );

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      mutateAsync({ code });
    }
  }, [navigate, mutateAsync, searchParams]);

  const code = searchParams.get('code');

  return providers.includes(provider) && code && isLoading ? (
    <LoadingIndicator />
  ) : null;
};

export default SpaAuth;
