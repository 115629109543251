import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text
} from '@chakra-ui/react';
import ImageSelect from 'components/ImageSelect';
import TitleInput from 'components/TitleInput';
import DescriptionFormControl from 'components/DescriptionFormControl';

const AnnouncementForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, isOpen]);

  const values = watch();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <ImageSelect
          object={values}
          setValue={setValue}
          preloadImage={!defaultValues}
          uploadVideo
        />
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <TitleInput control={control} />
        </FormControl>
        <DescriptionFormControl
          control={control}
          setValue={text => setValue('description', text)}
        />
        <Input {...register('image')} hidden />
        <Input {...register('unsplash')} hidden />
        <HStack
          width="full"
          spacing={12}
          justifyContent="flex-end"
          alignItems="flex-start"
        >
          <Text fontSize="xs" variant="light">
            {t('home.announcement_notification_warning')}
          </Text>
          <Button
            alignSelf="flex-end"
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid}
            isLoading={isSubmitting}
          >
            {!defaultValues ? t('common.send') : t('button.save')}
          </Button>
        </HStack>
      </Stack>
    </form>
  );
};

export default AnnouncementForm;
