import axios from 'axios';
export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;

/* 
  TODO: temporarily increase the timeout from 10s to 30s.
  We should try to optimize instead. No request should ever take this long
  to complete.
*/
const api = axios.create({
  baseURL: API_URL,
  timeout: 30000,
  headers: {
    'Api-Key': API_KEY
  }
});

api.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token');
      }
    }
    return Promise.reject(error);
  }
);

export default api;
