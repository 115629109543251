import { Flex, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import BoxContainer from 'components/BoxContainer';

const FilterBox = ({ selected, text, onClick }) => {
  return (
    <BoxContainer
      boxShadow={selected ? 'outline' : null}
      onClick={onClick}
      width="full"
    >
      <Flex alignItems="center" justifyContent="space-between" py={0}>
        <Text fontSize="sm" fontWeight="bold">
          {text}
        </Text>
        <Text
          opacity={selected ? 0.8 : 0.2}
          color={selected ? 'blue.400' : null}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </Text>
      </Flex>
    </BoxContainer>
  );
};

export default FilterBox;
