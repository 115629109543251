import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import LabeledBox from 'components/LabeledBox';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import { getCollectingPlural } from 'features/collection/collectionUtils';
import api from 'utils/api';
import StatisticSkeleton from './StatisticSkeleton';

const NumberOfContributions = () => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const { status, data, isSuccess } = useQuery(
    'number-of-contributions',
    async () => {
      const { data } = await api.get(
        `/metrics/number-of-contributions?slug=${slug}`
      );
      return data;
    }
  );

  return (
    <LabeledBox label={t('dashboard.number_of_contributions')}>
      <LoadingWrapper
        indicator={
          <LoadingIndicator>
            <Stack py={2} spacing={4}>
              {[...Array(3)].map((_, index) => (
                <StatisticSkeleton key={index} />
              ))}
            </Stack>
          </LoadingIndicator>
        }
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.contributions').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack>
            {data.map((row, index) => (
              <HStack key={index}>
                <Text>{getCollectingPlural(row.type)}</Text>
                <Spacer />
                <Text fontWeight="bold">{row.count}</Text>
              </HStack>
            ))}
          </Stack>
        ) : null}
      </LoadingWrapper>
    </LabeledBox>
  );
};

export default NumberOfContributions;
