import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid
} from '@chakra-ui/react';
import api from 'utils/api';

const NameModal = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { data: me } = useQuery('me', { enabled: false });
  const updateMeMutation = useMutation(
    payload => api.patch('/users/me', payload),
    {
      onSuccess: ({ data: me }) => {
        queryClient.setQueryData('me', me);
      },
      timeout: 180000
    }
  );

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  });

  const handleOnSubmitName = async ({ first_name, last_name }) => {
    try {
      await updateMeMutation.mutateAsync({ first_name, last_name });
    } catch (e) {
      console.log(e);
    }
  };

  if (
    me &&
    (me.first_name === null ||
      me.first_name === '' ||
      me.last_name === null ||
      me.last_name === '')
  ) {
    return (
      <Modal isOpen={true} size="lg">
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleOnSubmitName)}>
            <ModalHeader>{t('common.name')}</ModalHeader>
            <ModalBody>
              <SimpleGrid columns={[1, null, 2]} spacing={4}>
                <FormControl id="firstName">
                  <FormLabel>{t('common.first_name')}</FormLabel>
                  <Input
                    placeholder={t('placeholder.enter_first_name')}
                    {...register('first_name', {
                      required: true
                    })}
                  />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>{t('common.last_name')}</FormLabel>
                  <Input
                    placeholder={t('placeholder.enter_last_name')}
                    {...register('last_name', {
                      required: true
                    })}
                  />
                </FormControl>
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="teal"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                {t('button.save')}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    );
  }
  return null;
};

export default NameModal;
