import { useIsMutating, useQueryClient } from 'react-query';
import { Switch, Spinner, HStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const SpinnerSwitch = ({ isChecked, onChange, optionKey }) => {
  const [submittingOption, setSubmittingOption] = useState('');
  const isMutatating = useIsMutating();

  const queryClient = useQueryClient();

  useEffect(() => {
    const mutations = queryClient?.getMutationCache()?.mutations;
    const filteredMutations = mutations
      .filter(mutation => {
        return mutation?.state?.status !== 'success';
      })
      ?.flatMap(mutation =>
        !!!mutation?.state?.variables
          ? {}
          : Object?.keys(mutation?.state?.variables)
      );
    if (filteredMutations) {
      setSubmittingOption(filteredMutations[0]);
    }
  }, [isMutatating, queryClient]);

  return (
    <HStack ml={4} spacing={2} justifyContent="flex-end">
      {submittingOption === optionKey && <Spinner color="gray" size="sm" />}
      <Switch
        isChecked={isChecked}
        isDisabled={submittingOption === optionKey}
        onChange={onChange}
      />
    </HStack>
  );
};

export default SpinnerSwitch;
