import { useEffect, useMemo, useRef } from 'react';
import { useSortBy, useTable } from 'react-table';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  HStack,
  Table,
  Tbody,
  TableContainer,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Heading,
  useColorModeValue,
  useColorMode,
  Tooltip
} from '@chakra-ui/react';
import IconTooltip from 'components/tooltips/IconTooltip';
import ModalWrapper from 'components/ModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortDown,
  faSortUp
} from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@emotion/react';

const ScorersModal = ({
  scores,
  parameters,
  focusedScore = { scoreId: null, paramId: null },
  isOpen,
  onClose,
  isAdminOrCollectionManager = false
}) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const focusHighlightColor =
    colorMode === 'light' ? theme.colors.gray['200'] : theme.colors.gray['600'];

  const ref = useRef();

  const data = useMemo(() => {
    const temp = [];
    scores?.forEach(score => {
      let row = {
        id: score.id,
        scorer: score.creator,
        comment: score.comment ? score.comment : '-'
      };
      parameters?.forEach((param, paramIndex) => {
        row[param.label] = score.points[paramIndex].value;
      });
      temp.push(row);
    });
    return temp;
  }, [scores, parameters]);

  const descriptionColumn = (param, index) => ({
    Header: <IconTooltip label={param.description} />,
    accessor: `${index}.description`,
    textAlign: 'right',
    disableSortBy: true,
    pr: 1,
    pl: 1,
    maxWidth: 1
  });

  const scoreColumn = (param, _) => ({
    Header: param.label,
    accessor: param.label,
    textAlign: 'center',
    sortType: 'alphanumeric',
    pr: 8,
    pl: 1
  });

  const columns = useMemo(() => {
    let temp = [];
    if (isAdminOrCollectionManager) {
      parameters?.forEach((param, index) => {
        if (param.description) {
          temp.push(descriptionColumn(param, index));
        }
        temp.push(scoreColumn(param, index));
      });
      temp.push(
        scoreColumn({ label: 'comment', sortType: 'string' }, parameters.length)
      );
    }
    return [
      {
        Header: t('common.name'),
        accessor: 'scorer',
        textAlign: 'left',
        sortType: 'string',
        pr: 8,
        pl: 1
      },
      ...temp
    ];
  }, [t, parameters, isAdminOrCollectionManager]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ data, columns }, useSortBy);

  useEffect(() => {
    if (isOpen && focusedScore) {
      if (focusedScore?.scoreId) {
        setTimeout(() => {
          const focus = document.getElementById('focusedScore');
          focus?.scrollIntoView();
        }, 70);
      }
    }
  }, [isOpen, focusedScore, rows]);

  return (
    <ModalWrapper
      headerContent={
        <HStack>
          <Text as="span">{t('card.scored_by')}</Text>
          <Text variant="muted" fontSize="md">{`(${scores.length})`}</Text>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
      width="full"
    >
      <TableContainer mb={4}>
        <Table
          {...getTableProps()}
          bg={useColorModeValue('white', 'gray.700')}
          variant="simple"
        >
          <Thead>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    textAlign={column.textAlign}
                    whiteSpace="nowrap"
                    pl={column?.pl}
                    pr={column?.pr}
                  >
                    <HStack display="inline-flex" spacing={2}>
                      <Tooltip
                        isDisabled={column?.disableSortBy === true}
                        label={column.render('Header')}
                        placement="top"
                        hasArrow
                      >
                        <Text
                          label={column.render('Header')}
                          userSelect="none"
                          maxW={['15ch', null, '25ch']}
                          textOverflow="ellipsis"
                          noOfLines={1}
                          display="block"
                          ref={ref}
                        >
                          {column.render('Header')}
                        </Text>
                      </Tooltip>
                      {!column.disableSortBy && (
                        <Box as="span">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <FontAwesomeIcon
                                icon={faSortDown}
                                aria-label="sorted descending"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faSortUp}
                                aria-label="sorted ascending"
                              />
                            )
                          ) : (
                            <FontAwesomeIcon icon={faSort} />
                          )}
                        </Box>
                      )}
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  style={
                    row.original.id === focusedScore.scoreId
                      ? {
                          backgroundColor: focusHighlightColor,
                          borderRadius: '20%'
                        }
                      : null
                  }
                >
                  {row.cells.map(cell => (
                    <Td
                      {...cell.getCellProps()}
                      textAlign={cell.column.textAlign}
                      pl={cell.column?.pl}
                      pr={cell.column?.pr}
                      py={4}
                    >
                      {cell.column.id === 'scorer' ? (
                        <HStack
                          key={cell.value.id}
                          as={Link}
                          to={`/${slug}/member/${cell.value?.id}`}
                          maxWidth="fit-content"
                          spacing={4}
                          id={
                            row.original.id === focusedScore.scoreId
                              ? 'focusedScore'
                              : null
                          }
                        >
                          <Avatar
                            size="sm"
                            cursor="pointer"
                            src={
                              cell.value.avatar
                                ? cell.value.avatar
                                : cell.value?.third_party_avatar
                                ? cell.value.third_party_avatar
                                : null
                            }
                            name={cell.value?.full_name}
                          />
                          <Heading
                            maxW={['15ch', null, '25ch']}
                            fontSize="sm"
                            whiteSpace="break-spaces"
                          >
                            {cell.value.full_name}
                          </Heading>
                        </HStack>
                      ) : (
                        <Text
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxW={['15ch', null, '25ch']}
                        >
                          {cell.render('Cell')}
                        </Text>
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </ModalWrapper>
  );
};

export default ScorersModal;
