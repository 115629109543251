import {
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const ImageDetailed = ({ src, isOpen, onClose }) => (
  <>
    <Modal isOpen={isOpen} onClose={onClose} allowPinchZoom>
      <ModalOverlay />
      <ModalContent maxW="container.lg">
        <Image src={src} />
      </ModalContent>
    </Modal>
    {isOpen && (
      <Flex
        position="fixed"
        cursor="pointer"
        top={2}
        right={2}
        padding={4}
        fontSize="2xl"
        onClick={onClose}
        zIndex={9999}
      >
        <FontAwesomeIcon icon={faTimes} color="white" />
      </Flex>
    )}
  </>
);

export default ImageDetailed;
