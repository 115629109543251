import axios from 'axios';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Image,
  Input,
  SimpleGrid,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import ModalWrapper from 'components/ModalWrapper';

const UnsplashModal = ({ isOpen, onClose, onImage }) => {
  const [query, setQuery] = useState('');
  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const {
    data: images,
    refetch,
    isFetching
  } = useQuery(
    'images',
    async () => {
      const response = await axios.get(
        `https://api.unsplash.com/search/photos?client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`,
        {
          params: { query }
        }
      );
      return response.data;
    },
    {
      enabled: false
    }
  );

  useEffect(() => {
    return () => {
      if (!isOpen) {
        queryClient.setQueryData('images', null);
      }
    };
  }, [isOpen, queryClient]);

  const handleOnUnsplashImage = async image => {
    onImage(image.urls.regular);
    await axios.get(
      `${image.links.download_location}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
    );
    onClose();
  };

  const handleOnSubmitQuery = () => {
    queryClient.setQueryData('images', null);
    refetch();
  };

  return (
    <ModalWrapper
      headerContent={
        <Trans
          t={t}
          i18nKey="unsplash.title"
          components={[
            <Text />,
            <Text
              as="a"
              href="https://www.unsplash.com"
              target="_blank"
              style={{ textDecoration: 'underline' }}
            />
          ]}
        />
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <HStack alignItems="flex-start">
        <FormControl>
          <Input
            autoFocus
            onChange={e => {
              setQuery(e.target.value);
            }}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                if (query.length > 0) {
                  handleOnSubmitQuery();
                }
              }
            }}
          />
          <FormHelperText>{t('unsplash.helper_text')}</FormHelperText>
        </FormControl>
        <Button
          isDisabled={query.length === 0 || isFetching}
          isLoading={isFetching}
          w={12}
          onClick={handleOnSubmitQuery}
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </HStack>
      <SimpleGrid columns={2} spacing={4} my={4}>
        {images?.results.map(image => (
          <Box position="relative" key={image.id}>
            <Image
              src={image.urls.thumb}
              height="120px"
              width="full"
              objectFit="cover"
              rounded="lg"
              cursor="pointer"
              onClick={() => handleOnUnsplashImage(image)}
            />
            <HStack
              as="a"
              href={`${image.user.links.html}?utm_source=hives&utm_medium=referral`}
              target="_blank"
              bg="blackAlpha.500"
              width="full"
              position="absolute"
              bottom={0}
              borderBottomRadius="lg"
              px={2}
              py={1}
              _hover={{ bg: 'blackAlpha.700' }}
            >
              <Text color="white" fontSize="xs" fontWeight="bold">
                {image.user.name}
              </Text>
            </HStack>
          </Box>
        ))}
      </SimpleGrid>
    </ModalWrapper>
  );
};

export default UnsplashModal;
