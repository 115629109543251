import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Center, Container, Spinner } from '@chakra-ui/react';
import api from 'utils/api';

const HiveRedirect = ({ endpoint }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function fetchSlug() {
      try {
        const {
          data: { slug }
        } = await api.get(endpoint.replace(':id', id));
        navigate(`/${slug}${pathname}`, { replace: true });
      } catch (e) {
        navigate('/', { replace: true });
      }
    }
    if (id) {
      fetchSlug();
    }
  }, [id, endpoint, navigate, pathname]);

  return (
    <Container marginY={8}>
      <Center>
        <Spinner />
      </Center>
    </Container>
  );
};

export default HiveRedirect;
