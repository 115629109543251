export const getCollectionShareUrl = (slug, collection, filter) => {
  return `${process.env.REACT_APP_URL}/${slug}${
    collection?.code ? '/shared' : ''
  }/collection/${collection?.code ? collection?.code : collection?.id}${
    filter?.card ? `?card=${filter.card}` : ''
  }${filter?.step ? `?step=${filter.step}` : ''}`;
};

export const getCardShareUrl = (slug, card) => {
  return getCollectionShareUrl(slug, card?.collection, {
    card: card?.id
  });
};
