import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { Heading, SimpleGrid, Stack } from '@chakra-ui/react';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import InfiniteScrollHelper from 'components/InfiniteScrollHelper';
import NewItem from 'components/NewItem';
import api from 'utils/api';
import ProjectListItem from './ProjectListItem';

const ProjectList = ({
  channelId,
  connected = false,
  active = false,
  showCreate = false,
  headerText = null,
  onClickCreate
}) => {
  const { t } = useTranslation();

  const {
    status,
    data: projects,
    isSuccess,
    fetchNextPage,
    isFetching,
    hasNextPage
  } = useInfiniteQuery(
    ['projects', channelId, active, connected],
    async ({ pageParam = 0 }) => {
      if (connected) {
        const { data } = await api.get(
          `/projects?connected=true&active=${active}&offset=${pageParam}&limit=20`
        );
        return data;
      }
      const { data } = await api.get(
        `/projects?active=${active}&channel=${channelId}&offset=${pageParam}&limit=20`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage, _) => {
        if (lastPage.next) {
          let url = new URL(lastPage.next);
          let offset = url.searchParams.get('offset');
          return offset;
        }
        return null;
      }
    }
  );

  return (
    <Stack spacing={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.projects').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <>
            {headerText && projects?.pages[0]?.count > 0 && (
              <Heading fontSize="2xl">{headerText}</Heading>
            )}
            <InfiniteScrollHelper
              hasMore={!isFetching && hasNextPage}
              loadMore={fetchNextPage}
            >
              <SimpleGrid columns={[1, null, 2]} spacing={8}>
                {showCreate && (
                  <NewItem
                    text={t('button.new_project')}
                    onClick={onClickCreate}
                  />
                )}
                {projects?.pages?.map(page =>
                  page.results.map(project => (
                    <ProjectListItem key={project.id} project={project} />
                  ))
                )}
              </SimpleGrid>
              {isFetching && <LoadingIndicator marginY={10} />}
            </InfiniteScrollHelper>
          </>
        ) : null}
      </LoadingWrapper>
    </Stack>
  );
};

export default ProjectList;
