import {
  Button,
  ButtonGroup,
  Flex,
  HStack,
  SimpleGrid,
  Stack
} from '@chakra-ui/react';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import AnswerListItem from './AnswerListItem';

const SurveyTextAnswerCarousel = ({
  onClickLeft,
  onClickRight,
  onClickAnswer,
  answers,
  onClickSeeAll,
  progressText
}) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={8}>
      <SimpleGrid spacing={4} columns={[1, 2, 3]}>
        {answers?.results.map(answer => (
          <AnswerListItem
            key={answer.id}
            answer={answer}
            onClick={() => onClickAnswer(answer)}
          />
        ))}
      </SimpleGrid>
      {answers?.count > 0 && (
        <Flex alignItems="center" justifyContent="flex-end">
          <HStack spacing={8}>
            {progressText}
            <Button variant="link" onClick={onClickSeeAll}>
              {t('button.see_all')}
            </Button>
            <ButtonGroup variant="outline">
              <Button isDisabled={!answers.previous} onClick={onClickRight}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <Button isDisabled={!answers.next} onClick={onClickLeft}>
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </ButtonGroup>
          </HStack>
        </Flex>
      )}
    </Stack>
  );
};

export default SurveyTextAnswerCarousel;
