import { useParams } from 'react-router-dom';
import { Heading } from '@chakra-ui/react';
import ItemWrapper from 'components/ItemWrapper';
import MemberAvatar from 'components/MemberAvatar';

const MemberListItem = ({ member }) => {
  const { slug } = useParams();
  return (
    <ItemWrapper
      to={`/${slug}/member/${member.id}`}
      alignItems="center"
      spacing={4}
    >
      <MemberAvatar link={false} member={member} size="xl" />
      <Heading fontSize="md" noOfLines={2}>
        {member.full_name}
      </Heading>
    </ItemWrapper>
  );
};

export default MemberListItem;
