import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import LabeledBox from 'components/LabeledBox';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingWrapper';
import api from 'utils/api';
import StatisticSkeleton from './StatisticSkeleton';

const ContributionList = ({ query, title }) => {
  const { slug } = useParams();
  const { t } = useTranslation();

  const { status, data, isSuccess } = useQuery([query, slug], async () => {
    const { data } = await api.get(`/metrics/${query}?slug=${slug}`);
    return data;
  });

  return (
    <LoadingWrapper
      indicator={
        <LoadingIndicator>
          <LabeledBox label={title} asSkeleton>
            <Stack py={2} spacing={4}>
              {[...Array(3)].map((_, index) => (
                <StatisticSkeleton key={index} />
              ))}
            </Stack>
          </LabeledBox>
        </LoadingIndicator>
      }
      statuses={[status]}
      errorMessages={[
        t('common.could_not_fetch_data_please_try_again_later', {
          data: t('common.contributions').toLowerCase()
        })
      ]}
    >
      {isSuccess && data.length > 0 && (
        <LabeledBox label={title}>
          <Stack>
            {data.map((data, index) => (
              <HStack key={index}>
                <Text>{data.name}</Text>
                <Spacer />
                <Text fontWeight="bold">{data.count}</Text>
              </HStack>
            ))}
          </Stack>
        </LabeledBox>
      )}
    </LoadingWrapper>
  );
};

export default ContributionList;
