import { AvatarGroup, HStack, Stack, Text } from '@chakra-ui/react';
import Description from 'components/Description';
import MemberAvatar from 'components/MemberAvatar';
import { format } from 'date-fns';

const AnswerDetailed = ({ answer }) => {
  return (
    <Stack spacing={4} py={4}>
      <HStack alignItems="flex-start">
        <AvatarGroup size="sm" alignSelf="center">
          <MemberAvatar
            member={answer.creator}
            zIndex={-1}
            size="sm"
            src={
              answer.creator?.avatar
                ? answer.creator.avatar
                : answer.creator?.third_party_avatar
                ? answer.creator.third_party_avatar
                : null
            }
          />
        </AvatarGroup>
        <Stack spacing={0} justifyContent="flex-start">
          <Text fontSize="sm" fontWeight="bold">
            {answer.creator?.first_name}
          </Text>
          <Text fontSize="xs">
            {format(new Date(answer.created_at), 'dd MMM yyyy')}
          </Text>
        </Stack>
      </HStack>
      <Description>{answer ? answer.answer : ''}</Description>
    </Stack>
  );
};

export default AnswerDetailed;
