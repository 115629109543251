import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack
} from '@chakra-ui/react';

/*
  TODO: improve validation and error handling
*/
const StepReminderForm = ({ defaultValues, isOpen, onDelete, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="days">
          <FormLabel>{t('common.days')}</FormLabel>
          <NumberInput>
            <NumberInputField
              {...register('reminder_days', {
                required: true,
                min: 1,
                max: 100,
                valueAsNumber: true
              })}
            />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <Flex
          alignItems="center"
          justifyContent={
            defaultValues?.reminder_days ? 'space-between' : 'flex-end'
          }
        >
          {defaultValues?.reminder_days && (
            <Button onClick={onDelete} colorScheme="red">
              {t('common.delete')}
            </Button>
          )}
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default StepReminderForm;
