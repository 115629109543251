import i18next from 'i18next';

export const getActiveStepFilters = filter => {
  const noOfMainFilters = Object.keys(filter).filter(key => {
    switch (key) {
      case 'assignee':
        return true;
      default:
        return false;
    }
  }).length;
  const noOfOptionFilters = filter?.options
    ? Object.values(filter?.options).length
    : 0;
  const noOfActiveFilters = noOfMainFilters + noOfOptionFilters;
  return noOfActiveFilters;
};

export const isCurrentStep = (a, b) => {
  return a === b;
};

export const getStep = (collection, filter) =>
  collection?.workflow.steps.find(step => step.id === parseInt(filter.step));

export const getStateLabel = (collection, filter) => {
  if (collection && filter) {
    if (!filter.state && !filter.step) {
      return i18next.t('common.all_steps');
    }
    if (filter.state === 'START') {
      return i18next.t('common.inbox');
    }
    if (filter.state === 'END') {
      return 'END';
    }
    if (filter.state === 'ARCHIVED') {
      return i18next.t('common.archived');
    }
    return getStep(collection, filter)?.name;
  }
  return null;
};

export const getStepColor = (collection, filter) => {
  return getStep(collection, filter)?.color;
};

export const getWorkflowExplainer = collectionType => {
  switch (collectionType) {
    case 'FEEDBACK':
      return i18next.t('workflow.workflow_selection_explainer_feedback');
    case 'IDEA':
      return i18next.t('workflow.workflow_selection_explainer_idea');
    case 'INSIGHT':
      return i18next.t('workflow.workflow_selection_explainer_insight');
    case 'POST':
      return i18next.t('workflow.workflow_selection_explainer_post');
    default:
      return i18next.t('workflow.workflow_selection_explainer_idea');
  }
};

export const getFieldTypeLabel = field => {
  switch (field?.type) {
    case 'SINGLE_SELECT':
      return i18next.t('common.single_select_field');
    case 'MULTI_SELECT':
      return field.many
        ? i18next.t('common.vote')
        : i18next.t('common.multi_select_field');
    case 'RATING':
      return i18next.t('common.rating');
    default:
      return i18next.t('common.text_field');
  }
};

export const getContributeButtonLabel = workflow => {
  return workflow?.contribute_button_label
    ? workflow.contribute_button_label
    : `+ ${i18next.t('common.contribute')}`;
};
