import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Outlet, useParams } from 'react-router-dom';
import { Alert, Container, Heading, SimpleGrid, Stack } from '@chakra-ui/react';
import {
  faCircleInfo,
  faPencilRuler,
  faRectangleHistoryCirclePlus,
  faShareAlt,
  faUsers
} from '@fortawesome/pro-regular-svg-icons';
import BackButton from 'components/BackButton';
import AdminListItem from './AdminListItem';
import { Plans, hasPlanGreaterThanOrEqual } from 'features/hive/hiveUtils';
import LoadingWrapper from 'components/LoadingWrapper';

const AdminPage = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const { data: profile, status, isSuccess } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.score').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          profile?.is_admin ? (
            <Stack spacing={8}>
              <Heading fontSize="2xl">{t('common.admin')}</Heading>
              {window.location.pathname === `/${slug}/admin` ? (
                <SimpleGrid columns={[1, 2, 3, 4]} spacing={8}>
                  <AdminListItem
                    icon={faUsers}
                    text={t('common.users')}
                    to={`/${slug}/admin/users`}
                    tooltip={t('admin.users_tooltip')}
                  />
                  {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Starter) && (
                    <AdminListItem
                      icon={faCircleInfo}
                      text={t('common.hints')}
                      to={`/${slug}/admin/hints`}
                      tooltip={t('admin.hints_tooltip')}
                    />
                  )}
                  <AdminListItem
                    icon={faRectangleHistoryCirclePlus}
                    text={t('common.collection_workflows')}
                    to={`/${slug}/workflows`}
                    tooltip={t('admin.collection_workflows_tooltip')}
                  />
                  {hasPlanGreaterThanOrEqual(
                    hive?.plan,
                    Plans.Professional
                  ) && (
                    <AdminListItem
                      icon={faPencilRuler}
                      text={t('common.project_templates')}
                      to={`/${slug}/project/templates`}
                      tooltip={t('admin.project_templates_tooltip')}
                    />
                  )}
                  <AdminListItem
                    icon={faShareAlt}
                    text={t('common.hive')}
                    to={`/${slug}/admin/hive`}
                    tooltip={t('admin.hive_tooltip')}
                  />
                </SimpleGrid>
              ) : (
                <BackButton />
              )}
              <Outlet />
            </Stack>
          ) : (
            <Alert colorScheme="red">
              {t('common.you_cant_access_this_page')}
            </Alert>
          )
        ) : null}
      </LoadingWrapper>
    </Container>
  );
};

export default AdminPage;
