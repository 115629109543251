import { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(null);

  const authenticate = token => {
    localStorage.setItem('token', token);
    setAuthenticated(true);
  };

  const logout = async () => {
    localStorage.removeItem('token');
    setAuthenticated(false);
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticate,
        authenticated,
        logout
      }}
    >
      {authenticated === null ? null : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
