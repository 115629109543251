import { Center, Text } from '@chakra-ui/react';

const NotificationBadge = ({ unreadNotifications, position = 'absolute' }) => {
  return unreadNotifications ? (
    <Center
      position={position}
      bg="red.500"
      color="white"
      fontSize="xs"
      size={4}
      top={position === 'static' ? null : -1}
      right={position === 'static' ? null : -1}
      px="0.5ch"
      minWidth="18px"
      minHeight="16px"
      rounded="full"
    >
      <Text>{unreadNotifications > 9 ? '9+' : unreadNotifications}</Text>
    </Center>
  ) : null;
};

export default NotificationBadge;
