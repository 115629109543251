import Description from 'components/Description';
import ModalWrapper from 'components/ModalWrapper';

const ReadMoreModal = ({ title, description, isOpen, onClose }) => (
  <ModalWrapper
    title={title}
    titleProps={{ fontSize: '2xl', noOfLines: 99, maxWidth: '90%' }}
    size="2xl"
    isOpen={isOpen}
    onClose={onClose}
    pb={8}
  >
    <Description>{description}</Description>
  </ModalWrapper>
);

export default ReadMoreModal;
