import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import LoadingWrapper from 'components/LoadingWrapper';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Tag,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import {
  faChevronLeft,
  faEdit,
  faTag
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from 'utils/api';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { useUi } from 'providers/UiProvider';
import { cardFilterToArray } from 'features/card/cardUtils';
import { useParams } from 'react-router-dom';

const TagPopover = ({ cardId, cardTags, collectionId }) => {
  const {
    isOpen: showDeleteTagModal,
    onOpen: openDeleteTagModal,
    onClose: closeDeleteTagModal
  } = useDisclosure();

  const { slug } = useParams();

  const [selectedTag, setSelectedTag] = useState(null);
  const [showCreateTag, setShowCreateTag] = useState(false);
  const [showUpdateTag, setShowUpdateTag] = useState(false);

  const { t } = useTranslation();
  const { filter } = useUi();

  const queryClient = useQueryClient();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  const {
    data: tags,
    isSuccess,
    status,
    refetch
  } = useQuery(
    'tags',
    async () => {
      const { data } = await api.get(`/tags?collection=${collectionId}`);
      return data;
    },
    {
      enabled: false
    }
  );

  const createTagMutation = useMutation(payload => api.post('/tags', payload), {
    onSuccess: ({ data: tag }) => {
      queryClient.setQueryData('tags', [tag, ...tags]);
      setShowCreateTag(false);
      reset();
    }
  });

  const deleteTagMutation = useMutation(
    () => api.delete(`/tags/${selectedTag.id}`),
    {
      onSuccess: () => {
        queryClient.setQueryData(
          'tags',
          tags.filter(t => t.id !== selectedTag.id)
        );
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        if (cards) {
          const pages = cards.pages.map(page => ({
            ...page,
            results: page.results.map(c => ({
              ...c,
              tags: c.tags.filter(t => t.id !== selectedTag.id)
            }))
          }));
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
        closeDeleteTagModal();
      }
    }
  );

  const updateTagMutation = useMutation(
    payload => api.patch(`/tags/${selectedTag.id}`, payload),
    {
      onSuccess: ({ data: tag }) => {
        queryClient.setQueryData(
          'tags',
          tags.map(t => (t.id === tag.id ? tag : t))
        );
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        if (cards) {
          const pages = cards.pages.map(page => ({
            ...page,
            results: page.results.map(c => ({
              ...c,
              tags: c.tags.map(t => (t.id === tag.id ? tag : t))
            }))
          }));
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
        setShowUpdateTag(false);
        reset();
      }
    }
  );

  const changeTagMutation = useMutation(
    payload => api.patch(`/cards/${cardId}?hive=${slug}`, payload),
    {
      onSuccess: ({ data: card }) => {
        const cards = queryClient.getQueryData([
          'cards',
          { hive: slug },
          ...cardFilterToArray({ ...filter })
        ]);
        if (cards) {
          const pages = cards.pages.map(page => ({
            ...page,
            results: page.results.map(c => (c.id === card.id ? card : c))
          }));
          queryClient.setQueryData(
            ['cards', { hive: slug }, ...cardFilterToArray({ ...filter })],
            {
              ...cards,
              pages
            }
          );
        }
      }
    }
  );

  const handleOnCreateTag = async ({ name }) => {
    if (collectionId) {
      await createTagMutation.mutateAsync({
        name,
        collection: collectionId
      });
    }
  };

  const handleOnDeleteTag = async ({ name }) => {
    await deleteTagMutation.mutateAsync();
  };

  const handleOnUpdateTag = async ({ name }) => {
    await updateTagMutation.mutateAsync({ name });
  };

  const handleOnChangeTag = async (tagId, checked) => {
    let tags = [];
    tags = cardTags.map(t => t.id);
    if (checked) {
      tags.push(tagId);
    } else {
      tags = tags.filter(id => id !== tagId);
    }
    await changeTagMutation.mutateAsync({ tags });
  };

  return (
    <Box position="relative">
      <Popover
        onOpen={() => {
          setShowCreateTag(false);
          setShowUpdateTag(false);
          refetch();
          reset();
        }}
        placement="top-start"
      >
        <PopoverTrigger>
          <Flex>
            {cardTags.length > 0 ? (
              cardTags.map(tag => (
                <Button key={tag.id} variant="unstyled" size="xs">
                  <Tag key={tag.id} mb={1} mr={1}>
                    {tag.name}
                  </Tag>
                </Button>
              ))
            ) : (
              <Button variant="outline" size="xs" mb={1} mr={1}>
                <HStack>
                  <FontAwesomeIcon icon={faTag} />
                  <Text>{t('button.add_tag')}</Text>
                </HStack>
              </Button>
            )}
          </Flex>
        </PopoverTrigger>
        <PopoverContent padding={4}>
          <PopoverHeader fontWeight="bold" border="0">
            <HStack>
              {showCreateTag && (
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setShowCreateTag(false);
                  }}
                  size="sm"
                >
                  <HStack>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </HStack>
                </Button>
              )}
              <Text>
                {showCreateTag ? t('button.create_tag') : t('common.tags')}
              </Text>
            </HStack>
          </PopoverHeader>
          <PopoverCloseButton />
          <PopoverBody>
            {showUpdateTag ? (
              <form onSubmit={handleSubmit(handleOnUpdateTag)}>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>{t('common.name')}</FormLabel>
                    <Input
                      defaultValue={selectedTag.name}
                      {...register('name', { required: true })}
                      autoFocus
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    colorScheme="teal"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                  >
                    {t('button.save')}
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={openDeleteTagModal}
                  >
                    {t('common.delete')}
                  </Button>
                </Stack>
              </form>
            ) : showCreateTag ? (
              <form onSubmit={handleSubmit(handleOnCreateTag)}>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>{t('common.name')}</FormLabel>
                    <Input
                      {...register('name', { required: true })}
                      autoFocus
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    colorScheme="teal"
                    isDisabled={!isValid}
                    isLoading={isSubmitting}
                  >
                    {t('common.create')}
                  </Button>
                </Stack>
              </form>
            ) : (
              <LoadingWrapper
                statuses={[status]}
                errorMessages={[
                  t('common.could_not_fetch_data_please_try_again_later', {
                    data: t('common.tags').toLowerCase()
                  })
                ]}
              >
                {isSuccess && (
                  <Stack spacing={4}>
                    {tags.map(tag => (
                      <HStack key={tag.id}>
                        <Checkbox
                          key={tag.id}
                          defaultChecked={cardTags.some(t => t.id === tag.id)}
                          onChange={e =>
                            handleOnChangeTag(tag.id, e.target.checked)
                          }
                        />
                        <HStack
                          width="full"
                          spacing={4}
                          justifyContent="space-between"
                        >
                          <Text>{tag.name}</Text>
                          <Button
                            variant="link"
                            onClick={() => {
                              setSelectedTag(tag);
                              setShowUpdateTag(true);
                            }}
                          >
                            <Text variant="light">
                              <FontAwesomeIcon icon={faEdit} />
                            </Text>
                          </Button>
                        </HStack>

                        {/* <Button
                        variant="link"
                        onClick={() => {
                          setSelectedTag(tag);
                          setShowUpdateTag(true);
                        }}
                      >
                        {tag.name}
                      </Button> */}
                      </HStack>
                    ))}
                    <Button
                      autoFocus
                      size="sm"
                      variant="outline"
                      onClick={() => setShowCreateTag(true)}
                    >
                      <Text>{t('button.create_tag')}</Text>
                    </Button>
                  </Stack>
                )}
              </LoadingWrapper>
            )}
          </PopoverBody>
        </PopoverContent>
        <ConfirmationModal
          deleteText={t(
            'confirmation.this_data_will_be_permanently_removed_and_cannot_be_restored',
            { data: t('common.tag').toLowerCase() }
          )}
          isOpen={showDeleteTagModal}
          onClose={closeDeleteTagModal}
          onDelete={handleOnDeleteTag}
        />
      </Popover>
    </Box>
  );
};

export default TagPopover;
