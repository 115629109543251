import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Box,
  HStack,
  Spacer,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  Tooltip as ChakraTooltip
} from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import CardDrawer from 'features/card/CardDrawer';
import {
  getCollectingPlural,
  getCollectingSingular
} from 'features/collection/collectionUtils';

const StepDistributionChart = ({ collection, steps, workflow }) => {
  const {
    isOpen: showCardDrawer,
    onOpen: openCardDrawer,
    onClose: closeCardDrawer
  } = useDisclosure();

  const [hover, setHover] = useState(null);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selectedStepLabel, setSelectedStepLabel] = useState('');

  const theme = useTheme();
  const { colorMode } = useColorMode();
  const { t } = useTranslation();

  const handleOnHover = (_, index) => {
    setHover(index);
  };

  const handleOnStepClick = step => {
    setSelectedStepId(step === null ? -1 : step.id);
    setSelectedStepLabel(step === null ? null : step.name);
    openCardDrawer();
  };

  return (
    <Stack>
      <Box width="100%" height="300px">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={steps}
              innerRadius={80}
              outerRadius={100}
              paddingAngle={5}
              dataKey="count"
              activeIndex={hover}
              onClick={handleOnStepClick}
              onMouseEnter={handleOnHover}
              onMouseLeave={() => handleOnHover(null)}
            >
              {steps.map((step, index) => (
                <Cell
                  key={index}
                  fill={
                    theme.colors[step.color]?.[
                      colorMode === 'light' ? '200' : '500'
                    ]
                  }
                  strokeWidth={hover === index ? 10 : null}
                  stroke={
                    hover === index
                      ? theme.colors[step.color]?.[
                          colorMode === 'light' ? '200' : '500'
                        ]
                      : null
                  }
                />
              ))}
            </Pie>
            {collection && (
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    return (
                      <Stack
                        bg={colorMode === 'light' ? 'gray.100' : 'gray.700'}
                        p={4}
                      >
                        <Text fontWeight="bold" noOfLines={1}>
                          {`${payload[0].payload.count}  ${
                            payload[0].payload.count === 1
                              ? getCollectingSingular(
                                  collection.type
                                ).toLowerCase()
                              : getCollectingPlural(
                                  collection.type
                                ).toLowerCase()
                          }`}
                        </Text>
                        <Text
                          opacity="0.4"
                          fontWeight="light"
                          fontStyle="italic"
                        >
                          {t('common.click_to_open')}
                        </Text>
                      </Stack>
                    );
                  }
                }}
                cursor={{
                  strokeDasharray: '3 3',
                  stroke:
                    colorMode === 'light'
                      ? theme.colors.gray['400']
                      : theme.colors.gray['500']
                }}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Stack spacing={2}>
        {steps.map((step, index) => (
          <ChakraTooltip key={index} label={t('common.click_to_open')}>
            <HStack
              onClick={() => handleOnStepClick(step)}
              onMouseEnter={() => handleOnHover(null, index)}
              onMouseLeave={() => handleOnHover(null)}
              bg={
                hover === index
                  ? colorMode === 'light'
                    ? 'gray.100'
                    : 'gray.700'
                  : null
              }
              cursor="pointer"
              borderRadius="md"
            >
              <Badge colorScheme={step.color}>{step.name}</Badge>
              <Spacer />
              <Text fontWeight="bold">{step.count}</Text>
            </HStack>
          </ChakraTooltip>
        ))}
      </Stack>
      <CardDrawer
        filter={{
          collection: collection?.id,
          workflow: workflow?.id,
          step: selectedStepId
        }}
        header={selectedStepLabel}
        isOpen={showCardDrawer}
        onClose={closeCardDrawer}
      />
    </Stack>
  );
};

export default StepDistributionChart;
