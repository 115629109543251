import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';

const Saml = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const slug = searchParams.get('slug') || localStorage.getItem('slug');
    const token = searchParams.get('token');
    if (slug && token) {
      localStorage.setItem('token', token);
      setTimeout(() => {
        navigate(`/${slug}`);
      }, 1000);
    } else {
      if (slug) {
        navigate(`/login/${slug}`);
      } else {
        navigate(`/login`);
      }
    }
  }, [navigate, searchParams]);

  return <LoadingIndicator />;
};

export default Saml;
