import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Image,
  Show,
  Spacer,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import CoverMedia from 'components/CoverMedia';
import LoadingWrapper from 'components/LoadingWrapper';
import PoweredByHives from 'components/PoweredByHives';
import { useConfetti } from 'providers/ConfettiProvider';
import api from 'utils/api';
import SurveyFields from './SurveyFields';
import TitleDescription from 'components/TitleDescription';

const SharedSurveyDetailed = () => {
  const [fieldIndex, setFieldIndex] = useState(0);

  const { fire } = useConfetti();
  const { code, slug } = useParams();

  const { t } = useTranslation();

  const [introduction, setIntroduction] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const queryClient = useQueryClient();

  const findSurveySession = code => {
    try {
      const surveySessions =
        JSON.parse(localStorage.getItem('surveySessions')) || [];
      const session = surveySessions.find(session => session.survey === code);
      return session ? session.id : null;
    } catch (error) {
      console.error('Failed to retrieve survey session:', error);
      return null;
    }
  };

  const updateSurveySession = (code, sessionId) => {
    try {
      let surveySessions =
        JSON.parse(localStorage.getItem('surveySessions')) || [];
      const sessionExists = surveySessions.some(
        session => session.survey === code && session.id === sessionId
      );
      if (!sessionExists) {
        surveySessions.push({ survey: code, id: sessionId });
        localStorage.setItem('surveySessions', JSON.stringify(surveySessions));
        setSessionId(sessionId);
      }
    } catch (error) {
      console.error('Failed to update survey session:', error);
    }
  };

  const {
    status: surveyStatus,
    data: survey,
    isSuccess: surveyIsSuccess
  } = useQuery(['survey', code], async () => {
    const { data } = await api.get(`/shared/surveys/${code}`);
    return data;
  });

  const {
    status: surveyResponseStatus,
    data: surveyResponse,
    isSuccess: surveyResponseIsSuccess
  } = useQuery(
    ['survey', 'response', code],
    async () => {
      const surveySession = findSurveySession(code);
      if (surveySession) {
        setSessionId(surveySession);
      }
      const { data } = await api.get(
        `/shared/surveys/${code}/response${
          surveySession ? `?session_id=${surveySession}` : ''
        }`
      );
      return data;
    },
    {
      onSuccess: data => {
        if (data.session_id) {
          updateSurveySession(code, data.session_id);
        }
      },
      enabled: surveyIsSuccess
    }
  );

  const handleOnClickPrevious = () => {
    if (fieldIndex > 0) {
      setFieldIndex(fieldIndex - 1);
    }
  };

  const handleOnClickNext = () => {
    if (fieldIndex < survey.fields.length - 1) {
      setFieldIndex(fieldIndex + 1);
    }
  };

  useEffect(() => {
    if (completed) {
      fire();
    }
  }, [completed, fire]);

  const hive = queryClient.getQueryData(['hive', slug]);

  const logo = useColorModeValue(
    hive?.dark_logo ? hive.dark_logo : '/hives_dark.png',
    hive?.light_logo ? hive.light_logo : '/hives_light.png'
  );

  const HeaderNavigation = ({ ...props }) => (
    <HStack {...props}>
      <Link to={`/${slug}`}>
        <Image src={logo} height={8} />
      </Link>
    </HStack>
  );

  /*
    TODO: this is a bit of a gotcha. By using pointer events none,
    we're able to make sure that the next / done button is clickable but this
    solution should be refactored to prevent potential future bugs.
  */
  const FooterNavigation = ({ ...props }) => (
    <HStack {...props} pointerEvents="none">
      <Flex pointerEvents="auto">
        <PoweredByHives />
      </Flex>
      <Spacer />
      {!introduction && !completed && (
        <>
          <Text>{`${fieldIndex + 1} / ${survey?.fields.length}`}</Text>
          <HStack pointerEvents="auto">
            <Button
              variant="outline"
              isDisabled={fieldIndex === 0}
              onClick={handleOnClickPrevious}
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
            <Button
              variant="outline"
              isDisabled={fieldIndex === survey?.fields.length - 1}
              onClick={handleOnClickNext}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </Button>
          </HStack>
        </>
      )}
    </HStack>
  );

  return (
    <Flex minHeight="100vh" alignItems="center" justifyContent="center">
      <Show above="lg">
        <HeaderNavigation
          position="absolute"
          minHeight="40px"
          top={8}
          left={8}
          right={8}
        />
      </Show>
      <Container maxW="container.xs" marginY={8}>
        <LoadingWrapper statuses={[surveyStatus, surveyResponseStatus]}>
          {surveyIsSuccess && surveyResponseIsSuccess && (
            <Stack spacing={8}>
              <Show below="lg">
                <HeaderNavigation />
              </Show>
              {introduction ? (
                <Stack spacing={8}>
                  <CoverMedia object={survey} height="200px" hideIfEmpty />
                  <TitleDescription
                    title={survey.title}
                    description={survey.description}
                    noOfLines={5}
                  />
                  <Button
                    colorScheme="teal"
                    alignSelf="flex-end"
                    onClick={() => {
                      setIntroduction(false);
                    }}
                    isDisabled={survey.fields.length === 0}
                  >
                    {t('button.start')}
                  </Button>
                </Stack>
              ) : completed ? (
                <Stack spacing={8}>
                  <Heading fontSize="3xl">
                    {`${t('survey.completion.thank_you_title')} 🎉`}
                  </Heading>
                  <Text>{t('survey.completion.thank_you_description')}</Text>
                </Stack>
              ) : (
                <Stack spacing={8}>
                  <SurveyFields
                    code={code}
                    slug={slug}
                    survey={survey}
                    fieldIndex={fieldIndex}
                    sessionId={sessionId}
                    surveyResponse={surveyResponse}
                    onNext={() => {
                      if (fieldIndex < survey?.fields.length - 1) {
                        setFieldIndex(fieldIndex + 1);
                      }
                    }}
                    onDone={() => setCompleted(true)}
                  />
                </Stack>
              )}
              <Show below="lg">
                <FooterNavigation />
              </Show>
            </Stack>
          )}
          <Show above="lg">
            <FooterNavigation
              position="absolute"
              minHeight="40px"
              bottom={8}
              left={8}
              right={8}
              spacing={4}
            />
          </Show>
        </LoadingWrapper>
      </Container>
    </Flex>
  );
};

export default SharedSurveyDetailed;
