import { Link, useParams } from 'react-router-dom';
import { Center, Circle, Text, VStack, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/pro-regular-svg-icons';

const ProjectTemplateListItem = ({ template }) => {
  const { slug } = useParams();
  const { colorMode } = useColorMode();
  return (
    <Center
      height={64}
      borderWidth="1px"
      as={Link}
      to={`/${slug}/project/template/${template.id}`}
    >
      <VStack>
        <Circle
          size={16}
          bgColor={colorMode === 'light' ? 'gray.100' : 'whiteAlpha.100'}
        >
          <FontAwesomeIcon icon={faPencilRuler} size="2x" />
        </Circle>
        <Text>{template.name}</Text>
      </VStack>
    </Center>
  );
};

export default ProjectTemplateListItem;
