import { HStack, Text } from '@chakra-ui/react';
import MemberAvatar from 'components/MemberAvatar';

const MemberOptionLabel = ({ member }) => (
  <HStack spacing={2} mr={2}>
    <MemberAvatar member={member} size="xs" />
    <Text>{member.full_name}</Text>
  </HStack>
);

export default MemberOptionLabel;
