import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import ModalWrapper from './ModalWrapper';
import { useTranslation } from 'react-i18next';
import Illustration from './Illustration';
import { useTeams } from 'msteams-react-base-component';

const UpgradeModal = ({ isOpen, onClose }) => {
  const [{ inTeams }] = useTeams();
  const { t } = useTranslation();
  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <Stack spacing={8} padding={2}>
        <Stack spacing={4}>
          <Illustration filename="airplane.svg" />
          <Heading size="md">{t('collection.upgrade_modal_title')}</Heading>
          <Text>{t('collection.upgrade_modal_description')}</Text>
        </Stack>
        {inTeams ? (
          <Button as="a" href="https://hives.co" target="_blank">
            {t('common.contact_us')}
          </Button>
        ) : (
          <Button as="a" href="https://hives.co/en/pricing" target="_blank">
            {t('collection.upgrade_modal_button')}
          </Button>
        )}
      </Stack>
    </ModalWrapper>
  );
};

export default UpgradeModal;
