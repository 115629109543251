import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import AccessForm from 'components/AccessForm';
import ModalWrapper from 'components/ModalWrapper';
import ChannelListItem from './ChannelListItem';
import LoadingWrapper from 'components/LoadingWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import api from 'utils/api';

const ChannelList = () => {
  const {
    isOpen: showCreateChannelModal,
    onOpen: openCreateChannelModal,
    onClose: closeCreateChannelModal
  } = useDisclosure();

  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: profile } = useQuery(['profile', slug]);
  const { data: hive } = useQuery(['hive', slug]);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    status,
    data: channels,
    isSuccess
  } = useQuery(['channels', slug], async () => {
    const { data } = await api.get(`/channels?hive__slug=${slug}`);
    return data;
  });

  const createChannelMutation = useMutation(
    channel => api.post('/channels', channel),
    {
      onSuccess: async ({ data: channel }) => {
        closeCreateChannelModal();
        queryClient.setQueryData(['channels', slug], [channel, ...channels]);
        navigate(`/${slug}/channel/${channel.id}`);
      }
    }
  );

  const handleOnCreateChannel = async channel => {
    const domains = channel.domains
      ? channel.domains.map(domain => domain.id)
      : [];
    const groups = channel.groups ? channel.groups.map(group => group.id) : [];
    const members = channel.members
      ? channel.members.map(member => member.id)
      : [];
    const accessId = channel.access.id;
    await createChannelMutation.mutateAsync({
      ...channel,
      hive: hive.id,
      domains,
      groups,
      members,
      access: accessId
    });
  };

  return (
    <Container maxW="container.lg" marginY={8}>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.channels').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={8}>
            <Flex alignItems="center" justifyContent="space-between">
              <Heading fontSize="3xl">{t('common.channels')}</Heading>
              {profile?.is_admin_or_manager && (
                <Button colorScheme="teal" onClick={openCreateChannelModal}>
                  <HStack>
                    <FontAwesomeIcon icon={faPlus} />
                    <Text>{t('common.channel')}</Text>
                  </HStack>
                </Button>
              )}
            </Flex>
            <SimpleGrid columns={[1, null, 2]} spacing={8}>
              {channels?.map(channel => (
                <ChannelListItem key={channel.id} channel={channel} />
              ))}
            </SimpleGrid>
          </Stack>
        ) : null}
      </LoadingWrapper>
      <ModalWrapper
        title={t('channel.create_channel')}
        size="full"
        isOpen={showCreateChannelModal}
        onClose={closeCreateChannelModal}
      >
        <AccessForm
          isOpen={showCreateChannelModal}
          onClose={closeCreateChannelModal}
          onSubmit={handleOnCreateChannel}
        />
      </ModalWrapper>
    </Container>
  );
};

export default ChannelList;
