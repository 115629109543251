import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Switch
} from '@chakra-ui/react';
import ImageSelect from 'components/ImageSelect';
import TitleInput from 'components/TitleInput';
import api from 'utils/api';
import DescriptionFormControl from 'components/DescriptionFormControl';

const CollectionForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { t } = useTranslation();

  const {
    control,
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  const { mutateAsync, isLoading } = useMutation(payload =>
    api.post('/services/generate-collection-description', payload)
  );

  const values = watch();

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  const handleOnClickMagic = async () => {
    try {
      const { data } = await mutateAsync({
        title: values.title,
        hive: hive.id
      });
      if (values.description) {
        setValue('description', values.description + '\n' + data.content);
      } else {
        setValue('description', data.content);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="image">
          {!defaultValues && (
            <>
              <FormLabel display="inline-block">{`${t(
                'common.choose_media'
              )}`}</FormLabel>
              <FormHelperText display="inline">{`(${t(
                'common.optional'
              )})`}</FormHelperText>
            </>
          )}
          <ImageSelect
            object={values}
            setValue={setValue}
            uploadVideo
            preloadImage={!defaultValues}
          />
        </FormControl>
        <FormControl id="title">
          <FormLabel>{t('common.title')}</FormLabel>
          <TitleInput control={control} />
        </FormControl>
        <DescriptionFormControl
          control={control}
          setValue={text => setValue('description', text)}
          onClickMagic={handleOnClickMagic}
          isLoading={isLoading}
          showMagicButton={values.title?.length > 10 && hive.use_ai}
        />
        {defaultValues && (
          <FormControl>
            <FormLabel htmlFor="hide-comments">
              {t('collection.hide_comments')}
            </FormLabel>
            <Switch
              id="hide-comments"
              {...register('hide_comments')}
              defaultChecked={defaultValues?.hide_comments}
            />
          </FormControl>
        )}
        <Input {...register('image')} hidden />
        <Input {...register('unsplash')} hidden />
        <Input {...register('video')} hidden />
        <Button
          alignSelf="flex-end"
          type="submit"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          {t('button.save')}
        </Button>
      </Stack>
    </form>
  );
};

export default CollectionForm;
