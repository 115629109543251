import { useQuery } from 'react-query';
import LabeledBox from 'components/LabeledBox';
import LoadingWrapper from 'components/LoadingWrapper';
import StepDistributionChart from 'components/StepDistributionChart';
import api from 'utils/api';

const CollectionStepDistribution = ({ collection, label }) => {
  const { data, status, isSuccess } = useQuery(
    ['collection-step-distribution', collection.id],
    async () => {
      const { data } = await api.get(
        `/metrics/collection-step-distribution?collection=${collection.id}`
      );
      return data;
    }
  );

  return (
    <LabeledBox label={label ? label : collection.workflow.name}>
      <LoadingWrapper statuses={[status]}>
        {isSuccess && (
          <StepDistributionChart steps={data.steps} collection={collection} />
        )}
      </LoadingWrapper>
    </LabeledBox>
  );
};

export default CollectionStepDistribution;
