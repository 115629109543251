export const Plans = {
  Freemium: 0,
  Starter: 1,
  Professional: 2,
  Enterprise: 3
};

const getPlanLevel = plan => {
  switch (plan) {
    case 'ENTERPRISE':
      return 3;
    case 'PROFESSIONAL':
      return 2;
    case 'STARTER':
      return 1;
    default:
      return 0;
  }
};

export const hasPlanGreaterThanOrEqual = (currentPlan, requiredPlan) => {
  const planLevel = getPlanLevel(currentPlan);
  return planLevel >= requiredPlan;
};

export const hasPlan = (currentPlan, requiredPlan) => {
  const planLevel = getPlanLevel(currentPlan);
  return planLevel === requiredPlan;
};
