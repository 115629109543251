import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Flex, FormControl, Input, Stack } from '@chakra-ui/react';

const OptionForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onChange'
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues, isOpen]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="option">
          <Input {...register('option')} autoFocus />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default OptionForm;
