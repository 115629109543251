import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  CloseButton,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import ModalWrapper from 'components/ModalWrapper';
import FolderForm from 'features/folders/FolderForm';
import api from 'utils/api';
import {
  faBoxArchive,
  faChartSimple,
  faFolder,
  faHome,
  faRectangleHistory,
  faShareNodes
} from '@fortawesome/pro-regular-svg-icons';
import { hasPlanGreaterThanOrEqual, Plans } from 'features/hive/hiveUtils';

const NavigationDrawer = ({ logo, isOpen, onClose }) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);

  const {
    isOpen: showCreateFolderModal,
    onOpen: openCreateFolderModal,
    onClose: closeCreateFolderModal
  } = useDisclosure();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data: folders, isSuccess } = useQuery(
    ['folders', 'parent'],
    async () => {
      const { data } = await api.get(
        `/folders?parent__isnull=true&archived_at__isnull=true&hive__slug=${slug}`
      );
      return data;
    }
  );

  const { data: navigation, isSuccess: navigationIsSuccess } = useQuery(
    ['navigation'],
    async () => {
      const { data } = await api.get(`/metrics/navigation?slug=${slug}`);
      return data;
    }
  );

  const createFolderMutation = useMutation(
    folder => api.post('/folders', folder),
    {
      onSuccess: ({ data: folder }) => {
        closeCreateFolderModal();
        onClose();
        toast({
          title: 'Folder was successfully created.',
          status: 'success',
          position: 'bottom-right',
          isClosable: true
        });
        queryClient.setQueryData(['folders', 'parent'], [...folders, folder]);
        navigate(`/${slug}/folder/${folder.id}`);
      },
      onError: () => {
        toast({
          title: `Could not create folder. Please try again later.`,
          status: 'error',
          position: 'bottom-right',
          isClosable: true
        });
      }
    }
  );

  const handleOnCreateFolder = async data => {
    await createFolderMutation.mutateAsync({ ...data, hive: hive.id });
  };

  const NavigationButton = ({ to, text, icon, right = null, tooltipLabel }) => {
    const actibeBgColor = useColorModeValue('blue.50', 'blue.700');
    const activeTextColor = useColorModeValue('blue.500', 'white');
    return (
      <Flex
        as={NavLink}
        to={to}
        onClick={onClose}
        variant="link"
        justifyContent="flex-start"
        py={3}
        px={3}
        rounded="lg"
        end
        _activeLink={{
          bg: actibeBgColor,
          color: activeTextColor
        }}
        _hover={{
          bg: actibeBgColor,
          color: activeTextColor
        }}
      >
        <Tooltip
          isDisabled={!tooltipLabel}
          placement="right"
          label={tooltipLabel}
          hasArrow
          offset={[0, 20]}
          width="60%"
        >
          <HStack width="full">
            {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
            <Text maxW="20ch" isTruncated>
              {text}
            </Text>
            <Spacer />
            {right}
          </HStack>
        </Tooltip>
      </Flex>
    );
  };

  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent position="relative">
        <CloseButton
          position="absolute"
          top={2}
          right={2}
          size="lg"
          alignSelf="flex-end"
          onClick={onClose}
        />
        <DrawerHeader maxWidth="85%">
          <Link to="/" onClick={onClose}>
            <Image src={logo} height={8} />
          </Link>
        </DrawerHeader>
        <DrawerBody px={3}>
          <Stack spacing={4}>
            <Flex flexDir="column">
              <NavigationButton
                to={`/${slug}`}
                icon={faHome}
                text={t('common.home')}
              />
              <NavigationButton
                to={`/${slug}/dashboard`}
                icon={faChartSimple}
                text={t('common.dashboard')}
              />
              {hasPlanGreaterThanOrEqual(hive?.plan, Plans.Professional) && (
                <NavigationButton
                  to={`/${slug}/channels`}
                  icon={faShareNodes}
                  text={t('common.channels')}
                />
              )}
              <NavigationButton
                to={`/${slug}/collections`}
                icon={faRectangleHistory}
                text={t('common.my_collections')}
              />
            </Flex>
            <Divider />
            <Text variant="muted" px={3}>
              {t('common.folders')}
            </Text>
            <Flex flexDir="column">
              {isSuccess &&
                folders.map(folder => (
                  <NavigationButton
                    key={folder.id}
                    to={`/${slug}/folder/${folder.id}`}
                    icon={faFolder}
                    text={folder.name}
                  />
                ))}

              <Button
                variant="link"
                justifyContent="flex-start"
                px={3}
                py={4}
                onClick={openCreateFolderModal}
              >
                <HStack>
                  <FontAwesomeIcon icon={faPlus} fixedWidth />
                  <Text>{t('button.new_folder')}</Text>
                </HStack>
              </Button>
              <Flex pb={4} pt={2}>
                <Divider />
              </Flex>
              <NavigationButton
                to={`/${slug}/uncategorized/`}
                icon={faRectangleHistory}
                text={t('common.uncategorized')}
                right={
                  navigationIsSuccess ? (
                    <Text>{navigation.metrics.uncategorized_collections}</Text>
                  ) : null
                }
                tooltipLabel={t('folder.uncategorized_explainer')}
              />
              <NavigationButton
                to={`/${slug}/archived/`}
                icon={faBoxArchive}
                text={t('common.archived_plural')}
                right={
                  navigationIsSuccess ? (
                    <Text>{navigation.metrics.archived_folders}</Text>
                  ) : null
                }
              />
            </Flex>
          </Stack>
        </DrawerBody>
      </DrawerContent>
      <ModalWrapper
        title={t('common.folder')}
        isOpen={showCreateFolderModal}
        onClose={closeCreateFolderModal}
        hasSteps
      >
        <FolderForm
          isOpen={showCreateFolderModal}
          onClose={closeCreateFolderModal}
          onSubmit={handleOnCreateFolder}
        />
      </ModalWrapper>
    </Drawer>
  );
};

export default NavigationDrawer;
