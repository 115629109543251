import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Center, Container, Spinner } from '@chakra-ui/react';
import { useAuth } from 'providers/AuthProvider';

const NoMatch = () => {
  const [searchParams] = useSearchParams();
  const { authenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: remove this after half a year.
    // Temporary solution to redirect old card links to the new link structure with hive slug.
    const card = searchParams.get('card');
    if (card) {
    } else {
      navigate(authenticated ? '/join' : '/');
    }
  }, [authenticated, navigate, searchParams]);

  return (
    <Container marginY={8}>
      <Center>
        <Spinner />
      </Center>
    </Container>
  );
};

export default NoMatch;
