import { Reorder, useDragControls } from 'framer-motion';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripDots } from '@fortawesome/pro-solid-svg-icons';

const BoardReorderItem = ({ board }) => {
  const controls = useDragControls();
  return (
    <Reorder.Item
      as={Flex}
      value={board}
      dragListener={false}
      dragControls={controls}
      borderWidth={0}
      borderBottom="none"
      py={4}
    >
      <HStack spacing={4} userSelect="none">
        <Text
          cursor="grab"
          variant="light"
          onPointerDown={e => {
            controls.start(e);
          }}
        >
          <FontAwesomeIcon icon={faGripDots} />
        </Text>
        <Text>{board.title}</Text>
      </HStack>
    </Reorder.Item>
  );
};

export default BoardReorderItem;
