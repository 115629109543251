import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Container, Heading, Stack } from '@chakra-ui/react';
import api from 'utils/api';
import CreateHiveForm from './CreateHiveForm';

const CreateHivePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const createHiveMutation = useMutation(hive => api.post('/hives', hive), {
    onSuccess: ({ data: hive }) => {
      navigate(`/${hive.slug}`);
    }
  });

  const handleOnCreateHive = async hive => {
    try {
      await createHiveMutation.mutateAsync(hive);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container marginY={16}>
      <Stack spacing={8}>
        <Heading as="h1" fontSize="3xl">
          {t('register.create_hive')}
        </Heading>
        <CreateHiveForm onSubmit={handleOnCreateHive} />
      </Stack>
    </Container>
  );
};

export default CreateHivePage;
