import { useTranslation } from 'react-i18next';
import { Center, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';

const InfoColumn = ({ description, title }) => {
  const { t } = useTranslation();
  return (
    <Flex
      position="relative"
      bg="blue.600"
      color="white"
      width="full"
      padding={8}
      display={['none', null, null, 'flex']}
    >
      <Image
        position="absolute"
        src="/hives_light.png"
        height="32px"
        objectFit="cover"
      />
      <Center>
        <Stack spacing={8}>
          <Heading as="h1" fontSize="5xl">
            {title}
          </Heading>
          <Text>{description}</Text>
        </Stack>
      </Center>
      <Flex
        position="absolute"
        bottom={0}
        left={0}
        padding={4}
        color="whiteAlpha.700"
      >
        <Text>{t('login.copyright')}</Text>
      </Flex>
    </Flex>
  );
};

export default InfoColumn;
