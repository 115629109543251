import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';

const TranslateButton = ({ showTranslated, size = 'xs', ...rest }) => {
  const { t } = useTranslation();
  return (
    <Button variant="link" size={size} {...rest}>
      {showTranslated ? t('common.show_original') : t('common.translate')}
    </Button>
  );
};

export default TranslateButton;
