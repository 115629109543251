import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  HStack,
  Stack,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';

const QrCodeButton = ({ value, filename = 'qr.svg', explainer }) => {
  const { t } = useTranslation();

  const handleOnDownloadQrCode = () => {
    const svg = document.getElementById('qr').innerHTML;
    const blob = new Blob([svg.toString()]);
    const element = document.createElement('a');
    element.download = filename;
    element.href = window.URL.createObjectURL(blob);
    element.click();
    element.remove();
  };

  return (
    <HStack justifyContent="flex-start" alignItems="flex-start" spacing={8}>
      <Box id="qr">
        <QRCode
          style={{
            height: 'auto',
            maxWidth: '96px',
            width: '150px'
          }}
          value={value}
          viewBox={`0 0 96 96`}
        />
      </Box>
      <Stack spacing={4}>
        <FormControl>
          <FormHelperText whiteSpace="pre-line" mt={0}>
            {explainer}
          </FormHelperText>
        </FormControl>
        <Button
          alignSelf="flex-start"
          colorScheme="blue"
          onClick={handleOnDownloadQrCode}
        >
          <HStack>
            <FontAwesomeIcon icon={faDownload} />
            <Text>{t('common.download')}</Text>
          </HStack>
        </Button>
      </Stack>
    </HStack>
  );
};

export default QrCodeButton;
