import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react';
import ImageSelect from 'components/ImageSelect';
import TitleInput from 'components/TitleInput';
import DescriptionFormControl from 'components/DescriptionFormControl';
import api from 'utils/api';
import { useUi } from 'providers/UiProvider';

const CardForm = ({
  code,
  collection,
  workflowId,
  defaultValues,
  titleOverride = '',
  useDescription = true,
  useImage = true,
  isOpen,
  onSubmit
}) => {
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { t } = useTranslation();

  const { setModalClosureLocked } = useUi();

  const {
    data: fields,
    refetch: fetchFields,
    status: fieldsIsSuccess
  } = useQuery(
    ['fields', 'form', workflowId],
    async () => {
      const params = new URLSearchParams({
        workflow: workflowId,
        form: true
      });
      if (code) {
        params.append('code', code);
      }
      const { data } = await api.get(`/fields?${params.toString()}`);
      return data;
    },
    {
      enabled: false
    }
  );

  const { mutateAsync, isLoading } = useMutation(payload =>
    api.post('/services/generate-card-description', payload)
  );

  const {
    control,
    register,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid, isDirty }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    if (workflowId && !defaultValues) {
      fetchFields();
    }
  }, [fetchFields, defaultValues, workflowId]);

  useEffect(() => {
    if (!isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  useEffect(() => {
    setModalClosureLocked(isDirty);
  }, [setModalClosureLocked, isDirty]);

  const values = watch();

  const handleOnClickMagic = async () => {
    try {
      const { data } = await mutateAsync({
        title: values.title,
        collection: collection.id
      });
      if (values.description) {
        setValue('description', values.description + '\n' + data.content);
      } else {
        setValue('description', data.content);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        {useImage && (
          <ImageSelect
            object={values}
            setValue={setValue}
            preloadImage={!defaultValues}
          />
        )}
        <FormControl id="title">
          <FormLabel>
            {titleOverride ? titleOverride : t('common.title')}
          </FormLabel>
          <TitleInput control={control} />
        </FormControl>
        {useDescription && (
          <DescriptionFormControl
            control={control}
            setValue={text => setValue('description', text)}
            onClickMagic={handleOnClickMagic}
            isLoading={isLoading}
            showMagicButton={values.title?.length > 10 && hive?.use_ai}
          />
        )}
        {!defaultValues &&
          fieldsIsSuccess &&
          fields?.length > 0 &&
          fields.map((field, index) => (
            <FormControl key={index} id={`fields.${index}`}>
              <FormLabel>{t(field.field)}</FormLabel>
              {field.help_text && (
                <Text my={2} fontSize="sm">
                  {field.help_text}
                </Text>
              )}
              <Controller
                name={`fields.${index}`}
                control={control}
                rules={{ required: field.type !== 'RATING' }}
                defaultValue={
                  field.type === 'RATING'
                    ? {
                        id: field.id,
                        points: field.chart.parameters.map(p => ({
                          parameter: p.id,
                          value: p.min
                        }))
                      }
                    : null
                }
                render={({ field: f }) =>
                  field.type === 'RATING' ? (
                    <Stack>
                      {field.chart.parameters.map((parameter, j) => {
                        return (
                          <Stack key={j}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Text>{parameter.label}</Text>
                              <Text>{f.value.points[j].value}</Text>
                            </Flex>
                            <Controller
                              name={`fields.${index}.points.${j}`}
                              defaultValue={{
                                parameter: parameter.id,
                                value: parameter.min
                              }}
                              control={control}
                              render={({ field: f }) => (
                                <Slider
                                  onChange={value => {
                                    f.onChange({
                                      parameter: parameter.id,
                                      value
                                    });
                                  }}
                                  defaultValue={parameter.min}
                                  min={parameter.min}
                                  max={parameter.max}
                                  step={1}
                                >
                                  <SliderTrack>
                                    <SliderFilledTrack />
                                  </SliderTrack>
                                  <SliderThumb />
                                </Slider>
                              )}
                            />
                          </Stack>
                        );
                      })}
                    </Stack>
                  ) : field.type === 'INPUT' ? (
                    <Textarea
                      value={f?.value ? f.value.value : ''}
                      onChange={e => {
                        f.onChange(
                          e.target.value === ''
                            ? null
                            : {
                                id: field.id,
                                value: e.target.value
                              }
                        );
                      }}
                    />
                  ) : (
                    <Select
                      {...f}
                      options={field?.options ? field.options : []}
                      placeholder={t('placeholder.search')}
                      getOptionLabel={field => field.option}
                      getOptionValue={field => field.id}
                      isClearable
                      isMulti
                      useBasicStyles
                    />
                  )
                }
              />
            </FormControl>
          ))}
        {useImage && (
          <>
            <Input {...register('image')} hidden />
            <Input {...register('unsplash')} hidden />
          </>
        )}
        {onSubmit && (
          <HStack
            width="full"
            spacing={12}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Text fontSize="xs" variant="light">
              {t('card.edit_later_explainer')}
            </Text>
            <Button
              type="submit"
              colorScheme="teal"
              isDisabled={!isValid}
              isLoading={isSubmitting}
            >
              {defaultValues ? t('button.save') : t('button.submit')}
            </Button>
          </HStack>
        )}
      </Stack>
    </form>
  );
};

export default CardForm;
