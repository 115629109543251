import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import svTranslation from './translations/sv.json';

const resources = {
  en: {
    translation: enTranslation
  },
  sv: {
    translation: svTranslation
  }
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export const getLanguageCode = () => {
  let temp = i18n.language.split('-');
  if (temp[0] === 'en' || temp[0] === 'sv') {
    return temp[0];
  }
  return 'en';
};

window.__localeId__ = getLanguageCode();

export default i18n;
