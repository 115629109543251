import { formatDistance } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { Circle, Flex, Stack, Text } from '@chakra-ui/react';

const StepNotification = ({ notification, onClickCard, color }) => {
  const { t } = useTranslation();

  return notification.data ? (
    <Flex
      alignItems="center"
      cursor="pointer"
      justifyContent="space-between"
      onClick={() => onClickCard(notification.data.id)}
    >
      <Stack>
        <Text>
          <Trans
            t={t}
            i18nKey="notifications.is_now"
            values={{
              card: notification.data.title,
              step: notification.event_label
            }}
            components={[
              <Text as="span" fontWeight="bold" />,
              <Text as="span" />,
              <Text as="span" fontWeight="bold" />
            ]}
          />
        </Text>
        <Text variant="light" fontSize="sm">
          {formatDistance(new Date(notification.created_at), new Date(), {
            addSuffix: true
          })}
        </Text>
      </Stack>
      <Circle bg={notification.read ? null : color} size={2} />
    </Flex>
  ) : null;
};

export default StepNotification;
