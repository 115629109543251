import i18next from 'i18next';

export const getRoleName = role => {
  switch (role) {
    case 'ADMIN':
      return i18next.t('common.admin');
    case 'MANAGER':
      return i18next.t('common.manager');
    case 'MEMBER':
      return i18next.t('common.member');
    case 'GUEST':
      return i18next.t('common.guest');
    default:
      return i18next.t('common.guest');
  }
};

export const Roles = {
  GUEST: 0,
  MEMBER: 1,
  MANAGER: 2,
  ADMIN: 3
};

const getRoleLevel = role => {
  switch (role) {
    case 'ADMIN':
      return 3;
    case 'MANAGER':
      return 2;
    case 'MEMBER':
      return 1;
    default:
      return 0;
  }
};

export const hasRoleGreaterThanOrEqual = (currentRole, requiredRole) => {
  const planLevel = getRoleLevel(currentRole);
  return planLevel >= requiredRole;
};

export const hasRole = (currentRole, requiredRole) => {
  const planLevel = getRoleLevel(currentRole);
  return planLevel === requiredRole;
};
