import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, useDisclosure } from '@chakra-ui/react';
import BoardListItem from './BoardListItem';
import LoadingWrapper from 'components/LoadingWrapper';
import ModalWrapper from 'components/ModalWrapper';
import CollectionCreationForm from 'features/collection/CollectionCreationForm';
import api from 'utils/api';

const BoardList = () => {
  const [newCollection, setNewCollection] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState();

  const {
    isOpen: showCreateCollectionModal,
    onOpen: openCreateCollectionModal,
    onClose: closeCreateCollectionModal
  } = useDisclosure();

  const navigate = useNavigate();
  const { slug } = useParams();
  const { data: hive } = useQuery(['hive', slug]);
  const { data: profile } = useQuery(['profile', slug]);
  const { t } = useTranslation();

  const {
    status,
    data: boards,
    isSuccess
  } = useQuery(['boards', slug], async () => {
    const { data } = await api.get(`/boards?hive=${hive.id}`);
    return data;
  });

  const hasCreateCollectionsPermission = profile?.is_admin_or_manager
    ? true
    : false;

  const handleOnCloseCreateCollectionModal = () => {
    closeCreateCollectionModal();
    if (newCollection) {
      navigate(`/${slug}/collection/${newCollection.id}?mode=preview`);
    }
  };

  return (
    <>
      <LoadingWrapper
        statuses={[status]}
        errorMessages={[
          t('common.could_not_fetch_data_please_try_again_later', {
            data: t('common.boards').toLowerCase()
          })
        ]}
      >
        {isSuccess ? (
          <Stack spacing={16}>
            {boards.map(board => (
              <React.Fragment key={board.id}>
                <BoardListItem
                  key={board.id}
                  board={board}
                  hasCreateCollectionsPermission={
                    hasCreateCollectionsPermission
                  }
                  onClickCreate={board => {
                    setSelectedBoard(board);
                    openCreateCollectionModal();
                  }}
                />
              </React.Fragment>
            ))}
          </Stack>
        ) : null}
        {selectedBoard && (
          <ModalWrapper
            size="full"
            hasSteps
            isOpen={showCreateCollectionModal}
            onOpen={openCreateCollectionModal}
            onClose={handleOnCloseCreateCollectionModal}
          >
            <CollectionCreationForm
              isOpen={showCreateCollectionModal}
              onClose={handleOnCloseCreateCollectionModal}
              onCollectionCreated={newCollection => {
                setNewCollection(newCollection);
              }}
              board={selectedBoard}
              newCollection={newCollection}
            />
          </ModalWrapper>
        )}
      </LoadingWrapper>
    </>
  );
};

export default BoardList;
