import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack
} from '@chakra-ui/react';

const ProjectTemplateForm = ({ defaultValues, isOpen, onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues
  });

  useEffect(() => {
    reset();
  }, [isOpen, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl id="name">
          <FormLabel>{t('common.name')}</FormLabel>
          <Input
            placeholder={t('placeholder.enter_name')}
            {...register('name', { required: true })}
            defaultValue={defaultValues?.name}
            autoFocus
          />
        </FormControl>
        <Flex justifyContent="flex-end">
          <Button
            type="submit"
            colorScheme="teal"
            isDisabled={!isValid || !isDirty}
            isLoading={isSubmitting}
          >
            {t('button.save')}
          </Button>
        </Flex>
      </Stack>
    </form>
  );
};

export default ProjectTemplateForm;
