import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

const SearchForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid }
  } = useForm({ mode: 'onChange' });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HStack>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <FontAwesomeIcon icon={faSearch} />
          </InputLeftElement>
          <Input
            placeholder={t('placeholder.search')}
            {...register('search')}
            autoFocus
          />
        </InputGroup>
        <Button
          type="submit"
          colorScheme="teal"
          isDisabled={!isValid}
          isLoading={isSubmitting}
        >
          {t('common.search')}
        </Button>
      </HStack>
    </form>
  );
};

export default SearchForm;
