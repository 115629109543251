import { useDisclosure } from '@chakra-ui/react';
import AvatarGroupButton from './AvatarGroupButton';
import MembersModal from './modals/MembersModal';

const MembersButton = ({
  canEdit,
  code,
  max = 3,
  members,
  modalTitle,
  onChangeMembers,
  showCount = true,
  canRemoveSelf,
  ...rest
}) => {
  const {
    isOpen: showMembersModal,
    onOpen: openMembersModal,
    onClose: closeMembersModal
  } = useDisclosure();

  return (
    <>
      <AvatarGroupButton
        showCount={showCount}
        max={max}
        members={members}
        onClick={openMembersModal}
        {...rest}
      />
      <MembersModal
        code={code}
        members={members}
        isOpen={showMembersModal}
        onClose={closeMembersModal}
        onChangeMembers={onChangeMembers}
        canRemoveSelf={canRemoveSelf}
        title={modalTitle}
        canEdit={canEdit}
      />
    </>
  );
};

export default MembersButton;
